import axios from "axios";
import { toast } from "react-toastify";
import { errorHandling } from "../../helper";

export const VENDOR_LIST_REQUEST = "VENDOR_LIST_REQUEST";
export const VENDOR_LIST_SUCCESS = "VENDOR_LIST_SUCCESS";
export const VENDOR_LIST_FAILURE = "VENDOR_LIST_FAILURE";

export const VENDOR_UPDATE_REQUEST = "VENDOR_UPDATE_REQUEST";
export const VENDOR_UPDATE_SUCCESS = "VENDOR_UPDATE_SUCCESS";
export const VENDOR_UPDATE_FAILURE = "VENDOR_UPDATE_FAILURE";

export const VENDOR_DETAIL_REQUEST = "VENDOR_DETAIL_REQUEST";
export const VENDOR_DETAIL_SUCCESS = "VENDOR_DETAIL_SUCCESS";
export const VENDOR_DETAIL_FAILURE = "VENDOR_DETAIL_FAILURE";

export const BUSINESS_TYPE_LIST_REQUEST = "BUSINESS_TYPE_LIST_REQUEST";
export const BUSINESS_TYPE_LIST_SUCCESS = "BUSINESS_TYPE_LIST_SUCCESS";
export const BUSINESS_TYPE_LIST_FAILURE = "BUSINESS_TYPE_LIST_FAILURE";

export const IMPORT_VENDORS_REQUEST = "IMPORT_VENDORS_REQUEST";
export const IMPORT_VENDORS_SUCCESS = "IMPORT_VENDORS_SUCCESS";
export const IMPORT_VENDORS_FAILURE = "IMPORT_VENDORS_FAILURE";

export const VENDOR_STATUS_UPDATE_REQUEST = "VENDOR_STATUS_UPDATE_REQUEST";
export const VENDOR_STATUS_UPDATE_SUCCESS = "VENDOR_STATUS_UPDATE_SUCCESS";
export const VENDOR_STATUS_UPDATE_FAILURE = "VENDOR_STATUS_UPDATE_FAILURE";

export const vendorList = (setIsLoading,region,isIncomplete) => {
  return async (dispatch) => {
    try {
      setIsLoading(true);
      dispatch({ type: VENDOR_LIST_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/admin/get/vendor/list/v1?regionName=${region}&incomplete=${isIncomplete}`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: VENDOR_LIST_SUCCESS, payload: response.data });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      errorHandling(dispatch, VENDOR_LIST_FAILURE, error);
    }
  };
};

export const updateVendorProfile = (id,data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: VENDOR_UPDATE_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/vendor/update/vendor/profile/${id}/v1`;
      const response = await axios.put(url,data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: VENDOR_UPDATE_SUCCESS, payload: response.data });
    } catch (error) {
      errorHandling(dispatch, VENDOR_UPDATE_FAILURE, error);
    }
  };
};

export const vendorDetails = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: VENDOR_DETAIL_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/admin/get/vendor/details/${id}/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: VENDOR_DETAIL_SUCCESS, payload: response.data });
    } catch (error) {
      errorHandling(dispatch, VENDOR_DETAIL_FAILURE, error);
    }
  };
};

export const businessTypeList = (setIsLoading) => {
  return async (dispatch) => {
    try {
      setIsLoading(true);
      dispatch({ type: BUSINESS_TYPE_LIST_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/vendor/get/all/business/type/name/for/admin/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: BUSINESS_TYPE_LIST_SUCCESS, payload: response.data });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      errorHandling(dispatch, BUSINESS_TYPE_LIST_FAILURE, error);
    }
  };
};

export const importVednors = (data, setIsLoading) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "VENDOR_STATUS_CHANGE" });
      dispatch({ type: IMPORT_VENDORS_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/admin/import/vendor/v1`;
      const response = await axios.post(
        url,
        { data },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      dispatch({ type: IMPORT_VENDORS_SUCCESS, payload: response.data });
      setIsLoading(false);
      toast("Vendor imported successfully");
    } catch (error) {
      setIsLoading(false);
      errorHandling(dispatch, IMPORT_VENDORS_FAILURE, error);
    }
  };
};

export const updateVendorStatus = ({ vendorId, status }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: VENDOR_STATUS_UPDATE_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/vendor/update/vendor/status/${vendorId}/v1`;
      const response = await axios.put(
        url,
        { status },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      dispatch({ type: VENDOR_STATUS_UPDATE_SUCCESS, payload: response.data });
      toast(`Status of vendor is change`)
    } catch (error) {
      errorHandling(dispatch, VENDOR_STATUS_UPDATE_FAILURE, error);
    }
  };
};
