import * as vetActions from "./vet.actions";

export const vetFeatureKey = "vetInfo";

const initialState = {
  loading: false,
  errorMsg: "",
  vets: [],
  isVetUpdate: false
};

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case vetActions.VET_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case vetActions.VET_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        vets: payload.data,
      };

    case vetActions.VET_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    case vetActions.VET_DETAIL_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case vetActions.VET_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        vetsDetail: payload.data,
      };

    case vetActions.VET_DETAIL_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    case vetActions.VET_STATUS_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case vetActions.VET_STATUS_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        isVetUpdate: true
      };

    case vetActions.VET_STATUS_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

      case vetActions.VET_UPDATE_REQUEST:
        return {
          ...state,
          loading: true,
        };
  
      case vetActions.VET_UPDATE_SUCCESS:
        return {
          ...state,
          loading: false,
        };
  
      case vetActions.VET_UPDATE_FAILURE:
        return {
          ...state,
          loading: false,
          errorMsg: payload,
        };

    case vetActions.IMPORT_VETS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case vetActions.IMPORT_VETS_SUCCESS:
      return {
        ...state,
        loading: false,
        isVetUpdate: true
      };

    case vetActions.IMPORT_VETS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    case "VET_STATUS_CHANGE":
      return {
        isVetUpdate: false
      }

    default:
      return state;
  }
};
