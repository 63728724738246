import axios from "axios";
import { errorHandling } from "../../helper";

export const PET_LIST_REQUEST = 'PET_LIST_REQUEST';
export const PET_LIST_SUCCESS = 'PET_LIST_SUCCESS';
export const PET_LIST_FAILURE = 'PET_LIST_FAILURE';

export const PET_DETAIL_REQUEST = 'PET_DETAIL_REQUEST';
export const PET_DETAIL_SUCCESS = 'PET_DETAIL_SUCCESS';
export const PET_DETAIL_FAILURE = 'PET_DETAIL_FAILURE';

export const PET_CATEGORIES_REQUEST = 'PET_CATEGORIES_REQUEST';
export const PET_CATEGORIES_SUCCESS = 'PET_CATEGORIES_SUCCESS';
export const PET_CATEGORIES_FAILURE = 'PET_CATEGORIES_FAILURE';

export const PET_HEALTH_CONCERNS_REQUEST = 'PET_HEALTH_CONCERNS_REQUEST';
export const PET_HEALTH_CONCERNS_SUCCESS = 'PET_HEALTH_CONCERNS_SUCCESS';
export const PET_HEALTH_CONCERNS_FAILURE = 'PET_HEALTH_CONCERNS_FAILURE';

export const PET_HEALTH_TREATMENT_AT_HOME_REQUEST = 'PET_HEALTH_TREATMENT_AT_HOME_REQUEST';
export const PET_HEALTH_TREATMENT_AT_HOME_SUCCESS = 'PET_HEALTH_TREATMENT_AT_HOME_SUCCESS';
export const PET_HEALTH_TREATMENT_AT_HOME_FAILURE = 'PET_HEALTH_TREATMENT_AT_HOME_FAILURE';


export const petList = (setIsLoading,isIncomplete) => {
    return async (dispatch) => {
        try {
            setIsLoading(true);
            dispatch({ type: PET_LIST_REQUEST });
            const url = `${process.env.REACT_APP_API_AWS_URL}/pet/admin/get/pets/list/v1?incomplete=${isIncomplete}`;
            const response = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: PET_LIST_SUCCESS, payload: response.data });
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            errorHandling(dispatch, PET_LIST_FAILURE, error);
        }
    }
};

export const petDetail = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: PET_DETAIL_REQUEST });
            const url = `${process.env.REACT_APP_API_AWS_URL}/pet/admin/get/pet/${id}/v1`;
            const response = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: PET_DETAIL_SUCCESS, payload: response.data });
        } catch (error) {
            errorHandling(dispatch, PET_DETAIL_FAILURE, error);
        }
    }
};

export const petCategories = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: PET_CATEGORIES_REQUEST });
            const url = `${process.env.REACT_APP_API_AWS_URL}/pet/admin/get/all/pet/category/v1`;
            const response = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: PET_CATEGORIES_SUCCESS, payload: response.data });
        } catch (error) {
            errorHandling(dispatch, PET_CATEGORIES_FAILURE, error);
        }
    }
};


export const getAllHealthConcerns = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: PET_HEALTH_CONCERNS_REQUEST });
            const url = `${process.env.REACT_APP_API_AWS_URL}/vet/get/health/concerns/name/v1`;
            const response = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: PET_HEALTH_CONCERNS_SUCCESS, payload: response.data });
        } catch (error) {
            errorHandling(dispatch, PET_HEALTH_CONCERNS_FAILURE, error);
        }
    }
};

export const getAllTreatments = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: PET_HEALTH_TREATMENT_AT_HOME_REQUEST });
            const url = `${process.env.REACT_APP_API_AWS_URL}/vet/get/treatments/offer/home/name/v1`;
            const response = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: PET_HEALTH_TREATMENT_AT_HOME_SUCCESS, payload: response.data });
        } catch (error) {
            errorHandling(dispatch, PET_HEALTH_TREATMENT_AT_HOME_FAILURE, error);
        }
    }
};