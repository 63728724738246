import * as bannerActions from "./banner.actions";

export const bannerFeatureKey = "bannerInfo";

const initialState = {
  loading: false,
  errorMsg: "",
  list: [],
  details: {}
};

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case bannerActions.GET_ALL_BANNERS_REQUEST:
      return {
        ...state,
        loading: true
      }

    case bannerActions.GET_ALL_BANNERS_SUCCESS:
      return {
        ...state,
        loading: false,
        list: payload.data
      }

    case bannerActions.GET_ALL_BANNERS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        list: []
      }

    case bannerActions.CREATE_BANNER_REQUEST:
      return {
        ...state,
        loading: true
      }

    case bannerActions.CREATE_BANNER_SUCCESS:
      const newList = [payload.data, ...state.list];
      return {
        ...state,
        loading: false,
        list: newList
      }

    case bannerActions.CREATE_BANNER_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload
      }

    case bannerActions.BANNER_DETAILS_REQUEST:
      return {
        ...state,
        loading: true
      }

    case bannerActions.BANNER_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        details: payload.data
      }

    case bannerActions.BANNER_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload
      }

    case bannerActions.BANNER_DELETE_REQUEST:
      return {
        ...state,
        loading: true
      }

    case bannerActions.BANNER_DELETE_SUCCESS:
      const filterArray = state.list.filter(item => item._id != payload);
      return {
        ...state,
        loading: false,
        list: filterArray
      }

    case bannerActions.BANNER_DELETE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload
      }

    case bannerActions.BANNER_UPDATE_REQUEST:
      return {
        ...state,
        loading: true
      }

    case bannerActions.BANNER_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        details: payload.data
      }

    case bannerActions.BANNER_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload
      }

    default: return state
  }
};