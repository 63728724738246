import React, { useEffect, useRef, useState } from "react";
import { Col, Modal, ModalBody, Row, Spinner } from "react-bootstrap";
import JoditEditor, { Jodit } from "jodit-react";
import * as mailActions from "../../../../redux/mail/mail.actions";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import { arrowRight } from "../../../../assets/svg/Svg";
import DataTable from "react-data-table-component";
import { imageBaseUrl } from "../../../../helper";
import axios from "axios";

const SendEmail = () => {
  const editor = useRef(null);
  // const [content, setContent] = useState("");
  const [title, setTitle] = useState("");
  const [showToolbar, setShowToolbar] = useState(true);
  const [readOnly, setReadOnly] = useState(false);
  const [data, setData] = useState([]);
  const [emailId, setEmailId] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const dispatch = useDispatch();
  const type = useParams().type;
  const navigate = useNavigate();

  const loading = useSelector((state) => state.mailInfo.loading);
  const list = useSelector((state) => state.mailInfo.list);
  const { profile } = useSelector((state) => state.authInfo);

  useEffect(() => {
    if (type) {
      dispatch(mailActions.emailList({ type }));
    }
    uploadImageButton();
  }, [type]);

  useEffect(() => {
    if (list && list.length > 0) {
      setData(list);
    } else {
      setData(list);
    }
  }, [list]);

  let editorValue;

  const config = {
    placeholder: "Start Typing......",
    toolbar: showToolbar,
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
    readonly:
      profile?.role === "admin" || profile?.mailAccess?.canCreate
        ? false
        : readOnly,
    uploader: {
      insertImageAsBase64URI: false,
    },
    extraButtons: ["uploadImage"],
    askBeforePasteHTML: false,
    askBeforePasteFromWord: true,
    events: {
      afterOpenPasteDialog: (dialog, msg, title, callback) => {
        dialog.close();
        callback();
      },
    },
  };

  const uploadImageButton = () => {
    Jodit.defaultOptions.controls.uploadImage = {
      name: "Upload image",
      iconURL:
        "https://www.kindpng.com/picc/m/261-2619141_cage-clipart-victorian-cloud-upload-icon-svg-hd.png",
      exec: async (editorImg) => {
        await imageUpload(editorImg);
      },
    };
  };

  const imageUpload = (editorImg) => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async function () {
      const imageFile = input.files[0];

      if (!imageFile) {
        return;
      }

      if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
        return;
      }

      const imageInfo = await fileUpload(imageFile);
      const imgUrl = await `${imageBaseUrl}${imageInfo}`;

      insertImage(editorImg, imgUrl);
    }.bind(this);
  };

  const insertImage = (editorImg, url) => {
    const image = editorImg.selection.j.createInside.element("img");
    image.setAttribute("src", url);
    editorImg.selection.insertNode(image);
  };

  const fileUpload = async (file) => {
    // upload image on aws S3
    const imgRes = await axios.post(
      `${process.env.REACT_APP_API_AWS_URL}/static/upload/image/v1`,
      {
        sFileName: file.name,
        sContentType: file.type,
      },
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    const url = imgRes.data.data.sUrl;
    const sImage = imgRes.data.data.sPath;
    await axios.put(url, file, { headers: { "Content-Type": file.type } });

    return sImage;
  };

  const handleBlurEvent = (e) => {
    editorValue = e;
  };

  const handleSendEmail = () => {
    if (!title) {
      toast("Please enter title");
      return;
    }

    if (!editorValue) {
      toast("Please enter mail content");
      return;
    }

    dispatch(
      mailActions.sendEmail(
        { subject: title, body: editorValue, type },
        navigate
      )
    );
  };

  // history table
  const columns = [
    {
      name: "Title",
      selector: (row) => row.subject,
      sortable: true,
    },
    {
      name: "Created At",
      sortable: true,
      selector: (row) =>
        moment(new Date(row.createdAt)).format("DD-MM-YYYY h:mm a"),
    },
    {
      ...((profile?.role == "admin" ||
        profile?.mailAccess?.canCreate) && {
        name: "Action",
        selector: (row) => (
          <button
            className="btn_primary_outline_sm ms-auto"
            onClick={(e) => manageReSendEmail(row._id)}
          >
            RE-SEND
          </button>
        ),
      }),
    },
  ];

  const manageReSendEmail = (id) => {
    setEmailId(id);
    setModalShow(true);
  };

  const filteredData = data;

  const paginationOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
    noRowsPerPage: false,
    selectAllRowsItem: false,
    selectAllRowsItemText: "All",
  };

  const customPagination = () => {
    const page = Math.ceil(filteredData.length / paginationPerPage);
    const pageButtons = [];
    const startPage = Math.max(currentPage - 1, 1);
    const endPage = Math.min(currentPage + 1, page);

    // Always add the first page
    pageButtons.push(
      <button
        key={1}
        className={`btn-pagination ${currentPage === 1 ? "active" : ""}`}
        onClick={() => setCurrentPage(1)}
      >
        1
      </button>
    );

    // Ellipsis after the first page if necessary
    if (startPage > 2) {
      pageButtons.push(
        <span key="ellipsis-start" className="pagination-ellipsis">
          &hellip;
        </span>
      );
    }

    // Range of buttons around the current page
    for (let i = startPage; i <= endPage; i++) {
      if (i !== 1 && i !== page) {
        pageButtons.push(
          <button
            key={i}
            className={`btn-pagination ${currentPage === i ? "active" : ""}`}
            onClick={() => setCurrentPage(i)}
          >
            {i}
          </button>
        );
      }
    }

    // Ellipsis before the last page if necessary
    if (endPage < page - 1) {
      pageButtons.push(
        <span key="ellipsis-end" className="pagination-ellipsis">
          &hellip;
        </span>
      );
    }

    // Always add the last page
    if (page !== 1) {
      pageButtons.push(
        <button
          key={page}
          className={`btn-pagination ${currentPage === page ? "active" : ""}`}
          onClick={() => setCurrentPage(page)}
        >
          {page}
        </button>
      );
    }

    return (
      <div className="pagination-container mt-5">
        <button
          className="btn-pagination btn-pagi_prev"
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          {arrowRight}
        </button>
        <span>{pageButtons}</span>
        <button
          className="btn-pagination btn-pagi_next"
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage === page}
        >
          {arrowRight}
        </button>
      </div>
    );
  };

  const paginationPerPage = 10;
  const [currentPage, setCurrentPage] = React.useState(1);

  const paginatedData = filteredData.slice(
    (currentPage - 1) * paginationPerPage,
    currentPage * paginationPerPage
  );

  const handleReSendEmail = (id) => {
    dispatch(
      mailActions.sendEmail(
        {
          subject: data.filter((elem) => emailId == elem._id)[0].subject,
          body: data.filter((elem) => emailId == elem._id)[0].body,
          type,
        },
        navigate
      )
    );
  };

  return (
    <div>
      <div className="page-header">
        <h3 className="page-title">Mail Admin</h3>
      </div>
      <div className="row">
        <div className="col-lg-12 mx-auto grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <Row>
                <Col className="mx-auto" md={12}>
                  <Row>
                    <Col md={12}>
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Mail Title
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="exampleInputEmail1"
                          placeholder="Title"
                          aria-describedby="emailHelp"
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                        />
                      </div>
                    </Col>
                    <Col md={12}>
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Mail Content
                        </label>
                        <JoditEditor
                          ref={editor}
                          value={editorValue}
                          onBlur={(newContent) => handleBlurEvent(newContent)}
                          config={config}
                        />
                      </div>
                    </Col>
                    {(profile?.role == "admin" ||
                      profile?.mailAccess?.canCreate) && (
                      <Col md={3} className="">
                        <button
                          className="btn_primary_outline_sm py-2 mt-4 w-100"
                          onClick={(e) => handleSendEmail()}
                        >
                          {loading ? <Spinner></Spinner> : "SEND"}
                        </button>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
      {data && data.length > 0 && (
        <div>
          <div className="page-header">
            <h3 className="page-title mt-3">History</h3>
          </div>
          <div className="row">
            <div className="col-lg-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body py-4">
                  <>
                    <DataTable
                      columns={columns}
                      data={paginatedData}
                      pagination
                      paginationServer
                      paginationTotalRows={filteredData.length}
                      onChangePage={(page) => setCurrentPage(page)}
                      paginationComponentOptions={paginationOptions}
                      paginationComponent={customPagination}
                      highlightOnHover
                      pointerOnHover
                      subHeader
                      subHeaderAlign="left"
                    />
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Modal
        show={modalShow}
        size="sx"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <ModalBody>
          <h5 className="mb-3">Are you sure?</h5>
        </ModalBody>
        <Modal.Footer>
          <button
            type="button"
            className="btn_secondary"
            onClick={() => {
              setModalShow(false);
              setEmailId(null);
            }}
          >
            CANCEL
          </button>
          <button
            type="button"
            className="btn_primary"
            disabled={loading}
            onClick={(e) => handleReSendEmail(emailId)}
          >
            {loading ? (
              <>
                <div
                  className="spinner-border text-primary mx-auto"
                  role="status"
                ></div>
              </>
            ) : (
              "SEND"
            )}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SendEmail;
