import * as vendorActions from "./vendor.actions";

export const vendorFeatureKey = "vendorInfo";

const initialState = {
  loading: false,
  errorMsg: "",
  venders: [],
  businessTypes: [],
  isVendorStatusChange: false,
  isVendorUpdate:false
};

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case vendorActions.VENDOR_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case vendorActions.VENDOR_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        venders: payload.data,
      };

    case vendorActions.VENDOR_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

      case vendorActions.VENDOR_UPDATE_REQUEST:
        return {
          ...state,
          loading: true,
        };
  
      case vendorActions.VENDOR_UPDATE_SUCCESS:
        return {
          ...state,
          loading: false,
        };
  
      case vendorActions.VENDOR_UPDATE_FAILURE:
        return {
          ...state,
          loading: false,
          errorMsg: payload,
        };

    case vendorActions.VENDOR_DETAIL_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case vendorActions.VENDOR_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        vendorDetail: payload.data,
      };

    case vendorActions.VENDOR_DETAIL_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    case vendorActions.BUSINESS_TYPE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case vendorActions.BUSINESS_TYPE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        businessTypes: payload.data,
      };

    case vendorActions.BUSINESS_TYPE_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    case vendorActions.IMPORT_VENDORS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case vendorActions.IMPORT_VENDORS_SUCCESS:
      return {
        ...state,
        loading: false,
        isVendorStatusChange: true,
      };

    case vendorActions.IMPORT_VENDORS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    case vendorActions.VENDOR_STATUS_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case vendorActions.VENDOR_STATUS_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        isVendorUpdate:true
      };

    case vendorActions.VENDOR_STATUS_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };
    case "VENDOR_STATUS_CHANGE":
      return {
        ...state,
        isVendorStatusChange: false,
      };

    default:
      return state;
  }
};
