import axios from "axios";
import { toast } from "react-toastify";
import { errorHandling } from "../../helper";

export const GET_ABOUT_US_REQUEST = "GET_ABOUT_US_REQUEST";
export const GET_ABOUT_US_SUCCESS = "GET_ABOUT_US_SUCCESS";
export const GET_ABOUT_US_FAILURE = "GET_ABOUT_US_FAILURE";

export const GET_CONTACT_US_REQUEST = "GET_CONTACT_US_REQUEST";
export const GET_CONTACT_US_SUCCESS = "GET_CONTACT_US_SUCCESS";
export const GET_CONTACT_US_FAILURE = "GET_CONTACT_US_FAILURE";

export const GET_USER_TERMS_REQUEST = "GET_USER_TERMS_REQUEST";
export const GET_USER_TERMS_SUCCESS = "GET_USER_TERMS_SUCCESS";
export const GET_USER_TERMS_FAILURE = "GET_USER_TERMS_FAILURE";

export const GET_USER_SUPPORT_REQUEST = "GET_USER_SUPPORT_REQUEST";
export const GET_USER_SUPPORT_SUCCESS = "GET_USER_SUPPORT_SUCCESS";
export const GET_USER_SUPPORT_FAILURE = "GET_USER_SUPPORT_FAILURE";

export const GET_USER_PRIVACY_REQUEST = "GET_USER_PRIVACY_REQUEST";
export const GET_USER_PRIVACY_SUCCESS = "GET_USER_PRIVACY_SUCCESS";
export const GET_USER_PRIVACY_FAILURE = "GET_USER_PRIVACY_FAILURE";

export const GET_USER_CANCELLATION_POLICY_REQUEST = "GET_USER_CANCELLATION_POLICY_REQUEST";
export const GET_USER_CANCELLATION_POLICY_SUCCESS = "GET_USER_CANCELLATION_POLICY_SUCCESS";
export const GET_USER_CANCELLATION_POLICY_FAILURE = "GET_USER_CANCELLATION_POLICY_FAILURE";

export const GET_VET_VENDOR_TERMS_REQUEST = "GET_VET_VENDOR_TERMS_REQUEST";
export const GET_VET_VENDOR_TERMS_SUCCESS = "GET_VET_VENDOR_TERMS_SUCCESS";
export const GET_VET_VENDOR_TERMS_FAILURE = "GET_VET_VENDOR_TERMS_FAILURE";

export const GET_VET_VENDOR_SUPPORT_REQUEST = "GET_VET_VENDOR_SUPPORT_REQUEST";
export const GET_VET_VENDOR_SUPPORT_SUCCESS = "GET_VET_VENDOR_SUPPORT_SUCCESS";
export const GET_VET_VENDOR_SUPPORT_FAILURE = "GET_VET_VENDOR_SUPPORT_FAILURE";

export const GET_VET_VENDOR_PRIVACY_REQUEST = "GET_VET_VENDOR_PRIVACY_REQUEST";
export const GET_VET_VENDOR_PRIVACY_SUCCESS = "GET_VET_VENDOR_PRIVACY_SUCCESS";
export const GET_VET_VENDOR_PRIVACY_FAILURE = "GET_VET_VENDOR_PRIVACY_FAILURE";

export const UPDATE_STATIC_REQUEST = "UPDATE_STATIC_REQUEST";
export const UPDATE_STATIC_SUCCESS = "UPDATE_STATIC_SUCCESS";
export const UPDATE_STATIC_FAILURE = "UPDATE_STATIC_FAILURE";

export const getAboutUsPage = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_ABOUT_US_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/static/about/page/v1`;
      const response = await axios.get(url);
      dispatch({ type: GET_ABOUT_US_SUCCESS, payload: response.data });
    } catch (error) {
      errorHandling(dispatch, GET_ABOUT_US_FAILURE, error);
    }
  };
};

export const getContactUsPage = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_CONTACT_US_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/static/contact/page/v1`;
      const response = await axios.get(url);
      dispatch({ type: GET_CONTACT_US_SUCCESS, payload: response.data });
    } catch (error) {
      errorHandling(dispatch, GET_CONTACT_US_FAILURE, error);
    }
  };
};

export const getUserTermsPage = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_USER_TERMS_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/static/user/terms/page/v1`;
      const response = await axios.get(url);
      dispatch({ type: GET_USER_TERMS_SUCCESS, payload: response.data });
    } catch (error) {
      errorHandling(dispatch, GET_USER_TERMS_FAILURE, error);
    }
  };
};

export const getUserSupportPage = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_USER_SUPPORT_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/static/user/support/page/v1`;
      const response = await axios.get(url);
      dispatch({ type: GET_USER_SUPPORT_SUCCESS, payload: response.data });
    } catch (error) {
      errorHandling(dispatch, GET_USER_SUPPORT_FAILURE, error);
    }
  };
};

export const getUserPrivacyPage = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_USER_PRIVACY_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/static/user/privacy/page/v1`;
      const response = await axios.get(url);
      dispatch({ type: GET_USER_PRIVACY_SUCCESS, payload: response.data });
    } catch (error) {
      errorHandling(dispatch, GET_USER_PRIVACY_FAILURE, error);
    }
  };
};

export const getUserCancellationPolicy = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_USER_CANCELLATION_POLICY_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/static/user/cancellation/policy/page/v1`;
      const response = await axios.get(url);
      dispatch({ type: GET_USER_CANCELLATION_POLICY_SUCCESS, payload: response.data });
    } catch (error) {
      errorHandling(dispatch, GET_USER_CANCELLATION_POLICY_FAILURE, error);
    }
  };
};

export const getVetVendorTermsPage = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_VET_VENDOR_TERMS_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/static/vet/terms/page/v1`;
      const response = await axios.get(url);
      dispatch({ type: GET_VET_VENDOR_TERMS_SUCCESS, payload: response.data });
    } catch (error) {
      errorHandling(dispatch, GET_VET_VENDOR_TERMS_FAILURE, error);
    }
  };
};

export const getVetVendorSupportPage = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_VET_VENDOR_SUPPORT_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/static/vet/support/page/v1`;
      const response = await axios.get(url);
      dispatch({
        type: GET_VET_VENDOR_SUPPORT_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      errorHandling(dispatch, GET_VET_VENDOR_SUPPORT_FAILURE, error);
    }
  };
};

export const getVetVendorPrivacyPage = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_VET_VENDOR_PRIVACY_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/static/vet/privacy/page/v1`;
      const response = await axios.get(url);
      dispatch({
        type: GET_VET_VENDOR_PRIVACY_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      errorHandling(dispatch, GET_VET_VENDOR_PRIVACY_FAILURE, error);
    }
  };
};

export const updateStaticPage = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_STATIC_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/static/update/page/v1`;
      const response = await axios.post(url, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: UPDATE_STATIC_SUCCESS, payload: response.data });
      toast("Updated successfully");
    } catch (error) {
      errorHandling(dispatch, UPDATE_STATIC_FAILURE, error);
    }
  };
};
