import axios from "axios";
import { toast } from "react-toastify";
import { errorHandling } from "../../helper";

export const CONSULTATION_LIST_REQUEST = 'CONSULTATION_LIST_REQUEST';
export const CONSULTATION_LIST_SUCCESS = 'CONSULTATION_LIST_SUCCESS';
export const CONSULTATION_LIST_FAILURE = 'CONSULTATION_LIST_FAILURE';

export const CONSULTATION_DETAILS_REQUEST = 'CONSULTATION_DETAILS_REQUEST';
export const CONSULTATION_DETAILS_SUCCESS = 'CONSULTATION_DETAILS_SUCCESS';
export const CONSULTATION_DETAILS_FAILURE = 'CONSULTATION_DETAILS_FAILURE';

export const consultationList = (consultationStage) => {
  return async (dispatch) => {
    try {
      dispatch({ type: CONSULTATION_LIST_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/user/admin/get/all/asign/vet/schedule/v1?consultationStage=${consultationStage}`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token")
        }
      });
      dispatch({ type: CONSULTATION_LIST_SUCCESS, payload: response.data });
    } catch (error) {
      errorHandling(dispatch, CONSULTATION_LIST_FAILURE, error);
    }
  }
};

export const consultationDetails = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: CONSULTATION_DETAILS_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/user/admin/get/asign/vet/schedule/${id}/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token")
        }
      });
      dispatch({ type: CONSULTATION_DETAILS_SUCCESS, payload: response.data });
    } catch (error) {
      errorHandling(dispatch, CONSULTATION_DETAILS_FAILURE, error);
    }
  }
};