import * as userActions from "./user.actions";

export const userFeatureKey = "userInfo";

const initialState = {
    loading: false,
    errorMsg: "",
    users: [],
    searchResult: {}
};

export const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case userActions.USER_LIST_REQUEST:
            return {
                ...state,
                loading: true
            }

        case userActions.USER_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                users: payload.data
            }

        case userActions.USER_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case userActions.USER_UPDATE_REQUEST:
            return {
                ...state,
                loading: true
            }

        case userActions.USER_UPDATE_SUCCESS:
            return {
                ...state,
                loading: false,
            }

        case userActions.USER_UPDATE_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }


        case userActions.USER_DETAIL_REQUEST:
            return {
                ...state,
                loading: true
            }

        case userActions.USER_DETAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                usersDetail: payload.data
            }

        case userActions.USER_DETAIL_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case userActions.SEND_NOTIFICATION_REQUEST:
            return {
                ...state,
                loading: true
            }

        case userActions.SEND_NOTIFICATION_SUCCESS:
            return {
                ...state,
                loading: false
            }

        case userActions.SEND_NOTIFICATION_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case userActions.SEND_EMAIL_REQUEST:
            return {
                ...state,
                loading: true
            }

        case userActions.SEND_EMAIL_SUCCESS:
            return {
                ...state,
                loading: false
            }

        case userActions.SEND_EMAIL_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }


        case userActions.GLOBAL_SEARCH_PANEL_REQUEST:
            return {
                ...state,
                loading: true
            }

        case userActions.GLOBAL_SEARCH_PANEL_SUCCESS:
            return {
                ...state,
                loading: false,
                searchResult: payload?.data
            }

        case userActions.GLOBAL_SEARCH_PANEL_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        default: return state
    }
};