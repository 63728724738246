import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import NavLink from "../components/NavLink";
import { LuLayoutDashboard, LuRectangleHorizontal } from "react-icons/lu";
import { MdOutlinePets } from "react-icons/md";
import {
  IoVideocamOutline,
  IoDocumentTextOutline,
  IoSettingsOutline,
} from "react-icons/io5";
import { TbUsersGroup, TbUsers } from "react-icons/tb";
import { SiAwsorganizations } from "react-icons/si";
import { CiBoxes } from "react-icons/ci";
import { FaUserDoctor } from "react-icons/fa6";
import { IoIosArrowDown, IoMdArrowDropright } from "react-icons/io";
import { FiFileText } from "react-icons/fi";
import { RiCoupon3Fill, RiShieldUserLine, RiKeyLine } from "react-icons/ri";
import { GoRepoPush } from "react-icons/go";
import { RiMailSendLine } from "react-icons/ri";
import { useSelector } from "react-redux";

// const sidebarData = [
//   {
//     icon: <LuLayoutDashboard size={18} />,
//     title: "Dashboard",
//     url: "/dashboard",
//   },
//   {
//     icon: <TbUsers size={18} />,
//     title: "Users",
//     url: "/users",
//   },
//   {
//     icon: <SiAwsorganizations strokeWidth={0.7} size={18} />,
//     title: "Vendors",
//     url: "/vendors",
//   },
//   {
//     icon: <CiBoxes strokeWidth={0.7} size={20} />,
//     title: "Products",
//     url: "/products",
//   },
//   {
//     icon: <FaUserDoctor strokeWidth={0} size={18} />,
//     title: "Vets",
//     url: "/vets",
//   },
//   {
//     icon: <MdOutlinePets strokeWidth={0} size={18} />,
//     title: "Pets",
//     url: "/pets",
//   },
//   {
//     icon: <IoDocumentTextOutline strokeWidth={1} size={18} />,
//     title: "Blogs",
//     url: "/blogs",
//   },
//   {
//     icon: <IoVideocamOutline strokeWidth={0} size={18} />,
//     title: "Videos",
//     url: "/videos",
//   },
// ].filter(Boolean);
const sidebarData = [
  {
    commonUrl: "/dashboard",
    primary: {
      iconClass: <LuLayoutDashboard size={18} />,
      title: "Dashboard",
      url: "/dashboard",
    },
  },
  {
    commonUrl: "/sub-admins",
    primary: {
      iconClass: <TbUsersGroup size={18} />,
      title: "Sub Admins",
      url: "/sub-admins",
    },
  },
  {
    commonUrl: "/users",
    primary: {
      iconClass: <TbUsers size={18} />,
      title: "Users",
      url: "/users",
    },
  },
  {
    commonUrl: "/vendors",
    primary: {
      iconClass: <SiAwsorganizations strokeWidth={0.7} size={18} />,
      title: "Vendors",
      url: "/vendors",
    },
  },
  {
    commonUrl: "/vets",
    primary: {
      iconClass: <FaUserDoctor strokeWidth={0} size={18} />,
      title: "Vets",
      url: "/vets",
    },
  },
  {
    commonUrl: "/products",
    primary: {
      iconClass: <CiBoxes strokeWidth={0.7} size={20} />,
      title: "Products",
      url: "/products",
    },
  },
  {
    commonUrl: "/pets",
    primary: {
      iconClass: <MdOutlinePets strokeWidth={0} size={18} />,
      title: "Pets",
      url: "/pets",
    },
  },
  {
    commonUrl: "/banners",
    primary: {
      iconClass: <LuRectangleHorizontal strokeWidth={1} size={18} />,
      title: "Banners",
      url: "/banners",
    },
  },
  {
    commonUrl: "/blogs",
    primary: {
      iconClass: <IoDocumentTextOutline strokeWidth={1} size={18} />,
      title: "Blogs",
      url: "/blogs",
    },
  },
  {
    commonUrl: "/videos",
    primary: {
      iconClass: <IoVideocamOutline strokeWidth={0} size={18} />,
      title: "Videos",
      url: "/videos",
    },
  },
  {
    commonUrl: "/orders",
    primary: {
      iconClass: <IoVideocamOutline strokeWidth={0} size={18} />,
      title: "Orders",
      url: "/orders",
    },
  },
  {
    commonUrl: "/payments",
    primary: {
      iconClass: <IoVideocamOutline strokeWidth={0} size={18} />,
      title: "Payment",
      url: "/payments",
    },
  },
  {
    commonUrl: "/health-cards",
    primary: {
      iconClass: <IoVideocamOutline strokeWidth={0} size={18} />,
      title: "E-health Card",
      url: "/health-cards",
    },
  },
  {
    commonUrl: "/homecares",
    primary: {
      iconClass: <IoVideocamOutline strokeWidth={0} size={18} />,
      title: "Homecare",
      url: "/homecares",
    },
  },
  {
    commonUrl: "/consultations",
    primary: {
      iconClass: <IoVideocamOutline strokeWidth={0} size={18} />,
      title: "Consultation",
      url: "/consultations",
    },
  },
  {
    commonUrl: "/aboutus",
    primary: {
      iconClass: <IoVideocamOutline strokeWidth={0} size={18} />,
      title: "About Us",
      url: "/aboutus",
    },
  },
  {
    commonUrl: "/contactus",
    primary: {
      iconClass: <IoVideocamOutline strokeWidth={0} size={18} />,
      title: "Contact Us",
      url: "/contactus",
    },
  },
  {
    commonUrl: "/policies",
    primary: {
      iconClass: <RiKeyLine strokeWidth={0} size={18} />,
      title: "Policy",
      url: "/policies",
    },
  },
  {
    commonUrl: "/ambulance",
    primary: {
      iconClass: <IoVideocamOutline strokeWidth={0} size={18} />,
      title: "Ambulance",
      url: "/ambulance",
    },
  },
  {
    commonUrl: "/government-pet",
    primary: {
      iconClass: <IoVideocamOutline strokeWidth={0} size={18} />,
      title: "Government Pet",
      url: "/government-pet",
    },
  },
  {
    commonUrl: "/report-abuse",
    primary: {
      iconClass: <FiFileText strokeWidth={1.5} size={18} />,
      title: "Report Abuse",
      url: "/report-abuse",
    },
  },
  // {
  //   commonUrl: "/sub-admin",
  //   primary: {
  //     iconClass: <RiShieldUserLine size={18} />,
  //     title: "Sub Admin",
  //     url: "/sub-admin",
  //   },
  // },
  {
    commonUrl: "/app-setting",
    primary: {
      iconClass: <IoSettingsOutline strokeWidth={30} size={18} />,
      title: "App Setting",
      url: "/app-setting",
    },
  },
  {
    commonUrl: "/marketing-notification",
    primary: {
      iconClass: <GoRepoPush size={18} />,
      title: "Marketing Notification",
      url: "/marketing-notification",
    },
  },
  {
    commonUrl: "/push-notification",
    primary: {
      iconClass: <GoRepoPush size={18} />,
      title: "Push Notification",
      url: "/push-notification",
    },
  },
  {
    commonUrl: "/mail",
    primary: {
      iconClass: <RiMailSendLine size={18} />,
      title: "Mail",
      url: "/mail",
    },
  },
  {
    commonUrl: "/coupon-code",
    primary: {
      iconClass: <RiCoupon3Fill size={18} />,
      title: "Coupon Code",
      url: "/coupon-code",
    },
  },
  {
    primary: {
      iconClass: <IoIosArrowDown />,
      title: "Dynamic Dropdowns",
    },
    secondary: [
      {
        iconClass: <IoMdArrowDropright className="ms-0 me-2" />,
        title: "Vet",
        url: "/vet-field",
      },
      {
        iconClass: <IoMdArrowDropright className="ms-0 me-2" />,
        title: "Vendor",
        url: "/vendor-field",
      },
      {
        iconClass: <IoMdArrowDropright className="ms-0 me-2" />,
        title: "User",
        url: "/user-field",
      },
      {
        iconClass: <IoMdArrowDropright className="ms-0 me-2" />,
        title: "Product",
        url: "/product-field",
      },
      {
        iconClass: <IoMdArrowDropright className="ms-0 me-2" />,
        title: "Order",
        url: "/order-field/order-cancel-reason",
      },
      {
        iconClass: <IoMdArrowDropright className="ms-0 me-2" />,
        title: "Report Abuse",
        url: "/report-abuse-field/animal-report",
      },
    ],
  },
];

const Sidebar = () => {
  const location = useLocation();
  const [menuState, setMenuState] = useState({});
  const [bodyClass, setBodyClass] = useState("sidebar-icon-only");
  const profile = useSelector((state) => state.authInfo.profile);

  const toggleMenuState = (menuStateKey) => {
    setMenuState((prevState) => ({
      ...Object.keys(prevState).reduce((acc, key) => {
        acc[key] = false;
        return acc;
      }, {}),
      [menuStateKey]: !prevState[menuStateKey],
    }));
  };

  const onRouteChanged = () => {
    document.querySelector("#sidebar").classList.remove("active");
    setMenuState({});
  };

  const isPathActive = (path) => {
    return location.pathname.startsWith(path);
  };

  useEffect(() => {
    onRouteChanged();
    const body = document.querySelector("body");
    document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
      el.addEventListener("mouseover", () => {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", () => {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });

    return () => {
      // Clean up event listeners if needed
    };
  }, [location]);

  return (
    <nav className="sidebar sidebar-offcanvas" id="sidebar">
      <ul className="nav">
        {sidebarData.map((data, i) => {
          const { title } = data.primary;
          if (title === "Sub Admins" && profile.role !== "admin") {
            return null; // Only admins can see Sub Admins
          }
          if (
            title === "Users" &&
            !(profile?.usersAccess?.canRead || profile.role === "admin")
          ) {
            return null;
          }
          if (
            title === "Vets" &&
            !(profile?.vetsAccess?.canRead || profile.role === "admin")
          ) {
            return null;
          }
          if (
            title === "Vendors" &&
            !(profile?.vendorsAccess?.canRead || profile.role === "admin")
          ) {
            return null;
          }
          if (
            title === "Products" &&
            !(profile?.productsAccess?.canRead || profile.role === "admin")
          ) {
            return null;
          }
          if (
            title === "Pets" &&
            !(profile?.petsAccess?.canRead || profile.role === "admin")
          ) {
            return null;
          }
          if (
            title === "Banners" &&
            !(profile?.bannersAccess?.canRead || profile.role === "admin")
          ) {
            return null;
          }
          if (
            title === "Blogs" &&
            !(profile?.blogsAccess?.canRead || profile.role === "admin")
          ) {
            return null;
          }
          if (
            title === "Videos" &&
            !(profile?.videosAccess?.canRead || profile.role === "admin")
          ) {
            return null;
          }
          if (
            title === "Orders" &&
            !(profile?.ordersAccess?.canRead || profile.role === "admin")
          ) {
            return null;
          }
          if (
            title === "Payment" &&
            !(profile?.paymentsAccess?.canRead || profile.role === "admin")
          ) {
            return null;
          }
          if (
            title === "E-health Card" &&
            !(profile?.ehealthcardAccess?.canRead || profile.role === "admin")
          ) {
            return null;
          }
          if (
            title === "Homecare" &&
            !(profile?.homecareAccess?.canRead || profile.role === "admin")
          ) {
            return null;
          }
          if (
            title === "Consultation" &&
            !(profile?.consultationsAccess?.canRead || profile.role === "admin")
          ) {
            return null;
          }
          if (
            title === "About Us" &&
            !(profile?.aboutusAccess?.canRead || profile.role === "admin")
          ) {
            return null;
          }
          if (
            title === "Contact Us" &&
            !(profile?.contactusAccess?.canRead || profile.role === "admin")
          ) {
            return null;
          }
          if (
            title === "Policy" &&
            !(profile?.policyAccess?.canRead || profile.role === "admin")
          ) {
            return null;
          }
          if (
            title === "Ambulance" &&
            !(profile?.ambulanceAccess?.canRead || profile.role === "admin")
          ) {
            return null;
          }
          if (
            title === "Government Pet" &&
            !(
              profile?.governmentpetsAccess?.canRead || profile.role === "admin"
            )
          ) {
            return null;
          }
          if (
            title === "Report Abuse" &&
            !(profile?.reportabuseAccess?.canRead || profile.role === "admin")
          ) {
            return null;
          }
          if (
            title === "App Setting" &&
            !(profile?.appsettingsAccess?.canRead || profile.role === "admin")
          ) {
            return null;
          }
          if (
            title === "Marketing Notification" &&
            !(
              profile?.marketingnotificationsAccess?.canRead ||
              profile.role === "admin"
            )
          ) {
            return null;
          }
          if (
            title === "Push Notification" &&
            !(
              profile?.pushnotificationsAccess?.canRead ||
              profile.role === "admin"
            )
          ) {
            return null;
          }
          if (
            title === "Mail" &&
            !(profile?.mailAccess?.canRead || profile.role === "admin")
          ) {
            return null;
          }
          if (
            title === "Coupon Code" &&
            !(profile?.couponcodeAccess?.canRead || profile.role === "admin")
          ) {
            return null;
          }
          if (
            title === "Dynamic Dropdowns" &&
            !(profile?.dropdownsAccess?.canRead || profile.role === "admin")
          ) {
            return null;
          }
          return (
            <NavLink
              key={i}
              isPathActive={isPathActive}
              url={data.primary.url}
              iconClass={data.primary.iconClass}
              title={data.primary.title}
              commonUrl={data.commonUrl}
              child={data.secondary}
            />
          );
        })}
      </ul>
    </nav>
  );
};

export default Sidebar;
