import axios from "axios";
import { toast } from "react-toastify";
import { errorHandling } from "../../helper";

export const PAYMENT_LIST_REQUEST = 'PAYMENT_LIST_REQUEST';
export const PAYMENT_LIST_SUCCESS = 'PAYMENT_LIST_SUCCESS';
export const PAYMENT_LIST_FAILURE = 'PAYMENT_LIST_FAILURE';

export const PAYMENT_DETAILS_REQUEST = 'PAYMENT_DETAILS_REQUEST';
export const PAYMENT_DETAILS_SUCCESS = 'PAYMENT_DETAILS_SUCCESS';
export const PAYMENT_DETAILS_FAILURE = 'PAYMENT_DETAILS_FAILURE';

export const paymentList = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: PAYMENT_LIST_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/payment/get/all/payment/for/admin/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token")
        }
      });
      dispatch({ type: PAYMENT_LIST_SUCCESS, payload: response.data });
    } catch (error) {
      errorHandling(dispatch, PAYMENT_LIST_FAILURE, error);
    }
  }
};

export const paymentDetails = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: PAYMENT_DETAILS_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/payment/admin/get/single/payment/${id}/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token")
        }
      });
      dispatch({ type: PAYMENT_DETAILS_SUCCESS, payload: response.data });
    } catch (error) {
      errorHandling(dispatch, PAYMENT_DETAILS_FAILURE, error);
    }
  }
};