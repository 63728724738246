import * as homecareActions from "./homecare.actions";

export const homecareFeatureKey = "homecareInfo";

const initialState = {
  loading: false,
  errorMsg: "",
  list: [],
  details: {}
};

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case homecareActions.HOMECARE_LIST_REQUEST:
      return {
        ...state,
        loading: true
      }

    case homecareActions.HOMECARE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: payload.data
      }

    case homecareActions.HOMECARE_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        list: []
      }

    case homecareActions.HOMECARE_DETAILS_REQUEST:
      return {
        ...state,
        loading: true
      }

    case homecareActions.HOMECARE_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        details: payload.data
      }

    case homecareActions.HOMECARE_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        details: {}
      }

    default: return state
  }
};