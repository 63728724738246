import axios from "axios";
import { toast } from "react-toastify";
import { errorHandling } from "../../helper";

export const AMBULANCE_LIST_REQUEST = 'AMBULANCE_LIST_REQUEST';
export const AMBULANCE_LIST_SUCCESS = 'AMBULANCE_LIST_SUCCESS';
export const AMBULANCE_LIST_FAILURE = 'AMBULANCE_LIST_FAILURE';

export const AMBULANCE_CREATE_REQUEST = 'AMBULANCE_CREATE_REQUEST';
export const AMBULANCE_CREATE_SUCCESS = 'AMBULANCE_CREATE_SUCCESS';
export const AMBULANCE_CREATE_FAILURE = 'AMBULANCE_CREATE_FAILURE';

export const AMBULANCE_UPDATE_REQUEST = 'AMBULANCE_UPDATE_REQUEST';
export const AMBULANCE_UPDATE_SUCCESS = 'AMBULANCE_UPDATE_SUCCESS';
export const AMBULANCE_UPDATE_FAILURE = 'AMBULANCE_UPDATE_FAILURE';

export const AMBULANCE_DELETE_REQUEST = 'AMBULANCE_DELETE_REQUEST';
export const AMBULANCE_DELETE_SUCCESS = 'AMBULANCE_DELETE_SUCCESS';
export const AMBULANCE_DELETE_FAILURE = 'AMBULANCE_DELETE_FAILURE';

export const ambulanceList = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: AMBULANCE_LIST_REQUEST });
            const url = `${process.env.REACT_APP_API_AWS_URL}/ambulance/list/v1`;
            const response = await axios.get(url);
            dispatch({ type: AMBULANCE_LIST_SUCCESS, payload: response.data });
        } catch (error) {
            errorHandling(dispatch, AMBULANCE_LIST_FAILURE, error);
        }
    }
};

export const ambulanceCreate = (data, setModal, setData) => {
    return async (dispatch) => {
        try {
            dispatch({ type: AMBULANCE_CREATE_REQUEST });
            if (data && data.image && data.image.file) {
                // image upload
                const imgRes = await axios.post(`${process.env.REACT_APP_API_AWS_URL}/ambulance/upload/image/v1`, {
                    sFileName: data.image.file.name,
                    sContentType: data.image.file.type
                }, {
                    headers: {
                        Authorization: localStorage.getItem('token')
                    }
                });
                const surl = imgRes.data.data.sUrl
                const sImage = imgRes.data.data.sPath
                const putRes = await axios.put(surl, data.image.file, { headers: { "Content-Type": data.image.file.type } });

                const url = `${process.env.REACT_APP_API_AWS_URL}/ambulance/create/v1`;
                const response = await axios.post(url, { ...data, image: sImage }, {
                    headers: {
                        Authorization: localStorage.getItem("token")
                    }
                });
                dispatch({ type: AMBULANCE_CREATE_SUCCESS, payload: response.data });
            } else {
                const url = `${process.env.REACT_APP_API_AWS_URL}/ambulance/create/v1`;
                const response = await axios.post(url, data, {
                    headers: {
                        Authorization: localStorage.getItem("token")
                    }
                });
                dispatch({ type: AMBULANCE_CREATE_SUCCESS, payload: response.data });
            }
            setModal(false);
            setData({
                image: null,
                name: "",
                location: "",
                phone: ""
            });
            toast("Ambulace details created successfully.");
        } catch (error) {
            errorHandling(dispatch, AMBULANCE_CREATE_FAILURE, error);
        }
    }
};

export const ambulanceUpdate = (data, setModal, setData, id, setSelectedId) => {
    return async (dispatch) => {
        try {
            dispatch({ type: AMBULANCE_UPDATE_REQUEST });
            if (data && data.image && data.image.file) {
                // image upload
                const imgRes = await axios.post(`${process.env.REACT_APP_API_AWS_URL}/ambulance/upload/image/v1`, {
                    sFileName: data.image.file.name,
                    sContentType: data.image.file.type
                }, {
                    headers: {
                        Authorization: localStorage.getItem('token')
                    }
                });
                const surl = imgRes.data.data.sUrl
                const sImage = imgRes.data.data.sPath
                const putRes = await axios.put(surl, data.image.file, { headers: { "Content-Type": data.image.file.type } });

                const url = `${process.env.REACT_APP_API_AWS_URL}/ambulance/update/${id}/v1`;
                const response = await axios.put(url, { ...data, image: sImage }, {
                    headers: {
                        Authorization: localStorage.getItem("token")
                    }
                });
                dispatch({ type: AMBULANCE_UPDATE_SUCCESS, payload: response.data });
            } else {
                const url = `${process.env.REACT_APP_API_AWS_URL}/ambulance/update/${id}/v1`;
                const response = await axios.put(url, data, {
                    headers: {
                        Authorization: localStorage.getItem("token")
                    }
                });
                dispatch({ type: AMBULANCE_UPDATE_SUCCESS, payload: response.data });
            }
            setModal(false);
            setData({
                image: null,
                name: "",
                location: "",
                phone: ""
            });
            setSelectedId("");
            toast("Ambulace details updated successfully.");
        } catch (error) {
            errorHandling(dispatch, AMBULANCE_UPDATE_FAILURE, error);
        }
    }
};

export const ambulanceDelete = (id, setDeleteId, setDeleteModal) => {
    return async (dispatch) => {
        try {
            dispatch({ type: AMBULANCE_DELETE_REQUEST });
            const url = `${process.env.REACT_APP_API_AWS_URL}/ambulance/delete/${id}/v1`;
            const response = await axios.delete(url, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: AMBULANCE_DELETE_SUCCESS, payload: id });
            setDeleteId("");
            setDeleteModal(false);
            toast("Ambulance deleted successfully");
        } catch (error) {
            errorHandling(dispatch, AMBULANCE_DELETE_FAILURE, error);
        }
    }
};