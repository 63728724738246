import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import * as vetActions from "../../../../redux/vet/vet.actions";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import dog from "../../../../assets/svg/pet_service/dog.svg";
import bird from "../../../../assets/svg/pet_service/bird.svg";
import chameleon from "../../../../assets/svg/pet_service/chameleon.svg";
import cat from "../../../../assets/svg/pet_service/cat.svg";
import turtle from "../../../../assets/svg/pet_service/turtle.svg";
import mammals from "../../../../assets/svg/pet_service/mammals.svg";
import cow from "../../../../assets/svg/pet_service/cow.svg";
import star1 from "../../../../assets/svg/star1.svg";
import { Col, Row } from "react-bootstrap";
import ViewMoreBtn from "../../components/ViewMoreBtn";
import * as petActions from "../../../../redux/pet/pet.actions";
import { imageBaseUrl } from "../../../../helper";

const PetDetail = () => {
  const [data, setData] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const id = useParams().id;

  const loading = useSelector((state) => state.petInfo.loading);
  const petDetails = useSelector((state) => state.petInfo.petDetails);

  useEffect(() => {
    if (id) {
      dispatch(petActions.petDetail(id));
    }
  }, [id]);

  useEffect(() => {
    if (petDetails && Object.keys(petDetails).length > 0) {
      setData(petDetails);
    } else {
      setData({});
    }
  }, [petDetails]);

  return (
    <>
      {loading ? (
        <div className="w-100 d-flex">
          <div
            className="spinner-border text-primary mx-auto"
            role="status"
          ></div>
        </div>
      ) : (
        <>
          {petDetails && Object.keys(petDetails).length > 0 ? (
            <>
              <div>
                <div className="page-header">
                  <h3 className="page-title">Pet Detail</h3>
                </div>
                <div className="row">
                  <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                        <div className="box_container doctor_info_card">
                          <Row className="mb-3">
                            <Col
                              lg={5}
                              md={12}
                              sm={12}
                              xs={12}
                              className="doctor_info_card_photo"
                            >
                              <img
                                src={
                                  data?.petImage &&
                                  `${imageBaseUrl}${data.petImage}`
                                }
                                alt="doctor_image"
                              />
                            </Col>
                            <Col lg={7} md={12} sm={12} xs={12}>
                              <div className="doctor_info_card_name">
                                <h6>{data?.petName}</h6>
                              </div>
                              {/* <div className="doctor_info_card_profession">
                                    <div>
                                      <span>Gender</span>
                                      <p>Male</p>
                                    </div>
                                    <div>
                                      <span>Colour</span>
                                      <p>Red</p>
                                    </div>
                                    <div>
                                      <span>Weight</span>
                                      <p>28 kg</p>
                                    </div>
                                  </div> */}
                              <Row className="">
                                <Col md={12}>
                                  <div
                                    class="mb-2 input_dark"
                                    style={{ textAlign: "left" }}
                                  >
                                    <label
                                      for="exampleInputEmail1"
                                      className="form-label"
                                    >
                                      Breed
                                    </label>
                                    <input
                                      type="text"
                                      class="form-control"
                                      id="exampleInputEmail1"
                                      aria-describedby="emailHelp"
                                      readOnly
                                      value={data?.breed?.name}
                                    />
                                  </div>
                                </Col>
                                {/* <Col lg={3} md={3} sm={3} xs={3}>
                                      <div>
                                        <div>
                                          <img src={dog} alt="dog_icon" />
                                        </div>
                                        <p>Dog</p>
                                      </div>
                                    </Col>
                                    <Col lg={3} md={3} sm={3} xs={3}>
                                      <div>
                                        <div>
                                          <img src={dog} alt="dog_icon" />
                                        </div>
                                        <p>Dog</p>
                                      </div>
                                    </Col>
                                    <Col lg={3} md={3} sm={3} xs={3}>
                                      <div>
                                        <div>
                                          <img src={dog} alt="dog_icon" />
                                        </div>
                                        <p>Dog</p>
                                      </div>
                                    </Col> */}
                              </Row>
                              <Row>
                                <Col md={12}>
                                  <div class="mb-2 input_dark">
                                    <label
                                      for="exampleInputEmail1"
                                      class="form-label"
                                    >
                                      Pet Date of Birth
                                    </label>
                                    <input
                                      type="text"
                                      class="form-control"
                                      id="exampleInputEmail1"
                                      placeholder="Name"
                                      aria-describedby="emailHelp"
                                      readOnly
                                      value={data?.dateOfBirth}
                                    />
                                  </div>
                                </Col>
                                <Col md={12}>
                                  <div class="mb-2 input_dark">
                                    <label
                                      for="exampleInputEmail1"
                                      class="form-label"
                                    >
                                      Gender
                                    </label>
                                    <input
                                      type="text"
                                      class="form-control"
                                      id="exampleInputEmail1"
                                      placeholder="Name"
                                      aria-describedby="emailHelp"
                                      readOnly
                                      value={data?.gender}
                                    />
                                  </div>
                                </Col>
                                <Col md={12}>
                                  <div class="mb-2 input_dark">
                                    <label
                                      for="exampleInputEmail1"
                                      class="form-label"
                                    >
                                      Category
                                    </label>
                                    <input
                                      type="text"
                                      class="form-control"
                                      id="exampleInputEmail1"
                                      placeholder="Name"
                                      aria-describedby="emailHelp"
                                      readOnly
                                      value={data?.petCategory?.name}
                                    />
                                  </div>
                                </Col>
                                <Col md={12} className="mt-2">
                                  <span>Vet Information</span>
                                </Col>
                                <Col md={12}>
                                  <div class="mb-2 input_dark">
                                    <label
                                      for="exampleInputEmail1"
                                      class="form-label"
                                    >
                                      Vet name
                                    </label>
                                    <input
                                      type="text"
                                      class="form-control"
                                      id="exampleInputEmail1"
                                      placeholder="Name"
                                      aria-describedby="emailHelp"
                                      readOnly
                                      value={data?.petCategory?.name}
                                    />
                                  </div>
                                </Col>
                                <Col md={12}>
                                  <div class="mb-2 input_dark">
                                    <label
                                      for="exampleInputEmail1"
                                      class="form-label"
                                    >
                                      Vet's MSVC
                                    </label>
                                    <input
                                      type="text"
                                      class="form-control"
                                      id="exampleInputEmail1"
                                      placeholder="Name"
                                      aria-describedby="emailHelp"
                                      readOnly
                                      value={data?.petCategory?.name}
                                    />
                                  </div>
                                </Col>
                                <Col md={12}>
                                  <div class="mb-2 input_dark">
                                    <label
                                      for="exampleInputEmail1"
                                      class="form-label"
                                    >
                                      Name of Clinic
                                    </label>
                                    <input
                                      type="text"
                                      class="form-control"
                                      id="exampleInputEmail1"
                                      placeholder="Name"
                                      aria-describedby="emailHelp"
                                      readOnly
                                      value={data?.petCategory?.name}
                                    />
                                  </div>
                                </Col>
                                <Col md={12}>
                                  <div class="mb-2 input_dark">
                                    <label
                                      for="exampleInputEmail1"
                                      class="form-label"
                                    >
                                      Date of last Rabies Vaccination
                                    </label>
                                    <input
                                      type="text"
                                      class="form-control"
                                      id="exampleInputEmail1"
                                      placeholder="Name"
                                      aria-describedby="emailHelp"
                                      readOnly
                                      value={data?.petCategory?.name}
                                    />
                                  </div>
                                </Col>
                                <Col md={12}>
                                  <div class="mb-2 input_dark">
                                    <label
                                      for="exampleInputEmail1"
                                      class="form-label"
                                    >
                                      Rabies Vaccination Certificate
                                    </label>
                                    <input
                                      type="text"
                                      class="form-control"
                                      id="exampleInputEmail1"
                                      placeholder="Name"
                                      aria-describedby="emailHelp"
                                      readOnly
                                      value={data?.petCategory?.name}
                                    />
                                  </div>
                                </Col>
                                {data.isEHealthCard && (
                                  <Col md={12}>
                                    <button
                                      className="btn_primary_outline_sm_selected"
                                      onClick={() =>
                                        navigate(`/health-card/${data._id}`)
                                      }
                                    >
                                      Go To E-HealthCard Details
                                    </button>
                                  </Col>
                                )}
                              </Row>
                            </Col>
                          </Row>
                        </div>
                        <div className="box_container about_veterian_box">
                          {/* <h6>About Veterinarian</h6> */}
                          {/* <Row>
                                <Col md={12}>
                                  <Row>
                                    <Col md={12}>
                                      <span>Vet Information</span>
                                    </Col>
                                    <Col md={4}>
                                      <div class="mb-3 input_dark">
                                        <label for="exampleInputEmail1" class="form-label">
                                          Vet Name
                                        </label>
                                        <input
                                          type="text"
                                          class="form-control"
                                          id="exampleInputEmail1"
                                          placeholder="Name"
                                          aria-describedby="emailHelp"
                                          readOnly
                                        />
                                      </div>
                                    </Col>
                                    <Col md={4}>
                                      <div class="mb-3 input_dark">
                                        <label for="exampleInputEmail1" class="form-label">
                                          Vet MSVC
                                        </label>
                                        <input
                                          type="text"
                                          class="form-control"
                                          id="exampleInputEmail1"
                                          aria-describedby="emailHelp"
                                          placeholder="123245244"
                                          readOnly
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col md={12} className="mt-3">
                                  <Row>
                                    <Col md={12}>
                                      <span>Clinic Details</span>
                                    </Col>
                                    <Col md={3}>
                                      <div class="mb-3 input_dark">
                                        <label for="exampleInputEmail1" class="form-label">
                                          Clinic Name
                                        </label>
                                        <input
                                          type="text"
                                          class="form-control"
                                          id="exampleInputEmail1"
                                          aria-describedby="emailHelp"
                                          placeholder="2013"
                                          readOnly
                                        />
                                      </div>
                                    </Col>
                                    <Col md={3}>
                                      <div class="mb-3 input_dark">
                                        <label for="exampleInputEmail1" class="form-label">
                                          Recent Vaccination Date
                                        </label>
                                        <input
                                          type="text"
                                          class="form-control"
                                          id="exampleInputEmail1"
                                          placeholder="Present"
                                          aria-describedby="emailHelp"
                                          readOnly
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <h2 className="text-center mt-3 text-muted">Not Fount</h2>
            </>
          )}
        </>
      )}
    </>
  );
};

export default PetDetail;
