import axios from "axios";
import { toast } from "react-toastify";
import { errorHandling } from "../../helper";

export const LOGIN_ADMIN_REQUEST = 'LOGIN_ADMIN_REQUEST';
export const LOGIN_ADMIN_SUCCESS = 'LOGIN_ADMIN_SUCCESS';
export const LOGIN_ADMIN_FAILURE = 'LOGIN_ADMIN_FAILURE';

export const GET_ADMIN_PROFILE_REQUEST = 'GET_ADMIN_PROFILE_REQUEST';
export const GET_ADMIN_PROFILE_SUCCESS = 'GET_ADMIN_PROFILE_SUCCESS';
export const GET_ADMIN_PROFILE_FAILURE = 'GET_ADMIN_PROFILE_FAILURE';

export const UPDATE_ADMIN_PROFILE_REQUEST = 'UPDATE_ADMIN_PROFILE_REQUEST';
export const UPDATE_ADMIN_PROFILE_SUCCESS = 'UPDATE_ADMIN_PROFILE_SUCCESS';
export const UPDATE_ADMIN_PROFILE_FAILURE = 'UPDATE_ADMIN_PROFILE_FAILURE';

export const CHANGE_ADMIN_PASSWORD_REQUEST = 'CHANGE_ADMIN_PASSWORD_REQUEST';
export const CHANGE_ADMIN_PASSWORD_SUCCESS = 'CHANGE_ADMIN_PASSWORD_SUCCESS';
export const CHANGE_ADMIN_PASSWORD_FAILURE = 'CHANGE_ADMIN_PASSWORD_FAILURE';

export const DASHBOARD_DATA_REQUEST = 'DASHBOARD_DATA_REQUEST';
export const DASHBOARD_DATA_SUCCESS = 'DASHBOARD_DATA_SUCCESS';
export const DASHBOARD_DATA_FAILURE = 'DASHBOARD_DATA_FAILURE';

export const loginAdmin = (data, navigate) => {
    return async (dispatch) => {
        try {
            dispatch({ type: LOGIN_ADMIN_REQUEST });
            const url = `${process.env.REACT_APP_API_AWS_URL}/auth/admin/login/v1`;
            const response = await axios.post(url, data);
            dispatch({ type: LOGIN_ADMIN_SUCCESS, payload: response.data });
            navigate('/dashboard');
            toast('Login Successfully');
        } catch (error) {
            errorHandling(dispatch, LOGIN_ADMIN_FAILURE, error);
        }
    }
};

export const getAdminProfile = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_ADMIN_PROFILE_REQUEST });
            const url = `${process.env.REACT_APP_API_AWS_URL}/admin/get/profile/v1`;
            const response = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: GET_ADMIN_PROFILE_SUCCESS, payload: response.data });
        } catch (error) {
            errorHandling(dispatch, GET_ADMIN_PROFILE_FAILURE, error);
        }
    }
};

export const updateAdminProfile = (data) => {
    return async (dispatch) => {
        try {
            dispatch({ type: UPDATE_ADMIN_PROFILE_REQUEST });
            const url = `${process.env.REACT_APP_API_AWS_URL}/admin/update/profile/v1`;
            const response = await axios.put(url, data, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: UPDATE_ADMIN_PROFILE_SUCCESS, payload: response.data });
        } catch (error) {
            errorHandling(dispatch, UPDATE_ADMIN_PROFILE_FAILURE, error);
        }
    }
};

export const changeAdminPassword = (data, clearData) => {
    return async (dispatch) => {
        try {
            dispatch({ type: CHANGE_ADMIN_PASSWORD_REQUEST });
            const url = `${process.env.REACT_APP_API_AWS_URL}/admin/change/password/v1`;
            const response = await axios.post(url, data, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: CHANGE_ADMIN_PASSWORD_SUCCESS, payload: response.data });
            toast("Your password has been changed");
            clearData();
        } catch (error) {
            errorHandling(dispatch, CHANGE_ADMIN_PASSWORD_FAILURE, error);
        }
    }
};

export const getDashboardData = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: DASHBOARD_DATA_REQUEST });
            const url = `${process.env.REACT_APP_API_AWS_URL}/admin/dashboard/data/v1`;
            const response = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: DASHBOARD_DATA_SUCCESS, payload: response.data });
        } catch (error) {
            errorHandling(dispatch, DASHBOARD_DATA_FAILURE, error);
        }
    }
};