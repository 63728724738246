import * as vetDropdownAction from "./vet.action";

export const vetDropdownFeatureKey = "vetDropdownInfo";

const initialState = {
  loading: false,
  errorMsg: "",
  list: [],
  degrees: [],
  subdegrees: [],
  details: {},
  isStatusUpdate: false,
};

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    // vet create

    case vetDropdownAction.VET_DROPDOWN_CLINICAL_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
        isStatusUpdate: false,
      };

    case vetDropdownAction.VET_DROPDOWN_CLINICAL_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        isStatusUpdate: true,
      };

    case vetDropdownAction.VET_DROPDOWN_CLINICAL_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        list: [],
        isStatusUpdate: false,
      };

    //vet update

    case vetDropdownAction.VET_DROPDOWN_CLINICAL_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
        isStatusUpdate: false,
      };

    case vetDropdownAction.VET_DROPDOWN_CLINICAL_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        isStatusUpdate: true,
      };

    case vetDropdownAction.VET_DROPDOWN_CLINICAL_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        list: [],
        isStatusUpdate: false,
      };

    // vet list

    case vetDropdownAction.VET_DROPDOWN_CLINICAL_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case vetDropdownAction.VET_DROPDOWN_CLINICAL_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: payload.data,
      };

    case vetDropdownAction.VET_DROPDOWN_CLINICAL_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        list: [],
      };

    // vet delete

    case vetDropdownAction.VET_DROPDOWN_CLINICAL_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
        isStatusUpdate: false,
      };

    case vetDropdownAction.VET_DROPDOWN_CLINICAL_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        list: payload.data,
        isStatusUpdate: true,
      };

    case vetDropdownAction.VET_DROPDOWN_CLINICAL_DELETE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        list: [],
        isStatusUpdate: false,
      };

    // pet Health concern

    // create

    case vetDropdownAction.PET_HEALTH_CONCERN_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
        isStatusUpdate: false,
      };

    case vetDropdownAction.PET_HEALTH_CONCERN_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        isStatusUpdate: true,
      };

    case vetDropdownAction.PET_HEALTH_CONCERN_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        list: [],
        isStatusUpdate: false,
      };

    //update

    case vetDropdownAction.PET_HEALTH_CONCERN_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
        isStatusUpdate: false,
      };

    case vetDropdownAction.PET_HEALTH_CONCERN_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        isStatusUpdate: true,
      };

    case vetDropdownAction.PET_HEALTH_CONCERN_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        list: [],
        isStatusUpdate: false,
      };

    // list

    case vetDropdownAction.PET_HEALTH_CONCERN_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case vetDropdownAction.PET_HEALTH_CONCERN_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: payload.data,
      };

    case vetDropdownAction.PET_HEALTH_CONCERN_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        list: [],
      };

    // delete

    case vetDropdownAction.PET_HEALTH_CONCERN_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
        isStatusUpdate: false,
      };

    case vetDropdownAction.PET_HEALTH_CONCERN_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        list: payload.data,
        isStatusUpdate: true,
      };

    case vetDropdownAction.PET_HEALTH_CONCERN_DELETE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        list: [],
        isStatusUpdate: false,
      };

    // speciality certificates

    // create

    case vetDropdownAction.SPECIALITY_DROPDOWN_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
        isStatusUpdate: false,
      };

    case vetDropdownAction.SPECIALITY_DROPDOWN_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        isStatusUpdate: true,
      };

    case vetDropdownAction.SPECIALITY_DROPDOWN_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        list: [],
        isStatusUpdate: false,
      };

    //update

    case vetDropdownAction.SPECIALITY_DROPDOWN_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
        isStatusUpdate: false,
      };

    case vetDropdownAction.SPECIALITY_DROPDOWN_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        isStatusUpdate: true,
      };

    case vetDropdownAction.SPECIALITY_DROPDOWN_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        list: [],
        isStatusUpdate: false,
      };

    // list

    case vetDropdownAction.SPECIALITY_DROPDOWN_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case vetDropdownAction.SPECIALITY_DROPDOWN_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: payload.data,
      };

    case vetDropdownAction.SPECIALITY_DROPDOWN_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        list: [],
      };

    // delete

    case vetDropdownAction.SPECIALITY_DROPDOWN_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
        isStatusUpdate: false,
      };

    case vetDropdownAction.SPECIALITY_DROPDOWN_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        list: payload.data,
        isStatusUpdate: true,
      };

    case vetDropdownAction.SPECIALITY_DROPDOWN_DELETE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        list: [],
        isStatusUpdate: false,
      };

    // Treatment at home

    // create

    case vetDropdownAction.TREATMENT_OFFER_DROPDOWN_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
        isStatusUpdate: false,
      };

    case vetDropdownAction.TREATMENT_OFFER_DROPDOWN_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        isStatusUpdate: true,
      };

    case vetDropdownAction.TREATMENT_OFFER_DROPDOWN_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        list: [],
        isStatusUpdate: false,
      };

    //update

    case vetDropdownAction.TREATMENT_OFFER_DROPDOWN_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
        isStatusUpdate: false,
      };

    case vetDropdownAction.TREATMENT_OFFER_DROPDOWN_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        isStatusUpdate: true,
      };

    case vetDropdownAction.TREATMENT_OFFER_DROPDOWN_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        list: [],
        isStatusUpdate: false,
      };

    // list

    case vetDropdownAction.TREATMENT_OFFER_DROPDOWN_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case vetDropdownAction.TREATMENT_OFFER_DROPDOWN_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: payload.data,
      };

    case vetDropdownAction.TREATMENT_OFFER_DROPDOWN_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        list: [],
      };

    // delete

    case vetDropdownAction.TREATMENT_OFFER_DROPDOWN_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
        isStatusUpdate: false,
      };

    case vetDropdownAction.TREATMENT_OFFER_DROPDOWN_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        list: payload.data,
        isStatusUpdate: true,
      };

    case vetDropdownAction.TREATMENT_OFFER_DROPDOWN_DELETE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        list: [],
        isStatusUpdate: false,
      };

    // Bloodtest

    // create

    case vetDropdownAction.BLOODTEST_DROPDOWN_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
        isStatusUpdate: false,
      };

    case vetDropdownAction.BLOODTEST_DROPDOWN_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        isStatusUpdate: true,
      };

    case vetDropdownAction.BLOODTEST_DROPDOWN_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        list: [],
        isStatusUpdate: false,
      };

    //update

    case vetDropdownAction.BLOODTEST_DROPDOWN_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
        isStatusUpdate: false,
      };

    case vetDropdownAction.BLOODTEST_DROPDOWN_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        isStatusUpdate: true,
      };

    case vetDropdownAction.BLOODTEST_DROPDOWN_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        list: [],
        isStatusUpdate: false,
      };

    // list

    case vetDropdownAction.BLOODTEST_DROPDOWN_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case vetDropdownAction.BLOODTEST_DROPDOWN_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: payload.data,
      };

    case vetDropdownAction.BLOODTEST_DROPDOWN_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        list: [],
      };

    // delete

    case vetDropdownAction.BLOODTEST_DROPDOWN_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
        isStatusUpdate: false,
      };

    case vetDropdownAction.BLOODTEST_DROPDOWN_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        list: payload.data,
        isStatusUpdate: true,
      };

    case vetDropdownAction.BLOODTEST_DROPDOWN_DELETE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        list: [],
        isStatusUpdate: false,
      };

    // Degree Type

    // create

    case vetDropdownAction.DEGREE_DROPDOWN_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
        isStatusUpdate: false,
      };

    case vetDropdownAction.DEGREE_DROPDOWN_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        isStatusUpdate: true,
      };

    case vetDropdownAction.DEGREE_DROPDOWN_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        degrees: [],
        isStatusUpdate: false,
      };

    //update

    case vetDropdownAction.DEGREE_DROPDOWN_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
        isStatusUpdate: false,
      };

    case vetDropdownAction.DEGREE_DROPDOWN_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        isStatusUpdate: true,
      };

    case vetDropdownAction.DEGREE_DROPDOWN_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        degrees: [],
        isStatusUpdate: false,
      };

    // list

    case vetDropdownAction.DEGREE_DROPDOWN_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case vetDropdownAction.DEGREE_DROPDOWN_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        degrees: payload.data,
      };

    case vetDropdownAction.DEGREE_DROPDOWN_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        degrees: [],
      };

    // delete

    case vetDropdownAction.DEGREE_DROPDOWN_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
        isStatusUpdate: false,
      };

    case vetDropdownAction.DEGREE_DROPDOWN_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        list: payload.data,
        isStatusUpdate: true,
      };

    case vetDropdownAction.DEGREE_DROPDOWN_DELETE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        degrees: [],
        isStatusUpdate: false,
      };

    // SubDegree Type

    // create

    case vetDropdownAction.SUB_DEGREE_DROPDOWN_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
        isStatusUpdate: false,
      };

    case vetDropdownAction.SUB_DEGREE_DROPDOWN_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        isStatusUpdate: true,
      };

    case vetDropdownAction.SUB_DEGREE_DROPDOWN_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        subdegrees: [],
        isStatusUpdate: false,
      };

    //update

    case vetDropdownAction.SUB_DEGREE_DROPDOWN_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
        isStatusUpdate: false,
      };

    case vetDropdownAction.SUB_DEGREE_DROPDOWN_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        isStatusUpdate: true,
      };

    case vetDropdownAction.SUB_DEGREE_DROPDOWN_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        subdegrees: [],
        isStatusUpdate: false,
      };

    // list

    case vetDropdownAction.SUB_DEGREE_DROPDOWN_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case vetDropdownAction.SUB_DEGREE_DROPDOWN_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        subdegrees: payload.data,
      };

    case vetDropdownAction.SUB_DEGREE_DROPDOWN_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        subdegrees: [],
      };

    // delete

    case vetDropdownAction.SUB_DEGREE_DROPDOWN_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
        isStatusUpdate: false,
      };

    case vetDropdownAction.SUB_DEGREE_DROPDOWN_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        list: payload.data,
        isStatusUpdate: true,
      };

    case vetDropdownAction.SUB_DEGREE_DROPDOWN_DELETE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        subdegrees: [],
        isStatusUpdate: false,
      };

    default:
      return state;
  }
};
