import axios from "axios";
import { errorHandling } from "../../helper";

export const VIDEO_LIST_REQUEST = 'VIDEO_LIST_REQUEST';
export const VIDEO_LIST_SUCCESS = 'VIDEO_LIST_SUCCESS';
export const VIDEO_LIST_FAILURE = 'VIDEO_LIST_FAILURE';

export const VIDEO_DETAIL_REQUEST = 'VIDEO_DETAIL_REQUEST';
export const VIDEO_DETAIL_SUCCESS = 'VIDEO_DETAIL_SUCCESS';
export const VIDEO_DETAIL_FAILURE = 'VIDEO_DETAIL_FAILURE';

export const UPDATE_VIDEO_DETAIL_REQUEST = 'UPDATE_VIDEO_DETAIL_REQUEST';
export const UPDATE_VIDEO_DETAIL_SUCCESS = 'UPDATE_VIDEO_DETAIL_SUCCESS';
export const UPDATE_VIDEO_DETAIL_FAILURE = 'UPDATE_VIDEO_DETAIL_FAILURE';

export const videoList = (setIsLoading) => {
    return async (dispatch) => {
        try {
            setIsLoading(true);
            dispatch({ type: VIDEO_LIST_REQUEST });
            const url = `${process.env.REACT_APP_API_AWS_URL}/admin/get/all/vet/vidio/v1`;
            const response = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: VIDEO_LIST_SUCCESS, payload: response.data });
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            errorHandling(dispatch, VIDEO_LIST_FAILURE, error);
        }
    }
};

export const videoDetail = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: VIDEO_DETAIL_REQUEST });
            const url = `${process.env.REACT_APP_API_AWS_URL}/admin/get/video/details/${id}/v1`;
            const response = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: VIDEO_DETAIL_SUCCESS, payload: response.data });
        } catch (error) {
            errorHandling(dispatch, VIDEO_DETAIL_FAILURE, error);
        }
    }
};

export const updateVideoDetail = (id, data) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "VIDEO_STATUS_CHANGE" });
            dispatch({ type: UPDATE_VIDEO_DETAIL_REQUEST });
            const url = `${process.env.REACT_APP_API_AWS_URL}/admin/change/vet/vidio/status/${id}/v1`;
            const response = await axios.put(url, data, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: UPDATE_VIDEO_DETAIL_SUCCESS, payload: response.data });
        } catch (error) {
            errorHandling(dispatch, UPDATE_VIDEO_DETAIL_FAILURE, error);
        }
    }
};