import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import * as notificationActions from "../../../../redux/marketingNotification/notification.actions";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { uploadImage } from "../../../../helper";

const PushNotificationSend = () => {
  const [formData, setFormData] = useState({
    title: "",
    body: "",
    image: null
  });
  const [selectedImage, setSelectedImage] = useState(null);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const loading = useSelector(state => state.notificationInfo.loading);

  const handleSendNotification = async () => {
    if (!formData.title || !formData.body) {
      toast("Please fill fields");
      return;
    }
    if (formData.image && formData.image?.file) {
      const imagePath = await uploadImage("/fcm/upload/notification/image/v1", formData.image.file, setSelectedImage);
      dispatch(notificationActions.sendNotification({ ...formData, image: imagePath }, navigate));
    } else {
      dispatch(notificationActions.sendNotification(formData, navigate));
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setError('');

    if (file) {
      if (!file.type.startsWith('image/')) {
        setError('Please upload an image file.');
        return;
      }

      if (file.size > 1048576) {
        setError('File size must be 1MB or less.');
        return;
      }

      setFormData({
        ...formData,
        image: { file }
      });
    }
  };

  return (
    <div>
      <div className="page-header">
        <h3 className="page-title">Send Marketing Notification</h3>
      </div>
      <div className="row">
        <div className="col-lg-12 mx-auto grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <Row>
                <Col className="mx-auto" md={10}>
                  <Row>
                    <Col md={12}>
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Image
                        </label>
                        <input
                          required={true}
                          className="form-control"
                          type="file"
                          onChange={(e) => handleFileChange(e)}
                        />
                        {error && <p style={{ color: 'red' }}>{error}</p>}
                      </div>
                    </Col>
                    <Col md={12}>
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Title
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="exampleInputEmail1"
                          placeholder="Title"
                          aria-describedby="emailHelp"
                          value={formData.title}
                          onChange={e => setFormData({ ...formData, title: e.target.value })}
                        />
                      </div>
                    </Col>
                    <Col md={12}>
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Body
                        </label>
                        <textarea
                          style={{ minHeight: "200px" }}
                          className="form-control"
                          rows={5}
                          placeholder="Body"
                          value={formData.body}
                          onChange={e => setFormData({ ...formData, body: e.target.value })}
                        ></textarea>
                      </div>
                    </Col>
                    <Col md={3} className="">
                      <button className="btn_primary_outline_sm py-2 mt-4 w-100" disabled={loading} onClick={e => handleSendNotification()}>
                        {
                          loading ?
                            <>
                              <div
                                className="spinner-border text-primary mx-auto"
                                role="status"
                              ></div>
                            </>
                            :
                            "Send"
                        }
                      </button>
                    </Col>
                    {/* <Col md={12} className="d-flex">
                    <div class="mb-3 d-flex flex-column mx-auto">
                      <div className="mx-auto">
                        <img className="mt-3 mb-2" src={mp4file} />
                      </div>
                      <p>Click icon to download</p>
                    </div>
                  </Col> */}
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PushNotificationSend;
