import * as staticActions from "./static.actions";

export const staticFeatureKey = "staticInfo";

const initialState = {
  loading: false,
  errorMsg: "",
  aboutUs: "",
  contactUs: "",
  userTerms: "",
  userSupport: "",
  userPrivacy: "",
  userCancellationPolicy: "",
  vetVendorTerms: "",
  vetVendorSupport: "",
  vetVendorPrivacy: "",
};

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case staticActions.GET_ABOUT_US_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case staticActions.GET_ABOUT_US_SUCCESS:
      return {
        ...state,
        loading: false,
        aboutUs: payload.data,
      };

    case staticActions.GET_ABOUT_US_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        aboutUs: "",
      };

    case staticActions.GET_CONTACT_US_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case staticActions.GET_CONTACT_US_SUCCESS:
      return {
        ...state,
        loading: false,
        contactUs: payload.data,
      };

    case staticActions.GET_CONTACT_US_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        contactUs: "",
      };

    //   userTerms

    case staticActions.GET_USER_TERMS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case staticActions.GET_USER_TERMS_SUCCESS:
      return {
        ...state,
        loading: false,
        userTerms: payload.data,
      };

    case staticActions.GET_USER_TERMS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        userTerms: "",
      };

    // user support

    case staticActions.GET_USER_SUPPORT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case staticActions.GET_USER_SUPPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        userSupport: payload.data,
      };

    case staticActions.GET_USER_SUPPORT_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        userSupport: "",
      };

    // user privacy

    case staticActions.GET_USER_PRIVACY_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case staticActions.GET_USER_PRIVACY_SUCCESS:
      return {
        ...state,
        loading: false,
        userPrivacy: payload.data,
      };

    case staticActions.GET_USER_PRIVACY_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        userPrivacy: "",
      };


    case staticActions.GET_USER_CANCELLATION_POLICY_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case staticActions.GET_USER_CANCELLATION_POLICY_SUCCESS:
      return {
        ...state,
        loading: false,
        userCancellationPolicy: payload.data,
      };

    case staticActions.GET_USER_CANCELLATION_POLICY_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        userCancellationPolicy: "",
      };

    // vet terms

    case staticActions.GET_VET_VENDOR_TERMS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case staticActions.GET_VET_VENDOR_TERMS_SUCCESS:
      return {
        ...state,
        loading: false,
        vetVendorTerms: payload.data,
      };

    case staticActions.GET_VET_VENDOR_TERMS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        vetVendorTerms: "",
      };

    // vet-vendor support

    case staticActions.GET_VET_VENDOR_SUPPORT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case staticActions.GET_VET_VENDOR_SUPPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        vetVendorSupport: payload.data,
      };

    case staticActions.GET_VET_VENDOR_SUPPORT_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        vetVendorSupport: "",
      };

    // vet-vendor privacy

    case staticActions.GET_VET_VENDOR_PRIVACY_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case staticActions.GET_VET_VENDOR_PRIVACY_SUCCESS:
      return {
        ...state,
        loading: false,
        vetVendorPrivacy: payload.data,
      };

    case staticActions.GET_VET_VENDOR_PRIVACY_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        vetVendorPrivacy: "",
      };

    default:
      return state;
  }
};
