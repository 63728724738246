import * as abuseActions from "./abuse.actions";

export const abuseFeatureKey = "abuseInfo";

const initialState = {
    loading: false,
    xlLoading: false,
    errorMsg: "",
    types: [],
    list: [],
    details: {}
};

export const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case abuseActions.ABUSE_TYPES_REQUEST:
            return {
                ...state,
                loading: true
            }

        case abuseActions.ABUSE_TYPES_SUCCESS:
            return {
                ...state,
                loading: false,
                types: payload.data
            }

        case abuseActions.ABUSE_TYPES_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload,
                types: []
            }

        case abuseActions.ABUSE_LIST_REQUEST:
            return {
                ...state,
                loading: true
            }

        case abuseActions.ABUSE_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                list: payload.data
            }

        case abuseActions.ABUSE_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload,
                list: []
            }

        case abuseActions.ABUSE_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            }

        case abuseActions.ABUSE_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                details: payload.data
            }

        case abuseActions.ABUSE_DETAILS_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload,
                details: {}
            }

        case abuseActions.ABUSE_LIST_FOR_EXCEL_REQUEST:
            return {
                ...state,
                xlLoading: true
            }

        case abuseActions.ABUSE_LIST_FOR_EXCEL_SUCCESS:
            return {
                ...state,
                xlLoading: false
            }

        case abuseActions.ABUSE_LIST_FOR_EXCEL_FAILURE:
            return {
                ...state,
                xlLoading: false,
                errorMsg: payload
            }

        default: return state
    }
};