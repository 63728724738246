import React, { useState } from "react";
import { Col, Input, FormGroup, Modal, ModalBody, Row } from "react-bootstrap";
import tick from "../../../../assets/svg/tick.svg";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { createSubAdmin } from "../../../../redux/subAdmin/subAdmin.actions";

const SubAdminCreate = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobileNumber: "",
    password: "",
    confirmPassword: "",
  });
  const [passwordMatchError, setPasswordMatchError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [touchedFields, setTouchedFields] = useState({
    name: false,
    email: false,
    mobileNumber: false,
    password: false,
    confirmPassword: false,
  });
  const [nameError, setNameError] = useState({
    name: false,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const accessKey = [
    "Users",
    "Vendors",
    "Vets",
    "Products",
    "Pets",
    "Banners",
    "Blogs",
    "Videos",
    "Orders",
    "Payments",
    "EHealthCard",
    "Homecare",
    "Consultations",
    "AboutUs",
    "ContactUs",
    "Policy",
    "Ambulance",
    "GovernmentPets",
    "ReportAbuse",
    "AppSettings",
    "MarketingNotifications",
    "PushNotifications",
    "Mail",
    "CouponCode",
    "Dropdowns",
  ];

  const handleCreateSubAdmin = () => {
    if (
      formData.name &&
      formData.email &&
      formData.mobileNumber &&
      formData.password &&
      formData.confirmPassword
    ) {
      setIsLoading(true);

      dispatch(createSubAdmin(formData, navigate));
    } else {
      // If any field is missing, show an alert or error message
      toast.error("Please fill in all fields before submitting.");
    }
  };

  const handleConfirmPasswordChange = (e) => {
    const confirmPassword = e.target.value;
    if (confirmPassword !== formData.password) {
      setPasswordMatchError(true);
    } else {
      setPasswordMatchError(false);
    }
    setFormData({ ...formData, confirmPassword });
  };

  const formatAccessKey = (key) => {
    return (
      key
        .toLowerCase() // Convert to lowercase
        .replace(/\s+/g, "") + // Remove all whitespace
      "Access"
    );
  };

  const handlePermissionChange = (access, permission, value) => {
    const formattedKey = formatAccessKey(access);

    setFormData({
      ...formData,
      [formattedKey]: {
        ...formData[formattedKey],
        [permission]: value,
      },
    });
  };

  const handleSelectAllChange = (access, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [`${access.toLowerCase()}Access`]: {
        canRead: value,
        canCreate: value,
        canEdit: value,
        canDelete: value,
      },
    }));
  };

  const isPasswordValid = (password) => {
    // Password should contain mixture of Capital letter, one special character, one digit, and be at least 8 characters long
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordRegex.test(password);
  };
  const isNameValid = (name) => {
    const nameRegex = /^[a-zA-Z\s]*$/;
    return nameRegex.test(name);
  };

  const handleMobileNumberChange = (e) => {
    const value = e.target.value;

    // Allow only digits and limit to 10 characters
    if (/^\d{0,10}$/.test(value)) {
      setFormData({ ...formData, mobileNumber: value });
      setTouchedFields({ ...touchedFields, mobileNumber: true });
    }
  };

  const handleNameChange = (e, field) => {
    const value = e.target.value;
    if (isNameValid(value)) {
      setFormData({ ...formData, [field]: value });
      setNameError({ ...nameError, [field]: false });
    } else {
      setNameError({ ...nameError, [field]: true });
    }
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;

    // Basic email format validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setFormData({ ...formData, email: value });

    if (emailRegex.test(value)) {
      // Reset error if email format is valid
      setNameError({ ...nameError, email: false });
    } else {
      // Set error if email format is invalid
      setNameError({ ...nameError, email: true });
    }
  };

  return (
    <div>
      <div className="page-header">
        <h3 className="page-title">Create New Sub-Admin</h3>
        <Col md={4} className="d-flex align-items-end">
          <button
            className="btn_primary_outline_sm ms-auto"
            onClick={handleCreateSubAdmin}
          >
            Submit Form
          </button>
        </Col>
      </div>
      <div className="row">
        <div className="col-12 grid-margin">
          <div className="card">
            <div className="card-body">
              <Row>
                <div className="mb-3 modal_input mt-4">
                  <h3 className="form-label w-100 mb-4">Personal Details</h3>
                </div>
                <Col md={12}>
                  <Row>
                    <Col md={4}>
                      <FormGroup className="mb-3">
                        <label>Name</label>
                        <input
                          type="text"
                          placeholder="Enter Firstname"
                          value={formData.name}
                          className="form-control"
                          onChange={(e) => handleNameChange(e, "name")}
                          onBlur={() =>
                            setTouchedFields({
                              ...touchedFields,
                              name: true,
                            })
                          }
                          required={true}
                        />
                        {touchedFields.name && formData.name === "" && (
                          <p style={{ color: "red", marginTop: "5px" }}>
                            *Name is required.
                          </p>
                        )}
                      </FormGroup>
                    </Col>

                    <Col md={4}>
                      <FormGroup className="mb-3">
                        <label>Mobile Number</label>
                        <input
                          type="number"
                          placeholder="Enter Mobile Number"
                          value={formData.mobileNumber}
                          className="form-control"
                          onChange={(e) => handleMobileNumberChange(e)}
                          onBlur={() =>
                            setTouchedFields({
                              ...touchedFields,
                              mobileNumber: true,
                            })
                          }
                          maxLength={10}
                          required={true}
                        />
                        {touchedFields.mobileNumber &&
                          (!/^\d{10}$/.test(formData.mobileNumber) ? (
                            <p style={{ color: "red", marginTop: "5px" }}>
                              *Mobile number must be exactly 10 digits.
                            </p>
                          ) : null)}
                      </FormGroup>
                    </Col>

                    <Col md={4}>
                      <FormGroup className="mb-3">
                        <label>Email</label>
                        <input
                          type="email"
                          placeholder="Enter Email"
                          value={formData.email}
                          className="form-control"
                          onChange={(e) => handleEmailChange(e)}
                          onBlur={() =>
                            setTouchedFields({
                              ...touchedFields,
                              email: true,
                            })
                          }
                          required={true}
                        />
                        {touchedFields.email && formData.email === "" && (
                          <p style={{ color: "red", marginTop: "5px" }}>
                            *Email is required.
                          </p>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <FormGroup className="mb-3">
                        <label>Password</label>
                        <input
                          type="password"
                          placeholder="Enter Password"
                          value={formData.password}
                          className="form-control"
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              password: e.target.value,
                            })
                          }
                          required={true}
                        />
                        {!isPasswordValid(formData.password) && (
                          <p style={{ color: "red", marginTop: "5px" }}>
                            Password should contain a mixture of capital and
                            small letters, one special character, one digit, and
                            be at least 8 characters long.
                          </p>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup className="mb-3">
                        <label>Confirm Password</label>
                        <input
                          type="password"
                          placeholder="Enter Confirm Password"
                          value={formData.confirmPassword}
                          className="form-control"
                          onChange={handleConfirmPasswordChange}
                          required={true}
                        />
                        {passwordMatchError && (
                          <p style={{ color: "red", marginTop: "5px" }}>
                            Passwords do not match.
                          </p>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <div className="mb-4 modal_input mt-4">
                <h3 className="form-label w-100 mb-4">Give Permissions</h3>
              </div>
              <Row>
                <Col md={6}>
                  {accessKey &&
                    accessKey.length > 0 &&
                    accessKey.map((access, i) => {
                      const allSelected =
                        formData[`${access.toLowerCase()}Access`]?.canRead &&
                        formData[`${access.toLowerCase()}Access`]?.canCreate &&
                        formData[`${access.toLowerCase()}Access`]?.canEdit &&
                        formData[`${access.toLowerCase()}Access`]?.canDelete;

                      return (
                        <div
                          className="mb-3 d-flex justify-content-between align-items-center"
                          key={i}
                        >
                          <p className="select_permission_label">
                            {access} Access
                          </p>
                          <div className="d-flex align-items-center gap-3 select_permission_group">
                            <div className="select_permission">
                              <label
                                htmlFor={`selectAll-${access}-${i}`}
                                className="selectCheck"
                              >
                                <input
                                  type="checkbox"
                                  id={`selectAll-${access}-${i}`}
                                  checked={allSelected || false}
                                  onChange={(e) =>
                                    handleSelectAllChange(
                                      access,
                                      e.target.checked
                                    )
                                  }
                                />
                                <span className="tick">
                                  <img src={tick} alt="" />
                                </span>
                                <span>Select All</span>
                              </label>
                            </div>
                            <div className="select_permission">
                              <label
                                htmlFor={`remember1-${access}-${i}`}
                                className="selectCheck"
                              >
                                <input
                                  type="checkbox"
                                  id={`remember1-${access}-${i}`}
                                  checked={
                                    formData[`${access.toLowerCase()}Access`]
                                      ?.canRead || false
                                  }
                                  onChange={(e) =>
                                    handlePermissionChange(
                                      access,
                                      "canRead",
                                      e.target.checked
                                    )
                                  }
                                />
                                <span className="tick">
                                  <img src={tick} alt="" />
                                </span>
                                <span>Read</span>
                              </label>
                            </div>
                            <div className="select_permission">
                              <label
                                htmlFor={`remember2-${access}-${i}`}
                                className="selectCheck"
                              >
                                <input
                                  type="checkbox"
                                  id={`remember2-${access}-${i}`}
                                  checked={
                                    formData[`${access.toLowerCase()}Access`]
                                      ?.canCreate || false
                                  }
                                  onChange={(e) =>
                                    handlePermissionChange(
                                      access,
                                      "canCreate",
                                      e.target.checked
                                    )
                                  }
                                />
                                <span className="tick">
                                  <img src={tick} alt="" />
                                </span>
                                <span>Create</span>
                              </label>
                            </div>
                            <div className="select_permission">
                              <label
                                htmlFor={`remember3-${access}-${i}`}
                                className="selectCheck"
                              >
                                <input
                                  type="checkbox"
                                  id={`remember3-${access}-${i}`}
                                  checked={
                                    formData[`${access.toLowerCase()}Access`]
                                      ?.canEdit || false
                                  }
                                  onChange={(e) =>
                                    handlePermissionChange(
                                      access,
                                      "canEdit",
                                      e.target.checked
                                    )
                                  }
                                />
                                <span className="tick">
                                  <img src={tick} alt="" />
                                </span>
                                <span>Edit</span>
                              </label>
                            </div>
                            <div className="select_permission">
                              <label
                                htmlFor={`remember4-${access}-${i}`}
                                className="selectCheck"
                              >
                                <input
                                  type="checkbox"
                                  id={`remember4-${access}-${i}`}
                                  checked={
                                    formData[`${access.toLowerCase()}Access`]
                                      ?.canDelete || false
                                  }
                                  onChange={(e) =>
                                    handlePermissionChange(
                                      access,
                                      "canDelete",
                                      e.target.checked
                                    )
                                  }
                                />
                                <span className="tick">
                                  <img src={tick} alt="" />
                                </span>
                                <span>Delete</span>
                              </label>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubAdminCreate;
