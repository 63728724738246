import * as authActions from "./auth.actions";

export const authFeatureKey = "authInfo";

const initialState = {
    loading: false,
    errorMsg: "",
    profile: {},
    dashboard: {}
};

export const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case authActions.LOGIN_ADMIN_REQUEST:
            return {
                ...state,
                loading: true
            }

        case authActions.LOGIN_ADMIN_SUCCESS:
            if (payload.token) {
                localStorage.setItem("token", payload.token);
            }
            return {
                ...state,
                loading: false,
                profile: payload.data
            }

        case authActions.LOGIN_ADMIN_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case authActions.GET_ADMIN_PROFILE_REQUEST:
            return {
                ...state,
                loading: true
            }

        case authActions.GET_ADMIN_PROFILE_SUCCESS:
            return {
                ...state,
                loading: false,
                profile: payload.data
            }

        case authActions.GET_ADMIN_PROFILE_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case authActions.UPDATE_ADMIN_PROFILE_REQUEST:
            return {
                ...state,
                loading: true
            }

        case authActions.UPDATE_ADMIN_PROFILE_SUCCESS:
            return {
                ...state,
                loading: false,
                profile: payload.data
            }

        case authActions.UPDATE_ADMIN_PROFILE_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case authActions.CHANGE_ADMIN_PASSWORD_REQUEST:
            return {
                ...state,
                loading: true
            }

        case authActions.CHANGE_ADMIN_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false
            }

        case authActions.CHANGE_ADMIN_PASSWORD_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case authActions.DASHBOARD_DATA_REQUEST:
            return {
                ...state,
                loading: true
            }

        case authActions.DASHBOARD_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                dashboard: payload.data
            }

        case authActions.DASHBOARD_DATA_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload,
                dashboard: {}
            }

        default: return state
    }
};