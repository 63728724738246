import axios from "axios";
import { errorHandling } from "../../helper";
import { toast } from "react-toastify";

//cancel appointment

export const CANCEL_APPOINTMENT_CREATE_REQUEST =
  "CANCEL_APPOINTMENT_CREATE_REQUEST";
export const CANCEL_APPOINTMENT_CREATE_SUCCESS =
  "CANCEL_APPOINTMENT_CREATE_SUCCESS";
export const CANCEL_APPOINTMENT_CREATE_FAILURE =
  "CANCEL_APPOINTMENT_CREATE_FAILURE";

export const CANCEL_APPOINTMENT_UPDATE_REQUEST =
  "CANCEL_APPOINTMENT_UPDATE_REQUEST";
export const CANCEL_APPOINTMENT_UPDATE_SUCCESS =
  "CANCEL_APPOINTMENT_UPDATE_SUCCESS";
export const CANCEL_APPOINTMENT_UPDATE_FAILURE =
  "CANCEL_APPOINTMENT_UPDATE_FAILURE";

export const CANCEL_APPOINTMENT_LIST_REQUEST =
  "CANCEL_APPOINTMENT_LIST_REQUEST";
export const CANCEL_APPOINTMENT_LIST_SUCCESS =
  "CANCEL_APPOINTMENT_LIST_SUCCESS";
export const CANCEL_APPOINTMENT_LIST_FAILURE =
  "CANCEL_APPOINTMENT_LIST_FAILURE";

export const CANCEL_APPOINTMENT_DELETE_REQUEST =
  "CANCEL_APPOINTMENT_DELETE_REQUEST";
export const CANCEL_APPOINTMENT_DELETE_SUCCESS =
  "CANCEL_APPOINTMENT_DELETE_SUCCESS";
export const CANCEL_APPOINTMENT_DELETE_FAILURE =
  "CANCEL_APPOINTMENT_DELETE_FAILURE";

// petCategory

export const BREED_OF_PETS_CATEGORY_CREATE_REQUEST =
  "BREED_OF_PETS_CATEGORY_CREATE_REQUEST";
export const BREED_OF_PETS_CATEGORY_CREATE_SUCCESS =
  "BREED_OF_PETS_CATEGORY_CREATE_SUCCESS";
export const BREED_OF_PETS_CATEGORY_CREATE_FAILURE =
  "BREED_OF_PETS_CATEGORY_CREATE_FAILURE";

export const BREED_OF_PETS_CATEGORY_UPDATE_REQUEST =
  "BREED_OF_PETS_CATEGORY_UPDATE_REQUEST";
export const BREED_OF_PETS_CATEGORY_UPDATE_SUCCESS =
  "BREED_OF_PETS_CATEGORY_UPDATE_SUCCESS";
export const BREED_OF_PETS_CATEGORY_UPDATE_FAILURE =
  "BREED_OF_PETS_CATEGORY_UPDATE_FAILURE";

export const BREED_OF_PETS_CATEGORY_LIST_REQUEST =
  "BREED_OF_PETS_CATEGORY_LIST_REQUEST";
export const BREED_OF_PETS_CATEGORY_LIST_SUCCESS =
  "BREED_OF_PETS_CATEGORY_LIST_SUCCESS";
export const BREED_OF_PETS_CATEGORY_LIST_FAILURE =
  "BREED_OF_PETS_CATEGORY_LIST_FAILURE";

export const BREED_OF_PETS_CATEGORY_DELETE_REQUEST =
  "BREED_OF_PETS_CATEGORY_DELETE_REQUEST";
export const BREED_OF_PETS_CATEGORY_DELETE_SUCCESS =
  "BREED_OF_PETS_CATEGORY_DELETE_SUCCESS";
export const BREED_OF_PETS_CATEGORY_DELETE_FAILURE =
  "BREED_OF_PETS_CATEGORY_DELETE_FAILURE";

// breedOfPets

export const BREED_OF_PETS_CREATE_REQUEST = "BREED_OF_PETS_CREATE_REQUEST";
export const BREED_OF_PETS_CREATE_SUCCESS = "BREED_OF_PETS_CREATE_SUCCESS";
export const BREED_OF_PETS_CREATE_FAILURE = "BREED_OF_PETS_CREATE_FAILURE";

export const BREED_OF_PETS_UPDATE_REQUEST = "BREED_OF_PETS_UPDATE_REQUEST";
export const BREED_OF_PETS_UPDATE_SUCCESS = "BREED_OF_PETS_UPDATE_SUCCESS";
export const BREED_OF_PETS_UPDATE_FAILURE = "BREED_OF_PETS_UPDATE_FAILURE";

export const BREED_OF_PETS_LIST_REQUEST = "BREED_OF_PETS_LIST_REQUEST";
export const BREED_OF_PETS_LIST_SUCCESS = "BREED_OF_PETS_LIST_SUCCESS";
export const BREED_OF_PETS_LIST_FAILURE = "BREED_OF_PETS_LIST_FAILURE";

export const BREED_OF_PETS_DELETE_REQUEST = "BREED_OF_PETS_DELETE_REQUEST";
export const BREED_OF_PETS_DELETE_SUCCESS = "BREED_OF_PETS_DELETE_SUCCESS";
export const BREED_OF_PETS_DELETE_FAILURE = "BREED_OF_PETS_DELETE_FAILURE";

// allergy

export const ALLERGY_CREATE_REQUEST = "ALLERGY_CREATE_REQUEST";
export const ALLERGY_CREATE_SUCCESS = "ALLERGY_CREATE_SUCCESS";
export const ALLERGY_CREATE_FAILURE = "ALLERGY_CREATE_FAILURE";

export const ALLERGY_UPDATE_REQUEST = "ALLERGY_UPDATE_REQUEST";
export const ALLERGY_UPDATE_SUCCESS = "ALLERGY_UPDATE_SUCCESS";
export const ALLERGY_UPDATE_FAILURE = "ALLERGY_UPDATE_FAILURE";

export const ALLERGY_LIST_REQUEST = "ALLERGY_LIST_REQUEST";
export const ALLERGY_LIST_SUCCESS = "ALLERGY_LIST_SUCCESS";
export const ALLERGY_LIST_FAILURE = "ALLERGY_LIST_FAILURE";

export const ALLERGY_DELETE_REQUEST = "ALLERGY_DELETE_REQUEST";
export const ALLERGY_DELETE_SUCCESS = "ALLERGY_DELETE_SUCCESS";
export const ALLERGY_DELETE_FAILURE = "ALLERGY_DELETE_FAILURE";

// cancel appointment create

export const createCancelAppointmentDropdown = (
  dropdown,
  setModalShow,
  setFormData
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: CANCEL_APPOINTMENT_CREATE_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/admin/create/cancel/reason/v1`;
      const response = await axios.post(url, dropdown, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: CANCEL_APPOINTMENT_CREATE_SUCCESS,
        payload: response.data,
      });
      setModalShow(false);
      setFormData({});
    } catch (error) {
      errorHandling(dispatch, CANCEL_APPOINTMENT_CREATE_FAILURE, error);
      setModalShow(false);
      setFormData({});
    }
  };
};

// cancel appointment update

export const updateCancelAppointmentDropdown = (
  id,
  dropdown,
  setUpdateModalShow,
  setFormData
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: CANCEL_APPOINTMENT_UPDATE_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/admin/update/cancel/reason/${id}/v1`;
      const response = await axios.put(url, dropdown, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: CANCEL_APPOINTMENT_UPDATE_SUCCESS,
        payload: response.data,
      });
      setUpdateModalShow(false);
      setFormData({});
    } catch (error) {
      errorHandling(dispatch, CANCEL_APPOINTMENT_UPDATE_FAILURE, error);
      setUpdateModalShow(false);
      setFormData({});
    }
  };
};

// cancel appointment list

export const cancelAppointmentDropdownList = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: CANCEL_APPOINTMENT_LIST_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/admin/get/all/cancel/reason/admin/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: CANCEL_APPOINTMENT_LIST_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      errorHandling(dispatch, CANCEL_APPOINTMENT_LIST_FAILURE, error);
    }
  };
};

// cancel appointment delete

export const deleteCancelAppointmentDropdown = (
  id,
  setDeleteModal,
  setFormData
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: CANCEL_APPOINTMENT_DELETE_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/admin/delete/cancel/reason/${id}/v1`;
      const response = await axios.delete(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: CANCEL_APPOINTMENT_DELETE_SUCCESS,
        payload: response.data,
      });
      setDeleteModal(false);
      setFormData({});
    } catch (error) {
      errorHandling(dispatch, CANCEL_APPOINTMENT_DELETE_FAILURE, error);
      setDeleteModal(false);
      setFormData({});
    }
  };
};

//petCategory

export const createPetCategoryDropdown = (
  dropdown,
  setModalShow,
  setFormData
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: BREED_OF_PETS_CATEGORY_CREATE_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/pet/admin/create/pet/category/v1`;
      const response = await axios.post(url, dropdown, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: BREED_OF_PETS_CATEGORY_CREATE_SUCCESS,
        payload: response.data,
      });
      setModalShow(false);
      setFormData({});
    } catch (error) {
      errorHandling(dispatch, BREED_OF_PETS_CATEGORY_CREATE_FAILURE, error);
      setModalShow(false);
      setFormData({});
    }
  };
};

// update

export const updatePetCategoryDropdown = (
  id,
  dropdown,
  setUpdateModalShow,
  setFormData
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: BREED_OF_PETS_CATEGORY_UPDATE_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/pet/admin/update/pet/category/${id}/v1`;
      const response = await axios.put(url, dropdown, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: BREED_OF_PETS_CATEGORY_UPDATE_SUCCESS,
        payload: response.data,
      });
      setUpdateModalShow(false);
      setFormData({});
    } catch (error) {
      errorHandling(dispatch, BREED_OF_PETS_CATEGORY_UPDATE_FAILURE, error);
      setUpdateModalShow(false);
      setFormData({});
    }
  };
};

// pet category list

export const breedOfPetsCategoryDropdownList = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: BREED_OF_PETS_CATEGORY_LIST_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/pet/admin/get/all/pet/category/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: BREED_OF_PETS_CATEGORY_LIST_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      errorHandling(dispatch, BREED_OF_PETS_CATEGORY_LIST_FAILURE, error);
    }
  };
};

//delete

export const deletePetCategoryDropdown = (id, setDeleteModal, setFormData) => {
  return async (dispatch) => {
    try {
      dispatch({ type: BREED_OF_PETS_CATEGORY_DELETE_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/pet/admin/delete/pet/category/${id}/v1`;
      const response = await axios.delete(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: BREED_OF_PETS_CATEGORY_DELETE_SUCCESS,
        payload: response.data,
      });
      setDeleteModal(false);
      setFormData({});
    } catch (error) {
      errorHandling(dispatch, BREED_OF_PETS_CATEGORY_DELETE_FAILURE, error);
      setDeleteModal(false);
      setFormData({});
    }
  };
};

// breedofpets create

export const createBreedOfPetsDropdown = (
  dropdown,
  setModalShow,
  setFormData
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: BREED_OF_PETS_CREATE_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/pet/create/breed/v1`;
      const response = await axios.post(url, dropdown, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: BREED_OF_PETS_CREATE_SUCCESS,
        payload: response.data,
      });
      setModalShow(false);
      setFormData({});
    } catch (error) {
      errorHandling(dispatch, BREED_OF_PETS_CREATE_FAILURE, error);
      setModalShow(false);
      setFormData({});
    }
  };
};

// breedofpets update

export const updateBreedOfPetsDropdown = (
  id,
  dropdown,
  setUpdateModalShow,
  setFormData
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: BREED_OF_PETS_UPDATE_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/pet/update/breed/${id}/v1`;
      const response = await axios.put(url, dropdown, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: BREED_OF_PETS_UPDATE_SUCCESS,
        payload: response.data,
      });
      setUpdateModalShow(false);
      setFormData({});
    } catch (error) {
      errorHandling(dispatch, BREED_OF_PETS_UPDATE_FAILURE, error);
      setUpdateModalShow(false);
      setFormData({});
    }
  };
};

// breedofpets list

export const breedOfPetsDropdownList = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: BREED_OF_PETS_LIST_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/pet/get/all/breed/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: BREED_OF_PETS_LIST_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      errorHandling(dispatch, BREED_OF_PETS_LIST_FAILURE, error);
    }
  };
};

// breedofpets delete

export const deleteBreedOfPetsDropdown = (id, setDeleteModal, setFormData) => {
  return async (dispatch) => {
    try {
      dispatch({ type: BREED_OF_PETS_DELETE_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/pet/delete/breed/${id}/v1`;
      const response = await axios.delete(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: BREED_OF_PETS_DELETE_SUCCESS,
        payload: response.data,
      });
      setDeleteModal(false);
      setFormData({});
    } catch (error) {
      errorHandling(dispatch, BREED_OF_PETS_DELETE_FAILURE, error);
      setDeleteModal(false);
      setFormData({});
    }
  };
};

// allery create

export const createAllergyDropdown = (dropdown, setModalShow, setFormData) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ALLERGY_CREATE_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/pet/create/allergies/v1`;
      const response = await axios.post(url, dropdown, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: ALLERGY_CREATE_SUCCESS,
        payload: response.data,
      });
      setModalShow(false);
      setFormData({});
    } catch (error) {
      errorHandling(dispatch, ALLERGY_CREATE_FAILURE, error);
      setModalShow(false);
      setFormData({});
    }
  };
};

// allery update

export const updateAllergyDropdown = (
  id,
  dropdown,
  setUpdateModalShow,
  setFormData
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ALLERGY_UPDATE_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/pet/update/allergies/${id}/v1`;
      const response = await axios.put(url, dropdown, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: ALLERGY_UPDATE_SUCCESS,
        payload: response.data,
      });
      setUpdateModalShow(false);
      setFormData({});
    } catch (error) {
      errorHandling(dispatch, ALLERGY_UPDATE_FAILURE, error);
      setUpdateModalShow(false);
      setFormData({});
    }
  };
};

// allery list

export const allergyDropdownList = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: ALLERGY_LIST_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/pet/get/all/allergies/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: ALLERGY_LIST_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      errorHandling(dispatch, ALLERGY_LIST_FAILURE, error);
    }
  };
};

// allery delete

export const deleteAllergyDropdown = (id, setDeleteModal, setFormData) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ALLERGY_DELETE_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/pet/delete/allergies/${id}/v1`;
      const response = await axios.delete(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: ALLERGY_DELETE_SUCCESS,
        payload: response.data,
      });
      setDeleteModal(false);
      setFormData({});
    } catch (error) {
      errorHandling(dispatch, ALLERGY_DELETE_FAILURE, error);
      setDeleteModal(false);
      setFormData({});
    }
  };
};
