import axios from "axios";
import { errorHandling } from "../../helper";
import { toast } from "react-toastify";

export const PET_LIST_REQUEST = 'PET_LIST_REQUEST';
export const PET_LIST_SUCCESS = 'PET_LIST_SUCCESS';
export const PET_LIST_FAILURE = 'PET_LIST_FAILURE';

export const PET_DETAILS_REQUEST = 'PET_DETAILS_REQUEST';
export const PET_DETAILS_SUCCESS = 'PET_DETAILS_SUCCESS';
export const PET_DETAILS_FAILURE = 'PET_DETAILS_FAILURE';

export const petList = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: PET_LIST_REQUEST });
            const url = `${process.env.REACT_APP_API_AWS_URL}/pet/get/all/goverment/pet/admin/v1`;
            const response = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: PET_LIST_SUCCESS, payload: response.data });
        } catch (error) {
            errorHandling(dispatch, PET_LIST_FAILURE, error);
        }
    }
};

export const petDetails = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: PET_DETAILS_REQUEST });
            const url = `${process.env.REACT_APP_API_AWS_URL}/pet/admin/get/single/goverment/register/pet/${id}/v1`;
            const response = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: PET_DETAILS_SUCCESS, payload: response.data });
        } catch (error) {
            errorHandling(dispatch, PET_DETAILS_FAILURE, error);
        }
    }
};