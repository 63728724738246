import axios from "axios";
import { toast } from "react-toastify";
import { errorHandling } from "../../helper";

export const HEALTHCARD_LIST_REQUEST = 'HEALTHCARD_LIST_REQUEST';
export const HEALTHCARD_LIST_SUCCESS = 'HEALTHCARD_LIST_SUCCESS';
export const HEALTHCARD_LIST_FAILURE = 'HEALTHCARD_LIST_FAILURE';

export const HEALTHCARD_DETAILS_REQUEST = 'HEALTHCARD_DETAILS_REQUEST';
export const HEALTHCARD_DETAILS_SUCCESS = 'HEALTHCARD_DETAILS_SUCCESS';
export const HEALTHCARD_DETAILS_FAILURE = 'HEALTHCARD_DETAILS_FAILURE';

export const healthCardList = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: HEALTHCARD_LIST_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/pet/get/all/e/health/card/pet/list/admin/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token")
        }
      });
      dispatch({ type: HEALTHCARD_LIST_SUCCESS, payload: response.data });
    } catch (error) {
      errorHandling(dispatch, HEALTHCARD_LIST_FAILURE, error);
    }
  }
};

export const healthCardDetails = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: HEALTHCARD_DETAILS_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/pet/admin/get/pet/info/${id}/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token")
        }
      });
      dispatch({ type: HEALTHCARD_DETAILS_SUCCESS, payload: response.data });
    } catch (error) {
      errorHandling(dispatch, HEALTHCARD_DETAILS_FAILURE, error);
    }
  }
};