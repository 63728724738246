import axios from "axios";
import { errorHandling } from "../../helper";
import { toast } from "react-toastify";

//Abuse

export const ABUSE_CREATE_REQUEST = "ABUSE_CREATE_REQUEST";
export const ABUSE_CREATE_SUCCESS = "ABUSE_CREATE_SUCCESS";
export const ABUSE_CREATE_FAILURE = "ABUSE_CREATE_FAILURE";

export const ABUSE_UPDATE_REQUEST = "ABUSE_UPDATE_REQUEST";
export const ABUSE_UPDATE_SUCCESS = "ABUSE_UPDATE_SUCCESS";
export const ABUSE_UPDATE_FAILURE = "ABUSE_UPDATE_FAILURE";

export const ABUSE_LIST_REQUEST = "ABUSE_LIST_REQUEST";
export const ABUSE_LIST_SUCCESS = "ABUSE_LIST_SUCCESS";
export const ABUSE_LIST_FAILURE = "ABUSE_LIST_FAILURE";

export const ABUSE_DELETE_REQUEST = "ABUSE_DELETE_REQUEST";
export const ABUSE_DELETE_SUCCESS = "ABUSE_DELETE_SUCCESS";
export const ABUSE_DELETE_FAILURE = "ABUSE_DELETE_FAILURE";

//create abuse dropdown

export const createAbuseDropdown = (dropdown, setModalShow, setFormData) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ABUSE_CREATE_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/report/create/animal/abuse/name/v1`;
      const response = await axios.post(url, dropdown, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: ABUSE_CREATE_SUCCESS,
        payload: response.data,
      });
      setModalShow(false);
      setFormData({});
    } catch (error) {
      errorHandling(dispatch, ABUSE_CREATE_FAILURE, error);
      setModalShow(false);
      setFormData({});
    }
  };
};

// abuse update

export const updateAbuseDropdown = (
  id,
  dropdown,
  setUpdateModalShow,
  setFormData
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ABUSE_UPDATE_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/report/update/animal/abuse/name/${id}/v1`;
      const response = await axios.put(url, dropdown, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: ABUSE_UPDATE_SUCCESS,
        payload: response.data,
      });
      setUpdateModalShow(false);
      setFormData({});
    } catch (error) {
      errorHandling(dispatch, ABUSE_UPDATE_FAILURE, error);
      setUpdateModalShow(false);
      setFormData({});
    }
  };
};

// abuse list

export const abuseDropdownList = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: ABUSE_LIST_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/report/get/all/animal/abuse/name/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: ABUSE_LIST_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      errorHandling(dispatch, ABUSE_LIST_FAILURE, error);
    }
  };
};

// abuse delete

export const deleteAbuseDropdown = (id, setDeleteModal, setFormData) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ABUSE_DELETE_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/report/delete/animal/abuse/name/${id}/v1`;
      const response = await axios.delete(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: ABUSE_DELETE_SUCCESS,
        payload: response.data,
      });
      setDeleteModal(false);
      setFormData({});
    } catch (error) {
      errorHandling(dispatch, ABUSE_DELETE_FAILURE, error);
      setDeleteModal(false);
      setFormData({});
    }
  };
};
