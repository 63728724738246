import React, { useEffect, useMemo, useState } from "react";
import ViewMoreBtn from "../../components/ViewMoreBtn";
import DataTable from "react-data-table-component";
import { arrowRight } from "../../../../assets/svg/Svg";
import { LuFilter } from "react-icons/lu";
import { FiEdit } from "react-icons/fi";
import { AiOutlineDelete } from "react-icons/ai";
import { Col, FormGroup, Modal, ModalBody, Row } from "react-bootstrap";
import DeleteModal from "../../components/DeleteModal";
import { useDispatch, useSelector } from "react-redux";
import { imageBaseUrl, uploadImage } from "../../../../helper";
import moment from "moment";
import { toast } from "react-toastify";
import {
  createCoupon,
  deleteCoupon,
  getAllCoupons,
} from "../../../../redux/couponCode/couponCode.action";
import { deleteBanner } from "../../../../redux/banners/banner.actions";

const CouponCode = () => {
  const [formData, setFormData] = useState({
    image: "",
    title: "",
    category: "",
    description: "",
    discount: "",
    requiredMinPrice: "",
    uptoPrice: "",
  });
  const [modalShow, setModalShow] = useState(false);
  const [data, setData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [status, setStatus] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const dispatch = useDispatch();

  const { list, loading } = useSelector((state) => state.couponInfo);
  const { profile } = useSelector((state) => state.authInfo);

  useEffect(() => {
    dispatch(getAllCoupons());
  }, []);

  useEffect(() => {
    if (list && list.length > 0) {
      setData(list);
    } else {
      setData([]);
    }
  }, [list]);

  const columns = [
    {
      name: "Coupon Image",
      selector: (row) =>
        row?.image ? (
          <img className="list_img" src={`${imageBaseUrl}${row.image}`} />
        ) : (
          "-"
        ),
      sortable: true,
    },
    {
      name: "Title",
      selector: (row) => row?.title,
      sortable: true,
    },
    {
      name: "Description",
      selector: (row) => row?.description,
      sortable: true,
    },
    {
      name: "Category",
      selector: (row) => row?.category,
      sortable: true,
    },
    {
      name: "Created At",
      selector: (row) =>
        moment(new Date(row?.createdAt)).format("DD-MM-YYYY h:mm a"),
      sortable: true,
    },
    {
      name: "Discount %",
      selector: (row) => row?.discount,
      sortable: true,
    },
    {
      name: "Required Min Price",
      selector: (row) => row?.requiredMinPrice,
      sortable: true,
    },
    {
      name: "Upto Price",
      selector: (row) => row?.uptoPrice || "-",
      sortable: true,
    },
    {
      ...((profile?.role == "admin" || profile?.couponcodeAccess?.canRead) && {
        name: "Actions",
        selector: (row) => (
          <div className="d-flex ">
            {(profile?.role == "admin" ||
              profile?.couponcodeAccess?.canDelete) && (
              <AiOutlineDelete
                onClick={() => {
                  setDeleteId(row._id);
                  setDeleteModal(true);
                }}
                size={19}
              />
            )}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <ViewMoreBtn redirectUrl={`/coupon-codes/${row._id}`} />
          </div>
        ),
      }),
    },
  ];

  const filteredData = useMemo(() => {
    return data.filter((item) => {
      const matchesSearchText =
        item.title?.toLowerCase().includes(searchText.toLowerCase()) ||
        item.category?.toLowerCase().includes(searchText.toLowerCase());

      return matchesSearchText;
    });
  }, [data, searchText]);

  const handleSearch = (value) => {
    setSearchText(value);
    setResetPaginationToggle(!resetPaginationToggle);
  };
  const handleStatusChange = (value) => {
    setStatus(value);
    setResetPaginationToggle(!resetPaginationToggle);
  };

  const paginationOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
    noRowsPerPage: false,
    selectAllRowsItem: false,
    selectAllRowsItemText: "All",
  };

  const customPagination = () => {
    const page = Math.ceil(filteredData.length / paginationPerPage);
    const pageButtons = [];
    const startPage = Math.max(currentPage - 1, 1);
    const endPage = Math.min(currentPage + 1, page);

    // Always add the first page
    pageButtons.push(
      <button
        key={1}
        className={`btn-pagination ${currentPage === 1 ? "active" : ""}`}
        onClick={() => setCurrentPage(1)}
      >
        1
      </button>
    );

    // Ellipsis after the first page if necessary
    if (startPage > 2) {
      pageButtons.push(
        <span key="ellipsis-start" className="pagination-ellipsis">
          &hellip;
        </span>
      );
    }

    // Range of buttons around the current page
    for (let i = startPage; i <= endPage; i++) {
      if (i !== 1 && i !== page) {
        pageButtons.push(
          <button
            key={i}
            className={`btn-pagination ${currentPage === i ? "active" : ""}`}
            onClick={() => setCurrentPage(i)}
          >
            {i}
          </button>
        );
      }
    }

    // Ellipsis before the last page if necessary
    if (endPage < page - 1) {
      pageButtons.push(
        <span key="ellipsis-end" className="pagination-ellipsis">
          &hellip;
        </span>
      );
    }

    // Always add the last page
    if (page !== 1) {
      pageButtons.push(
        <button
          key={page}
          className={`btn-pagination ${currentPage === page ? "active" : ""}`}
          onClick={() => setCurrentPage(page)}
        >
          {page}
        </button>
      );
    }

    return (
      <div className="pagination-container mt-5">
        <button
          className="btn-pagination btn-pagi_prev"
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          {arrowRight}
        </button>
        <span>{pageButtons}</span>
        <button
          className="btn-pagination btn-pagi_next"
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage === page}
        >
          {arrowRight}
        </button>
      </div>
    );
  };

  const paginationPerPage = 10;
  const [currentPage, setCurrentPage] = React.useState(1);

  const paginatedData = filteredData.slice(
    (currentPage - 1) * paginationPerPage,
    currentPage * paginationPerPage
  );

  const handleCreateCoupon = async () => {
    if (
      !formData.image.file ||
      !formData.title ||
      !formData.category ||
      !formData.description
    ) {
      toast("Please fill Mandatory Fields");
      return;
    }
    const image = await uploadImage(
      "/coupon/upload/coupon/image/v1",
      formData.image.file,
      setSelectedImage
    );

    dispatch(createCoupon({ ...formData, image }, setModalShow, setFormData));
  };

  const handleDeleteCoupon = () => {
    dispatch(deleteCoupon(deleteId, setDeleteModal, setDeleteId));
  };

  return (
    <>
      {loading ? (
        <div className="w-100 d-flex">
          <div
            className="spinner-border text-primary mx-auto"
            role="status"
          ></div>
        </div>
      ) : (
        <>
          <div>
            <div className="page-header">
              <h3 className="page-title">Coupon Code</h3>
            </div>
            <div className="row w-100">
              <div className="col-lg-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <>
                      {/* <button onClick={handleClearRows}>Clear Selected Rows</button> */}
                      <DataTable
                        // title={<h6 className="card-title">Pets Category List</h6>}
                        columns={columns}
                        data={paginatedData}
                        pagination
                        paginationServer
                        paginationTotalRows={filteredData.length}
                        onChangePage={(page) => setCurrentPage(page)}
                        paginationComponentOptions={paginationOptions}
                        paginationComponent={customPagination}
                        highlightOnHover
                        pointerOnHover
                        subHeader
                        subHeaderAlign="left"
                        subHeaderComponent={
                          <>
                            <Row className="mb-3 w-100">
                              <Col md={12} className="d-flex gap-2">
                                <div className="d-flex align-items-start flex-column justify-content-end gap-2">
                                  <span className="d-flex align-items-center gap-2 me-3 filterby_label">
                                    <LuFilter />
                                    Filter By :
                                  </span>
                                  <div className="table_filter">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Search by name"
                                      value={searchText}
                                      onChange={(e) => {
                                        setCurrentPage(1);
                                        handleSearch(e.target.value);
                                      }}
                                    />
                                  </div>
                                </div>
                                {(profile?.role == "admin" ||
                                  profile?.couponcodeAccess?.canCreate) && (
                                  <div className="d-flex align-items-start flex-column justify-content-end ms-auto gap-2">
                                    <button
                                      className="btn_primary_outline_sm ms-auto"
                                      onClick={() => setModalShow(true)}
                                    >
                                      Add New Coupon Code
                                    </button>
                                  </div>
                                )}
                              </Col>
                            </Row>
                          </>
                        }
                      />
                    </>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal
            show={modalShow}
            size="sx"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <ModalBody>
              <h5 className="mb-3">Add New Coupon</h5>

              <FormGroup>
                <label>Coupon Image</label>
                <input
                  required={true}
                  className="form-control"
                  type="file"
                  accept="image/*"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      image: { file: e.target.files[0] },
                    })
                  }
                />
              </FormGroup>
              <FormGroup className="mb-3">
                <label>Title</label>
                <input
                  type="text"
                  placeholder="Enter Title"
                  value={formData.title}
                  className="form-control"
                  onChange={(e) =>
                    setFormData({ ...formData, title: e.target.value })
                  }
                  required={true}
                />
              </FormGroup>
              <FormGroup className="mb-3">
                <label>Description</label>
                <input
                  type="text"
                  placeholder="Enter Description"
                  value={formData.description}
                  className="form-control"
                  onChange={(e) =>
                    setFormData({ ...formData, description: e.target.value })
                  }
                  required={true}
                />
              </FormGroup>
              <FormGroup className="mb-3">
                <label>Category</label>
                <select
                  type="text"
                  placeholder="Select Category"
                  value={formData.category}
                  className="form-control"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      category: e.target.value,
                    })
                  }
                >
                  <option selected value="">
                    Select Category
                  </option>
                  <option value="Order">Order</option>
                  <option value="Homecare">Homecare</option>
                  <option value="Consultation">Consultation</option>
                </select>
              </FormGroup>
              <FormGroup className="mb-3">
                <label>Discount</label>
                <input
                  type="number"
                  placeholder="Enter Discount"
                  value={formData.discount}
                  className="form-control"
                  onChange={(e) =>
                    setFormData({ ...formData, discount: e.target.value })
                  }
                  required={true}
                />
              </FormGroup>
              <FormGroup className="mb-3">
                <label>Required Min Price</label>
                <input
                  type="number"
                  placeholder="Enter Required Min Price"
                  value={formData.requiredMinPrice}
                  className="form-control"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      requiredMinPrice: e.target.value,
                    })
                  }
                  required={true}
                />
              </FormGroup>
              <FormGroup className="mb-3">
                <label>Upto Price</label>
                <input
                  type="number"
                  placeholder="Enter Upto Price"
                  value={formData.uptoPrice}
                  className="form-control"
                  onChange={(e) =>
                    setFormData({ ...formData, uptoPrice: e.target.value })
                  }
                  required={true}
                />
              </FormGroup>
            </ModalBody>
            <Modal.Footer>
              <button
                type="button"
                className="btn_secondary"
                onClick={() => {
                  setModalShow(false);
                  setFormData({
                    image: "",
                    title: "",
                    category: "",
                    description: "",
                    discount: "",
                    requiredMinPrice: "",
                    uptoPrice: "",
                  });
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn_primary"
                onClick={(e) => handleCreateCoupon()}
              >
                Add
              </button>
            </Modal.Footer>
          </Modal>
          <DeleteModal
            deleteModal={deleteModal}
            setDeleteModal={setDeleteModal}
            handlePerform={handleDeleteCoupon}
          />
        </>
      )}
    </>
  );
};

export default CouponCode;
