import React, { useEffect, useMemo, useState } from "react";
import ViewMoreBtn from "../../components/ViewMoreBtn";
import DataTable from "react-data-table-component";
import { arrowRight } from "../../../../assets/svg/Svg";
import { LuFilter } from "react-icons/lu";
import { FiEdit } from "react-icons/fi";
import { AiOutlineDelete } from "react-icons/ai";
import {
  Col,
  FormGroup,
  FormLabel,
  Modal,
  ModalBody,
  Row,
} from "react-bootstrap";
import DeleteModal from "../../components/DeleteModal";
import { useDispatch, useSelector } from "react-redux";
import * as bannerActions from "../../../../redux/banners/banner.actions";
import { imageBaseUrl, uploadImage } from "../../../../helper";
import moment from "moment";
import { toast } from "react-toastify";
import Flatpickr from "react-flatpickr";

const user = [
  "Instant Consultation",
  "Schedule Consultation",
  "Homecare",
  "E-Health Card",
  "Government Pet",
  "Register Pet",
  "Blogs",
  "Videos",
  "Report abuse",
  "E-commerce",
  "Pharmacy",
  "Ambulance",
  "Reminders",
  "Refer & Earn",
  "Website",
];

const vendor = ["Add Product", "Website"];

const Banners = () => {
  const [formData, setFormData] = useState({
    headName: "",
    paragraph: "",
    buttonName: "",
    bgImage: "",
    app: "",
    category: "",
  });
  const [webisteLink, setWebsiteLink] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [data, setData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [status, setStatus] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const dispatch = useDispatch();

  const { profile } = useSelector((state) => state.authInfo);
  const loading = useSelector((state) => state.bannerInfo.loading);
  const list = useSelector((state) => state.bannerInfo.list);

  useEffect(() => {
    dispatch(bannerActions.getAllBanners());
  }, []);

  useEffect(() => {
    if (list && list.length > 0) {
      setData(list);
    } else {
      setData([]);
    }
  }, [list]);

  const columns = [
    {
      name: "Banner Image",
      selector: (row) =>
        row?.bgImage ? (
          <img className="list_img" src={`${imageBaseUrl}${row.bgImage}`} />
        ) : (
          "-"
        ),
      sortable: true,
    },
    {
      name: "App",
      selector: (row) => row?.app,
      sortable: true,
    },
    {
      name: "Category",
      selector: (row) => row?.category,
      sortable: true,
    },
    {
      name: "Created At",
      selector: (row) =>
        moment(new Date(row?.createdAt)).format("DD-MM-YYYY h:mm a"),
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => (
        <label className={`badge badge-${row.active ? "success" : "danger"}`}>
          {row.active ? "Active" : "Inactive"}
        </label>
      ),
      sortable: true,
    },
    {
      name: "Actions",
      selector: (row) => (
        <div className="d-flex ">
          {(profile?.role == "admin" || profile?.bannersAccess?.canDelete) && (
            <AiOutlineDelete
              onClick={() => {
                setDeleteId(row._id);
                setDeleteModal(true);
              }}
              size={19}
            />
          )}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {(profile?.role == "admin" || profile?.bannersAccess?.canRead) && (
            <ViewMoreBtn redirectUrl={`/banner/${row._id}`} />
          )}
        </div>
      ),
    },
  ];

  function normalizeDate(date) {
    const normalized = new Date(date);
    normalized.setHours(0, 0, 0, 0);
    return normalized;
  }

  const filteredData = useMemo(() => {
    return data.filter((item) => {
      const matchesSearchText =
        item.app?.toLowerCase().includes(searchText.toLowerCase()) ||
        item.category?.toLowerCase().includes(searchText.toLowerCase());

      const createdAt = normalizeDate(item.createdAt);
      const normalizedStartDate = startDate ? normalizeDate(startDate) : null;
      const normalizedEndDate = endDate ? normalizeDate(endDate) : null;

      const matchesDate =
        (!normalizedStartDate || createdAt >= normalizedStartDate) &&
        (!normalizedEndDate || createdAt <= normalizedEndDate);

      return matchesSearchText && matchesDate;
    });
  }, [data, searchText, startDate, endDate]);

  const handleSearch = (value) => {
    setSearchText(value);
    setResetPaginationToggle(!resetPaginationToggle);
  };
  const handleStatusChange = (value) => {
    setStatus(value);
    setResetPaginationToggle(!resetPaginationToggle);
  };

  const paginationOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
    noRowsPerPage: false,
    selectAllRowsItem: false,
    selectAllRowsItemText: "All",
  };

  const customPagination = () => {
    const page = Math.ceil(filteredData.length / paginationPerPage);
    const pageButtons = [];
    const startPage = Math.max(currentPage - 1, 1);
    const endPage = Math.min(currentPage + 1, page);

    // Always add the first page
    pageButtons.push(
      <button
        key={1}
        className={`btn-pagination ${currentPage === 1 ? "active" : ""}`}
        onClick={() => setCurrentPage(1)}
      >
        1
      </button>
    );

    // Ellipsis after the first page if necessary
    if (startPage > 2) {
      pageButtons.push(
        <span key="ellipsis-start" className="pagination-ellipsis">
          &hellip;
        </span>
      );
    }

    // Range of buttons around the current page
    for (let i = startPage; i <= endPage; i++) {
      if (i !== 1 && i !== page) {
        pageButtons.push(
          <button
            key={i}
            className={`btn-pagination ${currentPage === i ? "active" : ""}`}
            onClick={() => setCurrentPage(i)}
          >
            {i}
          </button>
        );
      }
    }

    // Ellipsis before the last page if necessary
    if (endPage < page - 1) {
      pageButtons.push(
        <span key="ellipsis-end" className="pagination-ellipsis">
          &hellip;
        </span>
      );
    }

    // Always add the last page
    if (page !== 1) {
      pageButtons.push(
        <button
          key={page}
          className={`btn-pagination ${currentPage === page ? "active" : ""}`}
          onClick={() => setCurrentPage(page)}
        >
          {page}
        </button>
      );
    }

    return (
      <div className="pagination-container mt-5">
        <button
          className="btn-pagination btn-pagi_prev"
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          {arrowRight}
        </button>
        <span>{pageButtons}</span>
        <button
          className="btn-pagination btn-pagi_next"
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage === page}
        >
          {arrowRight}
        </button>
      </div>
    );
  };

  const paginationPerPage = 10;
  const [currentPage, setCurrentPage] = React.useState(1);

  const paginatedData = filteredData.slice(
    (currentPage - 1) * paginationPerPage,
    currentPage * paginationPerPage
  );

  const handleCreateBanner = async () => {
    if (!formData.bgImage.file || !formData.app || !formData.category) {
      toast("Please fill fields");
      return;
    }

    if (formData.category === "Website") {
      if (!webisteLink) {
        toast("Please enter Website link");
        return;
      }
    }
    const bgImage = await uploadImage(
      "/admin/upload/banner/bg/image/v1",
      formData.bgImage.file,
      setSelectedImage
    );
    dispatch(
      bannerActions.createBanner(
        {
          ...formData,
          bgImage,
          category:
            formData.category === "Website" ? webisteLink : formData.category,
        },
        setModalShow,
        setFormData
      )
    );
    setWebsiteLink("");
  };

  const handleDeleteBanner = () => {
    dispatch(bannerActions.deleteBanner(deleteId, setDeleteModal, setDeleteId));
  };

  return (
    <>
      {loading ? (
        <div className="w-100 d-flex">
          <div
            className="spinner-border text-primary mx-auto"
            role="status"
          ></div>
        </div>
      ) : (
        <>
          <div>
            <div className="page-header">
              <h3 className="page-title">Banners</h3>
            </div>
            <div className="row w-100">
              <div className="col-lg-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <>
                      {/* <button onClick={handleClearRows}>Clear Selected Rows</button> */}
                      <DataTable
                        // title={<h6 className="card-title">Pets Category List</h6>}
                        columns={columns}
                        data={paginatedData}
                        pagination
                        paginationServer
                        paginationTotalRows={filteredData.length}
                        onChangePage={(page) => setCurrentPage(page)}
                        paginationComponentOptions={paginationOptions}
                        paginationComponent={customPagination}
                        highlightOnHover
                        pointerOnHover
                        subHeader
                        subHeaderAlign="left"
                        subHeaderComponent={
                          <>
                            <Row className="mb-3 w-100">
                              <Col md={12} className="d-flex gap-2">
                                <div className="d-flex align-items-start flex-column justify-content-end gap-2">
                                  <span className="d-flex align-items-center gap-2 me-3 filterby_label">
                                    <LuFilter />
                                    Filter By :
                                  </span>
                                  <div className="table_filter">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Search by name"
                                      value={searchText}
                                      onChange={(e) => {
                                        setCurrentPage(1);
                                        handleSearch(e.target.value);
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="d-flex align-items-start flex-column justify-content-end gap-2">
                                  <span className="d-flex align-items-center gap-2 me-3 filterby_label">
                                    Start Date:
                                  </span>
                                  <div className="table_filter">
                                    <Flatpickr
                                      placeholder="Start Date"
                                      options={{
                                        dateFormat: "d-m-Y",
                                        maxDate: "today",
                                      }}
                                      className="form-control"
                                      value={startDate}
                                      onChange={(date) => setStartDate(date)}
                                    />
                                  </div>
                                </div>
                                <div className="d-flex align-items-start flex-column justify-content-end gap-2">
                                  <span className="d-flex align-items-center gap-2 me-3 filterby_label">
                                    End Date:
                                  </span>
                                  <div className="table_filter">
                                    <Flatpickr
                                      placeholder="End Date"
                                      options={{
                                        dateFormat: "d-m-Y",
                                        maxDate: "today",
                                      }}
                                      className="form-control"
                                      value={startDate}
                                      onChange={(date) => setStartDate(date)}
                                    />
                                  </div>
                                </div>
                                {(profile?.role == "admin" ||
                                  profile?.bannersAccess?.canCreate) && (
                                  <div className="d-flex align-items-start flex-column justify-content-end ms-auto gap-2">
                                    <button
                                      className="btn_primary_outline_sm ms-auto"
                                      onClick={() => setModalShow(true)}
                                    >
                                      Add New Banner
                                    </button>
                                  </div>
                                )}
                              </Col>
                            </Row>
                          </>
                        }
                      />
                    </>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal
            show={modalShow}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <ModalBody>
              <h5 className="mb-3">Add New Banner</h5>

              <FormGroup>
                <label>Banner Image</label>
                <input
                  required={true}
                  className="form-control"
                  type="file"
                  accept="image/*"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      bgImage: { file: e.target.files[0] },
                    })
                  }
                />
              </FormGroup>
              <FormGroup className="mb-3">
                <label>Heading name</label>
                <input
                  type="text"
                  placeholder="Heading name"
                  value={formData.title}
                  className="form-control"
                  onChange={(e) =>
                    setFormData({ ...formData, headName: e.target.value })
                  }
                  required={true}
                />
              </FormGroup>
              <FormGroup className="mb-3">
                <label>Description</label>
                <input
                  type="text"
                  placeholder="Description"
                  value={formData.title}
                  className="form-control"
                  onChange={(e) =>
                    setFormData({ ...formData, paragraph: e.target.value })
                  }
                  required={true}
                />
              </FormGroup>
              <FormGroup className="mb-3">
                <label>Button name</label>
                <input
                  type="text"
                  placeholder="Button name"
                  value={formData.title}
                  className="form-control"
                  onChange={(e) =>
                    setFormData({ ...formData, buttonName: e.target.value })
                  }
                  required={true}
                />
              </FormGroup>
              <FormGroup className="mb-3">
                <label>App</label>
                <select
                  type="text"
                  placeholder="App"
                  value={formData.app}
                  className="form-control"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      app: e.target.value,
                      category: "",
                    })
                  }
                >
                  <option selected value="">
                    Select App
                  </option>
                  <option value="user">user</option>
                  {/* <option value="vet">vet</option> */}
                  <option value="vendor">vendor</option>
                </select>
              </FormGroup>
              <FormGroup className="mb-3">
                <label>Category</label>
                <select
                  type="text"
                  placeholder="Category"
                  value={formData.category}
                  className="form-control"
                  disabled={!formData.app}
                  onChange={(e) =>
                    setFormData({ ...formData, category: e.target.value })
                  }
                >
                  <option selected value="">
                    Select Category
                  </option>
                  {formData.app === "user" &&
                    user.map((elem) => (
                      <option value={elem} key={elem}>
                        {elem}
                      </option>
                    ))}
                  {formData.app === "vendor" &&
                    vendor.map((elem) => (
                      <option value={elem} key={elem}>
                        {elem}
                      </option>
                    ))}
                </select>
              </FormGroup>
              {formData.category === "Website" && (
                <FormGroup className="mb-3">
                  <label>Webiste link</label>
                  <input
                    type="text"
                    placeholder="Website link"
                    value={webisteLink}
                    className="form-control"
                    onChange={(e) => setWebsiteLink(e.target.value)}
                    required={true}
                  />
                </FormGroup>
              )}
            </ModalBody>
            <Modal.Footer>
              <button
                type="button"
                className="btn_secondary"
                onClick={() => {
                  setModalShow(false);
                  setFormData({
                    headName: "",
                    paragraph: "",
                    buttonName: "",
                    bgImage: "",
                    app: "",
                    category: "",
                  });
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn_primary"
                onClick={(e) => handleCreateBanner()}
              >
                Add
              </button>
            </Modal.Footer>
          </Modal>
          <DeleteModal
            deleteModal={deleteModal}
            setDeleteModal={setDeleteModal}
            handlePerform={handleDeleteBanner}
          />
        </>
      )}
    </>
  );
};

export default Banners;
