import axios from "axios";
import { errorHandling } from "../../helper";
import { toast } from "react-toastify";

//brand

export const BRAND_CREATE_REQUEST = "BRAND_CREATE_REQUEST";
export const BRAND_CREATE_SUCCESS = "BRAND_CREATE_SUCCESS";
export const BRAND_CREATE_FAILURE = "BRAND_CREATE_FAILURE";

export const BRAND_UPDATE_REQUEST = "BRAND_UPDATE_REQUEST";
export const BRAND_UPDATE_SUCCESS = "BRAND_UPDATE_SUCCESS";
export const BRAND_UPDATE_FAILURE = "BRAND_UPDATE_FAILURE";

export const BRAND_LIST_REQUEST = "BRAND_LIST_REQUEST";
export const BRAND_LIST_SUCCESS = "BRAND_LIST_SUCCESS";
export const BRAND_LIST_FAILURE = "BRAND_LIST_FAILURE";

export const BRAND_DELETE_REQUEST = "BRAND_DELETE_REQUEST";
export const BRAND_DELETE_SUCCESS = "BRAND_DELETE_SUCCESS";
export const BRAND_DELETE_FAILURE = "BRAND_DELETE_FAILURE";

//category

export const CATEGORY_CREATE_REQUEST = "CATEGORY_CREATE_REQUEST";
export const CATEGORY_CREATE_SUCCESS = "CATEGORY_CREATE_SUCCESS";
export const CATEGORY_CREATE_FAILURE = "CATEGORY_CREATE_FAILURE";

export const CATEGORY_UPDATE_REQUEST = "CATEGORY_UPDATE_REQUEST";
export const CATEGORY_UPDATE_SUCCESS = "CATEGORY_UPDATE_SUCCESS";
export const CATEGORY_UPDATE_FAILURE = "CATEGORY_UPDATE_FAILURE";

export const CATEGORY_LIST_REQUEST = "CATEGORY_LIST_REQUEST";
export const CATEGORY_LIST_SUCCESS = "CATEGORY_LIST_SUCCESS";
export const CATEGORY_LIST_FAILURE = "CATEGORY_LIST_FAILURE";

export const CATEGORY_DELETE_REQUEST = "CATEGORY_DELETE_REQUEST";
export const CATEGORY_DELETE_SUCCESS = "CATEGORY_DELETE_SUCCESS";
export const CATEGORY_DELETE_FAILURE = "CATEGORY_DELETE_FAILURE";

//subcategory

export const SUBCATEGORY_CREATE_REQUEST = "SUBCATEGORY_CREATE_REQUEST";
export const SUBCATEGORY_CREATE_SUCCESS = "SUBCATEGORY_CREATE_SUCCESS";
export const SUBCATEGORY_CREATE_FAILURE = "SUBCATEGORY_CREATE_FAILURE";

export const SUBCATEGORY_UPDATE_REQUEST = "SUBCATEGORY_UPDATE_REQUEST";
export const SUBCATEGORY_UPDATE_SUCCESS = "SUBCATEGORY_UPDATE_SUCCESS";
export const SUBCATEGORY_UPDATE_FAILURE = "SUBCATEGORY_UPDATE_FAILURE";

export const SUBCATEGORY_LIST_REQUEST = "SUBCATEGORY_LIST_REQUEST";
export const SUBCATEGORY_LIST_SUCCESS = "SUBCATEGORY_LIST_SUCCESS";
export const SUBCATEGORY_LIST_FAILURE = "SUBCATEGORY_LIST_FAILURE";

export const SUBCATEGORY_DELETE_REQUEST = "SUBCATEGORY_DELETE_REQUEST";
export const SUBCATEGORY_DELETE_SUCCESS = "SUBCATEGORY_DELETE_SUCCESS";
export const SUBCATEGORY_DELETE_FAILURE = "SUBCATEGORY_DELETE_FAILURE";

// health concern

export const HEALTH_CONCERN_CREATE_REQUEST = "HEALTH_CONCERN_CREATE_REQUEST";
export const HEALTH_CONCERN_CREATE_SUCCESS = "HEALTH_CONCERN_CREATE_SUCCESS";
export const HEALTH_CONCERN_CREATE_FAILURE = "HEALTH_CONCERN_CREATE_FAILURE";

export const HEALTH_CONCERN_UPDATE_REQUEST = "HEALTH_CONCERN_UPDATE_REQUEST";
export const HEALTH_CONCERN_UPDATE_SUCCESS = "HEALTH_CONCERN_UPDATE_SUCCESS";
export const HEALTH_CONCERN_UPDATE_FAILURE = "HEALTH_CONCERN_UPDATE_FAILURE";

export const HEALTH_CONCERN_LIST_REQUEST = "HEALTH_CONCERN_LIST_REQUEST";
export const HEALTH_CONCERN_LIST_SUCCESS = "HEALTH_CONCERN_LIST_SUCCESS";
export const HEALTH_CONCERN_LIST_FAILURE = "HEALTH_CONCERN_LIST_FAILURE";

export const HEALTH_CONCERN_DELETE_REQUEST = "HEALTH_CONCERN_DELETE_REQUEST";
export const HEALTH_CONCERN_DELETE_SUCCESS = "HEALTH_CONCERN_DELETE_SUCCESS";
export const HEALTH_CONCERN_DELETE_FAILURE = "HEALTH_CONCERN_DELETE_FAILURE";

//unit type

export const UNIT_TYPE_CREATE_REQUEST = "UNIT_TYPE_CREATE_REQUEST";
export const UNIT_TYPE_CREATE_SUCCESS = "UNIT_TYPE_CREATE_SUCCESS";
export const UNIT_TYPE_CREATE_FAILURE = "UNIT_TYPE_CREATE_FAILURE";

export const UNIT_TYPE_UPDATE_REQUEST = "UNIT_TYPE_UPDATE_REQUEST";
export const UNIT_TYPE_UPDATE_SUCCESS = "UNIT_TYPE_UPDATE_SUCCESS";
export const UNIT_TYPE_UPDATE_FAILURE = "UNIT_TYPE_UPDATE_FAILURE";

export const UNIT_TYPE_LIST_REQUEST = "UNIT_TYPE_LIST_REQUEST";
export const UNIT_TYPE_LIST_SUCCESS = "UNIT_TYPE_LIST_SUCCESS";
export const UNIT_TYPE_LIST_FAILURE = "UNIT_TYPE_LIST_FAILURE";

export const UNIT_TYPE_DELETE_REQUEST = "UNIT_TYPE_DELETE_REQUEST";
export const UNIT_TYPE_DELETE_SUCCESS = "UNIT_TYPE_DELETE_SUCCESS";
export const UNIT_TYPE_DELETE_FAILURE = "UNIT_TYPE_DELETE_FAILURE";

//unit name

export const UNIT_NAME_CREATE_REQUEST = "UNIT_NAME_CREATE_REQUEST";
export const UNIT_NAME_CREATE_SUCCESS = "UNIT_NAME_CREATE_SUCCESS";
export const UNIT_NAME_CREATE_FAILURE = "UNIT_NAME_CREATE_FAILURE";

export const UNIT_NAME_UPDATE_REQUEST = "UNIT_NAME_UPDATE_REQUEST";
export const UNIT_NAME_UPDATE_SUCCESS = "UNIT_NAME_UPDATE_SUCCESS";
export const UNIT_NAME_UPDATE_FAILURE = "UNIT_NAME_UPDATE_FAILURE";

export const UNIT_NAME_LIST_REQUEST = "UNIT_NAME_LIST_REQUEST";
export const UNIT_NAME_LIST_SUCCESS = "UNIT_NAME_LIST_SUCCESS";
export const UNIT_NAME_LIST_FAILURE = "UNIT_NAME_LIST_FAILURE";

export const UNIT_NAME_DELETE_REQUEST = "UNIT_NAME_DELETE_REQUEST";
export const UNIT_NAME_DELETE_SUCCESS = "UNIT_NAME_DELETE_SUCCESS";
export const UNIT_NAME_DELETE_FAILURE = "UNIT_NAME_DELETE_FAILURE";

// brand create

export const createBrandDropdown = (dropdown, setModalShow, setFormData) => {
  return async (dispatch) => {
    try {
      dispatch({ type: BRAND_CREATE_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/product/create/brand/info/v1`;
      const response = await axios.post(url, dropdown, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: BRAND_CREATE_SUCCESS,
        payload: response.data,
      });
      setModalShow(false);
      setFormData({});
    } catch (error) {
      errorHandling(dispatch, BRAND_CREATE_FAILURE, error);
      setModalShow(false);
      setFormData({});
    }
  };
};

// brand update

export const updateBrandDropdown = (
  id,
  dropdown,
  setUpdateModalShow,
  setFormData
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: BRAND_UPDATE_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/product/update/brand/${id}/info/v1`;
      const response = await axios.put(url, dropdown, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: BRAND_UPDATE_SUCCESS,
        payload: response.data,
      });
      setUpdateModalShow(false);
      setFormData({});
    } catch (error) {
      errorHandling(dispatch, BRAND_UPDATE_FAILURE, error);
      setUpdateModalShow(false);
      setFormData({});
    }
  };
};

// brand list

export const brandDropdownList = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: BRAND_LIST_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/product/admin/get/all/brand/info/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: BRAND_LIST_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      errorHandling(dispatch, BRAND_LIST_FAILURE, error);
    }
  };
};

// brand delete

export const deleteBrandDropdown = (id, setDeleteModal, setFormData) => {
  return async (dispatch) => {
    try {
      dispatch({ type: BRAND_DELETE_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/product/delete/brand/${id}/info/v1`;
      const response = await axios.delete(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: BRAND_DELETE_SUCCESS,
        payload: response.data,
      });
      setDeleteModal(false);
      setFormData({});
    } catch (error) {
      errorHandling(dispatch, BRAND_DELETE_FAILURE, error);
      setDeleteModal(false);
      setFormData({});
    }
  };
};

//category

export const createCategoryDropdown = (dropdown, setModalShow, setFormData) => {
  return async (dispatch) => {
    try {
      dispatch({ type: CATEGORY_CREATE_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/product/create/product/category/v1`;
      const response = await axios.post(url, dropdown, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: CATEGORY_CREATE_SUCCESS,
        payload: response.data,
      });
      setModalShow(false);
      setFormData({});
    } catch (error) {
      errorHandling(dispatch, CATEGORY_CREATE_FAILURE, error);
      setModalShow(false);
      setFormData({});
    }
  };
};

// category update

export const updateCategoryDropdown = (
  id,
  dropdown,
  setUpdateModalShow,
  setFormData
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: CATEGORY_UPDATE_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/product/update/product/category/${id}/v1`;
      const response = await axios.put(url, dropdown, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: CATEGORY_UPDATE_SUCCESS,
        payload: response.data,
      });
      setUpdateModalShow(false);
      setFormData({});
    } catch (error) {
      errorHandling(dispatch, CATEGORY_UPDATE_FAILURE, error);
      setUpdateModalShow(false);
      setFormData({});
    }
  };
};

// category list

export const categoryDropdownList = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: CATEGORY_LIST_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/product/get/all/product/category/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: CATEGORY_LIST_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      errorHandling(dispatch, CATEGORY_LIST_FAILURE, error);
    }
  };
};

// category delete

export const deleteCategoryDropdown = (id, setDeleteModal, setFormData) => {
  return async (dispatch) => {
    try {
      dispatch({ type: CATEGORY_DELETE_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/product/delete/product/category/${id}/v1`;
      const response = await axios.delete(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: CATEGORY_DELETE_SUCCESS,
        payload: response.data,
      });
      setDeleteModal(false);
      setFormData({});
    } catch (error) {
      errorHandling(dispatch, CATEGORY_DELETE_FAILURE, error);
      setDeleteModal(false);
      setFormData({});
    }
  };
};

//   subcategory

export const createSubcategoryDropdown = (
  dropdown,
  setModalShow,
  setFormData
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: SUBCATEGORY_CREATE_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/product/create/product/sub/category/v1`;
      const response = await axios.post(url, dropdown, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: SUBCATEGORY_CREATE_SUCCESS,
        payload: response.data,
      });
      setModalShow(false);
      setFormData({});
    } catch (error) {
      errorHandling(dispatch, SUBCATEGORY_CREATE_FAILURE, error);
      setModalShow(false);
      setFormData({});
    }
  };
};

// SUBCATEGORY update

export const updateSubcategoryDropdown = (
  id,
  dropdown,
  setUpdateModalShow,
  setFormData
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: SUBCATEGORY_UPDATE_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/product/update/product/sub/category/${id}/v1`;
      const response = await axios.put(url, dropdown, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: SUBCATEGORY_UPDATE_SUCCESS,
        payload: response.data,
      });
      setUpdateModalShow(false);
      setFormData({});
    } catch (error) {
      errorHandling(dispatch, SUBCATEGORY_UPDATE_FAILURE, error);
      setUpdateModalShow(false);
      setFormData({});
    }
  };
};

// SUBCATEGORY list

export const subcategoryDropdownList = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: SUBCATEGORY_LIST_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/product/get/all/product/subcategory/with/category/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: SUBCATEGORY_LIST_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      errorHandling(dispatch, SUBCATEGORY_LIST_FAILURE, error);
    }
  };
};

// SUBCATEGORY delete

export const deleteSubcategoryDropdown = (id, setDeleteModal, setFormData) => {
  return async (dispatch) => {
    try {
      dispatch({ type: SUBCATEGORY_DELETE_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/product/delete/product/sub/category/${id}/v1`;
      const response = await axios.delete(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: SUBCATEGORY_DELETE_SUCCESS,
        payload: response.data,
      });
      setDeleteModal(false);
      setFormData({});
    } catch (error) {
      errorHandling(dispatch, SUBCATEGORY_DELETE_FAILURE, error);
      setDeleteModal(false);
      setFormData({});
    }
  };
};

//health concern

export const createHealthConcernDropdown = (
  dropdown,
  setModalShow,
  setFormData
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: HEALTH_CONCERN_CREATE_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/product/create/concern/name/v1`;
      const response = await axios.post(url, dropdown, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: HEALTH_CONCERN_CREATE_SUCCESS,
        payload: response.data,
      });
      setModalShow(false);
      setFormData({});
    } catch (error) {
      errorHandling(dispatch, HEALTH_CONCERN_CREATE_FAILURE, error);
      setModalShow(false);
      setFormData({});
    }
  };
};

// HEALTHCONCERN update

export const updateHealthConcernDropdown = (
  id,
  dropdown,
  setUpdateModalShow,
  setFormData
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: HEALTH_CONCERN_UPDATE_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/product/update/concern/name/${id}/v1`;
      const response = await axios.put(url, dropdown, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: HEALTH_CONCERN_UPDATE_SUCCESS,
        payload: response.data,
      });
      setUpdateModalShow(false);
      setFormData({});
    } catch (error) {
      errorHandling(dispatch, HEALTH_CONCERN_UPDATE_FAILURE, error);
      setUpdateModalShow(false);
      setFormData({});
    }
  };
};

// HEALTHCONCERN list

export const healthConcernDropdownList = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: HEALTH_CONCERN_LIST_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/product/get/concerns/name/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: HEALTH_CONCERN_LIST_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      errorHandling(dispatch, HEALTH_CONCERN_LIST_FAILURE, error);
    }
  };
};

// HEALTHCONCERN delete

export const deleteHealthConcernDropdown = (
  id,
  setDeleteModal,
  setFormData
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: HEALTH_CONCERN_DELETE_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/product/delete/concern/name/${id}/v1`;
      const response = await axios.delete(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: HEALTH_CONCERN_DELETE_SUCCESS,
        payload: response.data,
      });
      setDeleteModal(false);
      setFormData({});
    } catch (error) {
      errorHandling(dispatch, HEALTH_CONCERN_DELETE_FAILURE, error);
      setDeleteModal(false);
      setFormData({});
    }
  };
};

//unit-type

export const createUnitTypeDropdown = (dropdown, setModalShow, setFormData) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UNIT_TYPE_CREATE_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/product/create/product/unit/type/v1`;
      const response = await axios.post(url, dropdown, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: UNIT_TYPE_CREATE_SUCCESS,
        payload: response.data,
      });
      setModalShow(false);
      setFormData({});
    } catch (error) {
      errorHandling(dispatch, UNIT_TYPE_CREATE_FAILURE, error);
      setModalShow(false);
      setFormData({});
    }
  };
};

// UNIT_TYPE update

export const updateUnitTypeDropdown = (
  id,
  dropdown,
  setUpdateModalShow,
  setFormData
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UNIT_TYPE_UPDATE_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/product/update/product/unit/type/${id}/v1`;
      const response = await axios.put(url, dropdown, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: UNIT_TYPE_UPDATE_SUCCESS,
        payload: response.data,
      });
      setUpdateModalShow(false);
      setFormData({});
    } catch (error) {
      errorHandling(dispatch, UNIT_TYPE_UPDATE_FAILURE, error);
      setUpdateModalShow(false);
      setFormData({});
    }
  };
};

// UNIT_TYPE list

export const unitTypeDropdownList = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: UNIT_TYPE_LIST_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/product/get/product/unit/type/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: UNIT_TYPE_LIST_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      errorHandling(dispatch, UNIT_TYPE_LIST_FAILURE, error);
    }
  };
};

// UNIT_TYPE delete

export const deleteUnitTypeDropdown = (id, setDeleteModal, setFormData) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UNIT_TYPE_DELETE_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/product/delete/product/unit/type/${id}/v1`;
      const response = await axios.delete(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: UNIT_TYPE_DELETE_SUCCESS,
        payload: response.data,
      });
      setDeleteModal(false);
      setFormData({});
    } catch (error) {
      errorHandling(dispatch, UNIT_TYPE_DELETE_FAILURE, error);
      setDeleteModal(false);
      setFormData({});
    }
  };
};

//unit-name

export const createUnitNameDropdown = (dropdown, setModalShow, setFormData) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UNIT_NAME_CREATE_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/product/create/product/unit/v1`;
      const response = await axios.post(url, dropdown, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: UNIT_NAME_CREATE_SUCCESS,
        payload: response.data,
      });
      setModalShow(false);
      setFormData({});
    } catch (error) {
      errorHandling(dispatch, UNIT_NAME_CREATE_FAILURE, error);
      setModalShow(false);
      setFormData({});
    }
  };
};

// UNIT_NAME update

export const updateUnitNameDropdown = (
  id,
  dropdown,
  setUpdateModalShow,
  setFormData
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UNIT_NAME_UPDATE_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/product/update/product/unit/${id}/v1`;
      const response = await axios.put(url, dropdown, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: UNIT_NAME_UPDATE_SUCCESS,
        payload: response.data,
      });
      setUpdateModalShow(false);
      setFormData({});
    } catch (error) {
      errorHandling(dispatch, UNIT_NAME_UPDATE_FAILURE, error);
      setUpdateModalShow(false);
      setFormData({});
    }
  };
};

// UNIT_NAME list

export const unitNameDropdownList = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: UNIT_NAME_LIST_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/product/get/all/product/unit/with/unit/type/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: UNIT_NAME_LIST_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      errorHandling(dispatch, UNIT_NAME_LIST_FAILURE, error);
    }
  };
};

// UNIT_NAME delete

export const deleteUnitNameDropdown = (id, setDeleteModal, setFormData) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UNIT_NAME_DELETE_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/product/delete/product/unit/${id}/v1`;
      const response = await axios.delete(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: UNIT_NAME_DELETE_SUCCESS,
        payload: response.data,
      });
      setDeleteModal(false);
      setFormData({});
    } catch (error) {
      errorHandling(dispatch, UNIT_NAME_DELETE_FAILURE, error);
      setDeleteModal(false);
      setFormData({});
    }
  };
};
