import * as consultationActions from "./consultation.actions";

export const consultationFeatureKey = "consultationInfo";

const initialState = {
  loading: false,
  errorMsg: "",
  list: [],
  details: {},
  prescriptions: {},
};

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case consultationActions.CONSULTATION_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case consultationActions.CONSULTATION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: payload.data,
      };

    case consultationActions.CONSULTATION_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        list: [],
      };

    case consultationActions.CONSULTATION_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case consultationActions.CONSULTATION_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        details: payload.data,
        prescriptions: payload.prescriptionDetails,
      };

    case consultationActions.CONSULTATION_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        details: {},
      };

    default:
      return state;
  }
};
