import * as ambulanceActions from "./ambulance.actions";

export const ambulanceFeatureKey = "ambulanceInfo";

const initialState = {
    loading: false,
    loading2: false,
    errorMsg: "",
    list: [],
    details: {}
};

export const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case ambulanceActions.AMBULANCE_LIST_REQUEST:
            return {
                ...state,
                loading: true
            }

        case ambulanceActions.AMBULANCE_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                list: payload.data
            }

        case ambulanceActions.AMBULANCE_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload,
                list: []
            }

        case ambulanceActions.AMBULANCE_CREATE_REQUEST:
            return {
                ...state,
                loading2: true
            }

        case ambulanceActions.AMBULANCE_CREATE_SUCCESS:
            const newList = [payload.data, ...state.list];
            return {
                ...state,
                loading2: false,
                list: newList
            }

        case ambulanceActions.AMBULANCE_CREATE_FAILURE:
            return {
                ...state,
                loading2: false,
                errorMsg: payload
            }

        case ambulanceActions.AMBULANCE_UPDATE_REQUEST:
            return {
                ...state,
                loading2: true
            }

        case ambulanceActions.AMBULANCE_UPDATE_SUCCESS:
            const newArray = state.list.map(item =>
                item._id === payload.data._id ? payload.data : item
            );
            return {
                ...state,
                loading2: false,
                list: newArray
            }

        case ambulanceActions.AMBULANCE_UPDATE_FAILURE:
            return {
                ...state,
                loading2: false,
                errorMsg: payload
            }

        case ambulanceActions.AMBULANCE_DELETE_REQUEST:
            return {
                ...state,
                loading2: true
            }

        case ambulanceActions.AMBULANCE_DELETE_SUCCESS:
            const filterArray = state.list.filter(item => item._id != payload);
            return {
                ...state,
                loading2: false,
                list: filterArray
            }

        case ambulanceActions.AMBULANCE_DELETE_FAILURE:
            return {
                ...state,
                loading2: false,
                errorMsg: payload
            }

        default: return state
    }
};