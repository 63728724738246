import { combineReducers } from "redux";
import * as authReducers from "./auth/auth.reducers";
import * as userReducers from "./user/user.reducers";
import * as vendorReducers from "./vendor/vendor.reducers";
import * as vetReducers from "./vet/vet.reducers";
import * as petReducers from "./pet/pet.reducers";
import * as blogReducers from "./blogs/blog.reducers";
import * as videoReducers from "./videos/video.reducers";
import * as productReducers from "./products/product.reducers";
import * as marketingNotificationReducers from "./marketingNotification/notification.reducer";
import * as pushNotificationReducers from "./pushNotification/notification.reducer";
import * as mailReducers from "./mail/mail.reducers";
import * as settingReducers from "./setting/setting.reducers";
import * as abuseReducers from "./abuse/abuse.reducers";
import * as staticReducers from "./staticPage/static.reducers";
import * as ambulanceReducers from "./ambulance/ambulance.reducers";
import * as govPetReducers from "./governmentPet/pet.reducers";
import * as bannerReducers from "./banners/banner.reducers";
import * as vetDropdownReducers from "./dropdowns/vet.reducer";
import * as orderReducers from "./orders/order.raducers";
import * as paymentReducers from "./payments/payment.raducers";
import * as healthCardReducers from "./healthCard/healthCard.reducers";
import * as homecareReducers from "./homecare/homecare.reducers";
import * as consulationReducers from "./consultation/consultation.reducers";
import * as vendorDropdownReducers from "./dropdowns/vendorDropdown.reducer";
import * as userDropdownReducers from "./dropdowns/userdropdown.reducer";
import * as productDropdownReducers from "./dropdowns/productDropdown.reducer";
import * as orderDropdownReducers from "./dropdowns/orderDropdown.reducer";
import * as abuseDropdownReducers from "./dropdowns/abuseDropdown.reducer";
import * as couponCodeReducers from "./couponCode/couponCode.reducer";
import * as subAdminReducers from "./subAdmin/subAdmin.reducers"

export const rootReducer = combineReducers({
  [authReducers.authFeatureKey]: authReducers.reducer,
  [userReducers.userFeatureKey]: userReducers.reducer,
  [vendorReducers.vendorFeatureKey]: vendorReducers.reducer,
  [vetReducers.vetFeatureKey]: vetReducers.reducer,
  [petReducers.petFeatureKey]: petReducers.reducer,
  [blogReducers.blogFeatureKey]: blogReducers.reducer,
  [videoReducers.videoFeatureKey]: videoReducers.reducer,
  [productReducers.productFeatureKey]: productReducers.reducer,
  [marketingNotificationReducers.notificationFeatureKey]:
    marketingNotificationReducers.reducer,
  [pushNotificationReducers.usersNotificationFeatureKey]:
    pushNotificationReducers.reducer,
  [mailReducers.mailFeatureKey]: mailReducers.reducer,
  [settingReducers.settingFeatureKey]: settingReducers.reducer,
  [abuseReducers.abuseFeatureKey]: abuseReducers.reducer,
  [staticReducers.staticFeatureKey]: staticReducers.reducer,
  [ambulanceReducers.ambulanceFeatureKey]: ambulanceReducers.reducer,
  [govPetReducers.govPetFeatureKey]: govPetReducers.reducer,
  [bannerReducers.bannerFeatureKey]: bannerReducers.reducer,
  [vetDropdownReducers.vetDropdownFeatureKey]: vetDropdownReducers.reducer,
  [orderReducers.orderFeatureKey]: orderReducers.reducer,
  [paymentReducers.paymentFeatureKey]: paymentReducers.reducer,
  [healthCardReducers.healthCardFeatureKey]: healthCardReducers.reducer,
  [homecareReducers.homecareFeatureKey]: homecareReducers.reducer,
  [consulationReducers.consultationFeatureKey]: consulationReducers.reducer,
  [vendorDropdownReducers.vendorDropdownFeatureKey]:
    vendorDropdownReducers.reducer,
  [userDropdownReducers.userDropdownFeatureKey]: userDropdownReducers.reducer,
  [productDropdownReducers.productDropdownFeatureKey]:
    productDropdownReducers.reducer,
  [orderDropdownReducers.orderDropdownFeatureKey]:
    orderDropdownReducers.reducer,
  [abuseDropdownReducers.abuseDropdownFeatureKey]:
    abuseDropdownReducers.reducer,
  [couponCodeReducers.couponFeatureKey]: couponCodeReducers.reducer,
  [subAdminReducers.subAdminFeatureKey]: subAdminReducers.reducer,
});
