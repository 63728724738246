import * as userDropdownAction from "./userDropdown.action";

export const userDropdownFeatureKey = "userDropdownInfo";

const initialState = {
  loading: false,
  errorMsg: "",
  list: [],
  category: [],
  details: {},
  isStatusUpdate: false,
};

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    // Cancel appointments create

    case userDropdownAction.CANCEL_APPOINTMENT_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
        isStatusUpdate: false,
      };

    case userDropdownAction.CANCEL_APPOINTMENT_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        isStatusUpdate: true,
      };

    case userDropdownAction.CANCEL_APPOINTMENT_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        list: [],
        isStatusUpdate: false,
      };

    //Cancel appointments update

    case userDropdownAction.CANCEL_APPOINTMENT_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
        isStatusUpdate: false,
      };

    case userDropdownAction.CANCEL_APPOINTMENT_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        isStatusUpdate: true,
      };

    case userDropdownAction.CANCEL_APPOINTMENT_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        list: [],
        isStatusUpdate: false,
      };

    // Cancel appointments list

    case userDropdownAction.CANCEL_APPOINTMENT_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case userDropdownAction.CANCEL_APPOINTMENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: payload.data,
      };

    case userDropdownAction.CANCEL_APPOINTMENT_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        list: [],
      };

    // Cancel appointments delete

    case userDropdownAction.CANCEL_APPOINTMENT_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
        isStatusUpdate: false,
      };

    case userDropdownAction.CANCEL_APPOINTMENT_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        list: payload.data,
        isStatusUpdate: true,
      };

    case userDropdownAction.CANCEL_APPOINTMENT_DELETE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        list: [],
        isStatusUpdate: false,
      };

    // pet category

    //create
    case userDropdownAction.BREED_OF_PETS_CATEGORY_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
        isStatusUpdate: false,
      };

    case userDropdownAction.BREED_OF_PETS_CATEGORY_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        isStatusUpdate: true,
      };

    case userDropdownAction.BREED_OF_PETS_CATEGORY_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        category: [],
        isStatusUpdate: false,
      };

    //BREED OF PETS update

    case userDropdownAction.BREED_OF_PETS_CATEGORY_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
        isStatusUpdate: false,
      };

    case userDropdownAction.BREED_OF_PETS_CATEGORY_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        isStatusUpdate: true,
      };

    case userDropdownAction.BREED_OF_PETS_CATEGORY_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        category: [],
        isStatusUpdate: false,
      };

    //list

    case userDropdownAction.BREED_OF_PETS_CATEGORY_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case userDropdownAction.BREED_OF_PETS_CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        category: payload.data,
      };

    case userDropdownAction.BREED_OF_PETS_CATEGORY_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        category: [],
      };

    //delete

    case userDropdownAction.BREED_OF_PETS_CATEGORY_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
        isStatusUpdate: false,
      };

    case userDropdownAction.BREED_OF_PETS_CATEGORY_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        category: payload.data,
        isStatusUpdate: true,
      };

    case userDropdownAction.BREED_OF_PETS_CATEGORY_DELETE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        category: [],
        isStatusUpdate: false,
      };

    // BREED OF PETS create

    case userDropdownAction.BREED_OF_PETS_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
        isStatusUpdate: false,
      };

    case userDropdownAction.BREED_OF_PETS_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        isStatusUpdate: true,
      };

    case userDropdownAction.BREED_OF_PETS_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        list: [],
        isStatusUpdate: false,
      };

    //BREED OF PETS update

    case userDropdownAction.BREED_OF_PETS_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
        isStatusUpdate: false,
      };

    case userDropdownAction.BREED_OF_PETS_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        isStatusUpdate: true,
      };

    case userDropdownAction.BREED_OF_PETS_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        list: [],
        isStatusUpdate: false,
      };

    // BREED OF PETS list

    case userDropdownAction.BREED_OF_PETS_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case userDropdownAction.BREED_OF_PETS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: payload.data,
      };

    case userDropdownAction.BREED_OF_PETS_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        list: [],
      };

    // BREED OF PETS delete

    case userDropdownAction.BREED_OF_PETS_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
        isStatusUpdate: false,
      };

    case userDropdownAction.BREED_OF_PETS_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        list: payload.data,
        isStatusUpdate: true,
      };

    case userDropdownAction.BREED_OF_PETS_DELETE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        list: [],
        isStatusUpdate: false,
      };

    // ALLERGY create

    case userDropdownAction.ALLERGY_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
        isStatusUpdate: false,
      };

    case userDropdownAction.ALLERGY_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        isStatusUpdate: true,
      };

    case userDropdownAction.ALLERGY_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        list: [],
        isStatusUpdate: false,
      };

    //ALLERGY update

    case userDropdownAction.ALLERGY_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
        isStatusUpdate: false,
      };

    case userDropdownAction.ALLERGY_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        isStatusUpdate: true,
      };

    case userDropdownAction.ALLERGY_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        list: [],
        isStatusUpdate: false,
      };

    // ALLERGY list

    case userDropdownAction.ALLERGY_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case userDropdownAction.ALLERGY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: payload.data,
      };

    case userDropdownAction.ALLERGY_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        list: [],
      };

    // ALLERGY delete

    case userDropdownAction.ALLERGY_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
        isStatusUpdate: false,
      };

    case userDropdownAction.ALLERGY_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        list: payload.data,
        isStatusUpdate: true,
      };

    case userDropdownAction.ALLERGY_DELETE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        list: [],
        isStatusUpdate: false,
      };
    default:
      return state;
  }
};
