import * as paymentActions from "./payment.actions";

export const paymentFeatureKey = "paymentInfo";

const initialState = {
  loading: false,
  errorMsg: "",
  list: [],
  details: {}
};

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case paymentActions.PAYMENT_LIST_REQUEST:
      return {
        ...state,
        loading: true
      }

    case paymentActions.PAYMENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: payload.data
      }

    case paymentActions.PAYMENT_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        list: []
      }

    case paymentActions.PAYMENT_DETAILS_REQUEST:
      return {
        ...state,
        loading: true
      }

    case paymentActions.PAYMENT_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        details: payload.data
      }

    case paymentActions.PAYMENT_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        details: {}
      }

    default: return state
  }
};