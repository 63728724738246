import * as orderActions from "./order.actions";

export const orderFeatureKey = "orderInfo";

const initialState = {
  loading: false,
  errorMsg: "",
  list: [],
  details: {}
};

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case orderActions.ORDER_LIST_REQUEST:
      return {
        ...state,
        loading: true
      }

    case orderActions.ORDER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: payload.data
      }

    case orderActions.ORDER_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        list: []
      }

    case orderActions.ORDER_DETAILS_REQUEST:
      return {
        ...state,
        loading: true
      }

    case orderActions.ORDER_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        details: payload.data
      }

    case orderActions.ORDER_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        details: {}
      }

    default: return state
  }
};