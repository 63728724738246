import * as mailActions from "./mail.actions";

export const mailFeatureKey = "mailInfo";

const initialState = {
    loading: false,
    errorMsg: "",
    list: []
};

export const reducer = (state = initialState, { payload, type }) => {
    switch (type) {
        case mailActions.EMAIL_LIST_REQUEST:
            return {
                ...state,
                loading: true
            }

        case mailActions.EMAIL_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                list: payload.data
            }

        case mailActions.EMAIL_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload,
                list: []
            }

        case mailActions.SEND_EMAIL_REQUEST:
            return {
                ...state,
                loading: true
            }

        case mailActions.SEND_EMAIL_SUCCESS:
            return {
                ...state,
                loading: false
            }

        case mailActions.SEND_EMAIL_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        default: return state;
    }
};