import * as subAdminActions from "./subAdmin.actions";

export const subAdminFeatureKey = "subAdminInfo";

const initialState = {
  loading: false,
  errorMsg: "",
  subAdmins: [],
  subAdminDetails: {},
};

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case subAdminActions.GET_SUB_ADMIN_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case subAdminActions.GET_SUB_ADMIN_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        subAdmins: payload.data,
      };

    case subAdminActions.GET_SUB_ADMIN_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        subAdmins: [],
      };

    // details

    case subAdminActions.GET_SUB_ADMIN_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case subAdminActions.GET_SUB_ADMIN_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        subAdminDetails: payload.data,
      };

    case subAdminActions.GET_SUB_ADMIN_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        subAdminDetails: {},
      };

    // create

    case subAdminActions.GET_SUB_ADMIN_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case subAdminActions.GET_SUB_ADMIN_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case subAdminActions.GET_SUB_ADMIN_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

      // update

      case subAdminActions.GET_SUB_ADMIN_UPDATE_REQUEST:
        return {
          ...state,
          loading: true,
        };
  
      case subAdminActions.GET_SUB_ADMIN_UPDATE_SUCCESS:
        return {
          ...state,
          loading: false,
        };
  
      case subAdminActions.GET_SUB_ADMIN_UPDATE_FAILURE:
        return {
          ...state,
          loading: false,
          errorMsg: payload,
        };

    // delete subAdmin

    case subAdminActions.GET_SUB_ADMIN_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case subAdminActions.GET_SUB_ADMIN_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case subAdminActions.GET_SUB_ADMIN_DELETE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    default:
      return state;
  }
};
