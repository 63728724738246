import axios from "axios";
import { toast } from "react-toastify";
import { errorHandling } from "../../helper";

export const EMAIL_LIST_REQUEST = "EMAIL_LIST_REQUEST";
export const EMAIL_LIST_SUCCESS = "EMAIL_LIST_SUCCESS";
export const EMAIL_LIST_FAILURE = "EMAIL_LIST_FAILURE";

export const SEND_EMAIL_REQUEST = "SEND_EMAIL_REQUEST";
export const SEND_EMAIL_SUCCESS = "SEND_EMAIL_SUCCESS";
export const SEND_EMAIL_FAILURE = "SEND_EMAIL_FAILURE";

export const emailList = (data) => {
    return async (dispatch) => {
        try {
            dispatch({ type: EMAIL_LIST_REQUEST });
            const url = `${process.env.REACT_APP_API_AWS_URL}/admin/get/all/email/for/admin/v1`;
            const response = await axios.post(url, data, {
                headers: {
                    Authorization: localStorage.getItem("token")
                }
            });
            dispatch({ type: EMAIL_LIST_SUCCESS, payload: response.data });
        } catch (error) {
            errorHandling(dispatch, EMAIL_LIST_FAILURE, error);
        }
    }
};

export const sendEmail = (data, navigate) => {
    return async (dispatch) => {
        try {
            dispatch({ type: SEND_EMAIL_REQUEST });
            const url = `${process.env.REACT_APP_API_AWS_URL}/admin/send/email/by/admin/v1`;
            const response = await axios.post(url, data, {
                headers: {
                    Authorization: localStorage.getItem("token")
                }
            });
            dispatch({ type: SEND_EMAIL_SUCCESS, payload: response.data });
            navigate(-1)
            toast(`Email send to all ${data.type} successfully...`);
        } catch (error) {
            errorHandling(dispatch, SEND_EMAIL_FAILURE, error);
        }
    }
};