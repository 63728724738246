import axios from "axios";
import { toast } from "react-toastify";
import { errorHandling } from "../../helper";

export const GET_ALL_BANNERS_REQUEST = 'GET_ALL_BANNERS_REQUEST';
export const GET_ALL_BANNERS_SUCCESS = 'GET_ALL_BANNERS_SUCCESS';
export const GET_ALL_BANNERS_FAILURE = 'GET_ALL_BANNERS_FAILURE';

export const CREATE_BANNER_REQUEST = 'CREATE_BANNER_REQUEST';
export const CREATE_BANNER_SUCCESS = 'CREATE_BANNER_SUCCESS';
export const CREATE_BANNER_FAILURE = 'CREATE_BANNER_FAILURE';

export const BANNER_DETAILS_REQUEST = 'BANNER_DETAILS_REQUEST';
export const BANNER_DETAILS_SUCCESS = 'BANNER_DETAILS_SUCCESS';
export const BANNER_DETAILS_FAILURE = 'BANNER_DETAILS_FAILURE';

export const BANNER_DELETE_REQUEST = 'BANNER_DELETE_REQUEST';
export const BANNER_DELETE_SUCCESS = 'BANNER_DELETE_SUCCESS';
export const BANNER_DELETE_FAILURE = 'BANNER_DELETE_FAILURE';

export const BANNER_UPDATE_REQUEST = 'BANNER_UPDATE_REQUEST';
export const BANNER_UPDATE_SUCCESS = 'BANNER_UPDATE_SUCCESS';
export const BANNER_UPDATE_FAILURE = 'BANNER_UPDATE_FAILURE';

export const getAllBanners = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_ALL_BANNERS_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/admin/get/all/banner/for/admin/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token")
        }
      });
      dispatch({ type: GET_ALL_BANNERS_SUCCESS, payload: response.data });
    } catch (error) {
      errorHandling(dispatch, GET_ALL_BANNERS_FAILURE, error);
    }
  }
};

export const createBanner = (data, setModalShow, setFormData) => {
  return async (dispatch) => {
    try {
      dispatch({ type: CREATE_BANNER_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/admin/create/banner/v1`;
      const response = await axios.post(url, data, {
        headers: {
          Authorization: localStorage.getItem("token")
        }
      });
      dispatch({ type: CREATE_BANNER_SUCCESS, payload: response.data });
      setModalShow(false);
      setFormData({
        headName: "",
        paragraph: "",
        btnAction: "",
        buttonName: "",
        bannerLogoImage: "",
        bgImage: "",
        app: "",
        category: ""
      });
    } catch (error) {
      errorHandling(dispatch, CREATE_BANNER_FAILURE, error);
    }
  }
};

export const bannerDetails = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: BANNER_DETAILS_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/admin/banner/details/${id}/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token")
        }
      });
      dispatch({ type: BANNER_DETAILS_SUCCESS, payload: response.data });
    } catch (error) {
      errorHandling(dispatch, BANNER_DETAILS_FAILURE, error);
    }
  }
};

export const deleteBanner = (id, setDeleteModal, setDeleteId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: BANNER_DELETE_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/admin/delete/banner/${id}/v1`;
      const response = await axios.delete(url, {
        headers: {
          Authorization: localStorage.getItem("token")
        }
      });
      dispatch({ type: BANNER_DELETE_SUCCESS, payload: id });
      setDeleteModal(false);
      setDeleteId("");
    } catch (error) {
      errorHandling(dispatch, BANNER_DELETE_FAILURE, error);
    }
  }
};

export const updateBanner = (id, data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: BANNER_UPDATE_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/admin/update/banner/${id}/v1`;
      const response = await axios.put(url, data, {
        headers: {
          Authorization: localStorage.getItem("token")
        }
      });
      dispatch({ type: BANNER_UPDATE_SUCCESS, payload: response.data });
      toast("Banner updated successfully");
    } catch (error) {
      errorHandling(dispatch, BANNER_UPDATE_FAILURE, error);
    }
  }
};