import * as orderDropdownAction from "./orderDropdown.action";

export const orderDropdownFeatureKey = "orderDropdownInfo";

const initialState = {
  loading: false,
  errorMsg: "",
  list: [],
  details: {},
  isStatusUpdate: false,
};

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    // USER_CANCELLATION create

    case orderDropdownAction.USER_CANCELLATION_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
        isStatusUpdate: false,
      };

    case orderDropdownAction.USER_CANCELLATION_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        isStatusUpdate: true,
      };

    case orderDropdownAction.USER_CANCELLATION_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        list: [],
        isStatusUpdate: false,
      };

    //USER_CANCELLATION update

    case orderDropdownAction.USER_CANCELLATION_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
        isStatusUpdate: false,
      };

    case orderDropdownAction.USER_CANCELLATION_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        isStatusUpdate: true,
      };

    case orderDropdownAction.USER_CANCELLATION_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        list: [],
        isStatusUpdate: false,
      };

    // USER_CANCELLATION list

    case orderDropdownAction.USER_CANCELLATION_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case orderDropdownAction.USER_CANCELLATION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: payload.data,
      };

    case orderDropdownAction.USER_CANCELLATION_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        list: [],
      };

    // USER_CANCELLATION delete

    case orderDropdownAction.USER_CANCELLATION_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
        isStatusUpdate: false,
      };

    case orderDropdownAction.USER_CANCELLATION_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        list: payload.data,
        isStatusUpdate: true,
      };

    case orderDropdownAction.USER_CANCELLATION_DELETE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        list: [],
        isStatusUpdate: false,
      };
    // VENDOR_CANCELLATION create

    case orderDropdownAction.VENDOR_CANCELLATION_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
        isStatusUpdate: false,
      };

    case orderDropdownAction.VENDOR_CANCELLATION_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        isStatusUpdate: true,
      };

    case orderDropdownAction.VENDOR_CANCELLATION_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        list: [],
        isStatusUpdate: false,
      };

    //VENDOR_CANCELLATION update

    case orderDropdownAction.VENDOR_CANCELLATION_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
        isStatusUpdate: false,
      };

    case orderDropdownAction.VENDOR_CANCELLATION_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        isStatusUpdate: true,
      };

    case orderDropdownAction.VENDOR_CANCELLATION_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        list: [],
        isStatusUpdate: false,
      };

    // VENDOR_CANCELLATION list

    case orderDropdownAction.VENDOR_CANCELLATION_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case orderDropdownAction.VENDOR_CANCELLATION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: payload.data,
      };

    case orderDropdownAction.VENDOR_CANCELLATION_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        list: [],
      };

    // VENDOR_CANCELLATION delete

    case orderDropdownAction.VENDOR_CANCELLATION_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
        isStatusUpdate: false,
      };

    case orderDropdownAction.VENDOR_CANCELLATION_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        list: payload.data,
        isStatusUpdate: true,
      };

    case orderDropdownAction.VENDOR_CANCELLATION_DELETE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        list: [],
        isStatusUpdate: false,
      };

    default:
      return state;
  }
};
