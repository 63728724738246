import * as videoActions from "./video.actions";

export const videoFeatureKey = "videoInfo";

const initialState = {
    loading: false,
    errorMsg: "",
    videos: [],
    videoDetails: {},
    isStatusChange: false
};

export const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case videoActions.VIDEO_LIST_REQUEST:
            return {
                ...state,
                loading: true
            }

        case videoActions.VIDEO_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                videos: payload.getVidios
            }

        case videoActions.VIDEO_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case videoActions.VIDEO_DETAIL_REQUEST:
            return {
                ...state,
                loading: true
            }

        case videoActions.VIDEO_DETAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                videoDetails: payload.data
            }

        case videoActions.VIDEO_DETAIL_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case videoActions.UPDATE_VIDEO_DETAIL_REQUEST:
            return {
                ...state,
                loading: true
            }

        case videoActions.UPDATE_VIDEO_DETAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                isStatusChange: true,
                videoDetails: payload.data
            }

        case videoActions.UPDATE_VIDEO_DETAIL_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case "VIDEO_STATUS_CHANGE":
            return {
                ...state,
                isStatusChange: false
            }
        default: return state
    }
};