import * as couponActions from "./couponCode.action";

export const couponFeatureKey = "couponInfo";

const initialState = {
  loading: false,
  errorMsg: "",
  list: [],
  details: {},
};

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case couponActions.GET_ALL_COUPON_CODE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case couponActions.GET_ALL_COUPON_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
        list: payload.data,
      };

    case couponActions.GET_ALL_COUPON_CODE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        list: [],
      };

    case couponActions.CREATE_COUPON_CODE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case couponActions.CREATE_COUPON_CODE_SUCCESS:
      const newList = [payload.data, ...state.list];
      return {
        ...state,
        loading: false,
        list: newList,
      };

    case couponActions.CREATE_COUPON_CODE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    case couponActions.COUPON_CODE_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case couponActions.COUPON_CODE_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        details: payload.data,
      };

    case couponActions.COUPON_CODE_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    case couponActions.COUPON_CODE_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case couponActions.COUPON_CODE_DELETE_SUCCESS:
      const filterArray = state.list.filter((item) => item._id != payload);
      return {
        ...state,
        loading: false,
        list: filterArray,
      };

    case couponActions.COUPON_CODE_DELETE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    case couponActions.COUPON_CODE_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case couponActions.COUPON_CODE_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        details: payload.data,
      };

    case couponActions.COUPON_CODE_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    default:
      return state;
  }
};
