import axios from "axios";
import { errorHandling } from "../../helper";
import { toast } from "react-toastify";

export const BLOG_LIST_REQUEST = 'BLOG_LIST_REQUEST';
export const BLOG_LIST_SUCCESS = 'BLOG_LIST_SUCCESS';
export const BLOG_LIST_FAILURE = 'BLOG_LIST_FAILURE';

export const BLOG_DETAIL_REQUEST = 'BLOG_DETAIL_REQUEST';
export const BLOG_DETAIL_SUCCESS = 'BLOG_DETAIL_SUCCESS';
export const BLOG_DETAIL_FAILURE = 'BLOG_DETAIL_FAILURE';

export const BLOG_UPDATE_REQUEST = 'BLOG_UPDATE_REQUEST';
export const BLOG_UPDATE_SUCCESS = 'BLOG_UPDATE_SUCCESS';
export const BLOG_UPDATE_FAILURE = 'BLOG_UPDATE_FAILURE';

export const blogList = (setIsLoading) => {
    return async (dispatch) => {
        try {
            setIsLoading(true);
            dispatch({ type: BLOG_LIST_REQUEST });
            const url = `${process.env.REACT_APP_API_AWS_URL}/admin/get/all/vet/blog/v1`;
            const response = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: BLOG_LIST_SUCCESS, payload: response.data });
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            errorHandling(dispatch, BLOG_LIST_FAILURE, error);
        }
    }
};

export const blogDetail = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: BLOG_DETAIL_REQUEST });
            const url = `${process.env.REACT_APP_API_AWS_URL}/admin/get/blog/${id}/details/v1`;
            const response = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: BLOG_DETAIL_SUCCESS, payload: response.data });
        } catch (error) {
            errorHandling(dispatch, BLOG_DETAIL_FAILURE, error);
        }
    }
};

export const blogUpdate = (id, data) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "BLOG_STATUS_CHANGE" });
            dispatch({ type: BLOG_UPDATE_REQUEST });
            const url = `${process.env.REACT_APP_API_AWS_URL}/admin/change/vet/blog/status/${id}/v1`;
            const response = await axios.put(url, data, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: BLOG_UPDATE_SUCCESS, payload: response.data });
            toast("Blog Updated successfully");
        } catch (error) {
            errorHandling(dispatch, BLOG_UPDATE_FAILURE, error);
        }
    }
};