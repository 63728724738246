import axios from "axios";
import { toast } from "react-toastify";
import { errorHandling } from "../../helper";

export const ORDER_LIST_REQUEST = 'ORDER_LIST_REQUEST';
export const ORDER_LIST_SUCCESS = 'ORDER_LIST_SUCCESS';
export const ORDER_LIST_FAILURE = 'ORDER_LIST_FAILURE';

export const ORDER_DETAILS_REQUEST = 'ORDER_DETAILS_REQUEST';
export const ORDER_DETAILS_SUCCESS = 'ORDER_DETAILS_SUCCESS';
export const ORDER_DETAILS_FAILURE = 'ORDER_DETAILS_FAILURE';

export const ordersList = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: ORDER_LIST_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/order/admin/get/all/order/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token")
        }
      });
      dispatch({ type: ORDER_LIST_SUCCESS, payload: response.data });
    } catch (error) {
      errorHandling(dispatch, ORDER_LIST_FAILURE, error);
    }
  }
};

export const ordersDetails = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ORDER_DETAILS_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/order/admin/get/single/order/${id}/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token")
        }
      });
      dispatch({ type: ORDER_DETAILS_SUCCESS, payload: response.data });
    } catch (error) {
      errorHandling(dispatch, ORDER_DETAILS_FAILURE, error);
    }
  }
};