import * as petActions from "./pet.actions";

export const govPetFeatureKey = "govPetInfo";

const initialState = {
    loading: false,
    errorMsg: "",
    list: [],
    details: {}
};

export const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case petActions.PET_LIST_REQUEST:
            return {
                ...state,
                loading: true
            }

        case petActions.PET_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                list: payload.data
            }

        case petActions.PET_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload,
                list: []
            }

        case petActions.PET_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            }

        case petActions.PET_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                details: payload.data
            }

        case petActions.PET_DETAILS_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload,
                details: {}
            }

        default: return state;
    }
};