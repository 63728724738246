import axios from "axios";
import { errorHandling } from "../../helper";
import { toast } from "react-toastify";

//USER CANCELLATION

export const USER_CANCELLATION_CREATE_REQUEST =
  "USER_CANCELLATION_CREATE_REQUEST";
export const USER_CANCELLATION_CREATE_SUCCESS =
  "USER_CANCELLATION_CREATE_SUCCESS";
export const USER_CANCELLATION_CREATE_FAILURE =
  "USER_CANCELLATION_CREATE_FAILURE";

export const USER_CANCELLATION_UPDATE_REQUEST =
  "USER_CANCELLATION_UPDATE_REQUEST";
export const USER_CANCELLATION_UPDATE_SUCCESS =
  "USER_CANCELLATION_UPDATE_SUCCESS";
export const USER_CANCELLATION_UPDATE_FAILURE =
  "USER_CANCELLATION_UPDATE_FAILURE";

export const USER_CANCELLATION_LIST_REQUEST = "USER_CANCELLATION_LIST_REQUEST";
export const USER_CANCELLATION_LIST_SUCCESS = "USER_CANCELLATION_LIST_SUCCESS";
export const USER_CANCELLATION_LIST_FAILURE = "USER_CANCELLATION_LIST_FAILURE";

export const USER_CANCELLATION_DELETE_REQUEST =
  "USER_CANCELLATION_DELETE_REQUEST";
export const USER_CANCELLATION_DELETE_SUCCESS =
  "USER_CANCELLATION_DELETE_SUCCESS";
export const USER_CANCELLATION_DELETE_FAILURE =
  "USER_CANCELLATION_DELETE_FAILURE";

//VENDOR CANCELLATION

export const VENDOR_CANCELLATION_CREATE_REQUEST =
  "VENDOR_CANCELLATION_CREATE_REQUEST";
export const VENDOR_CANCELLATION_CREATE_SUCCESS =
  "VENDOR_CANCELLATION_CREATE_SUCCESS";
export const VENDOR_CANCELLATION_CREATE_FAILURE =
  "VENDOR_CANCELLATION_CREATE_FAILURE";

export const VENDOR_CANCELLATION_UPDATE_REQUEST =
  "VENDOR_CANCELLATION_UPDATE_REQUEST";
export const VENDOR_CANCELLATION_UPDATE_SUCCESS =
  "VENDOR_CANCELLATION_UPDATE_SUCCESS";
export const VENDOR_CANCELLATION_UPDATE_FAILURE =
  "VENDOR_CANCELLATION_UPDATE_FAILURE";

export const VENDOR_CANCELLATION_LIST_REQUEST =
  "VENDOR_CANCELLATION_LIST_REQUEST";
export const VENDOR_CANCELLATION_LIST_SUCCESS =
  "VENDOR_CANCELLATION_LIST_SUCCESS";
export const VENDOR_CANCELLATION_LIST_FAILURE =
  "VENDOR_CANCELLATION_LIST_FAILURE";

export const VENDOR_CANCELLATION_DELETE_REQUEST =
  "VENDOR_CANCELLATION_DELETE_REQUEST";
export const VENDOR_CANCELLATION_DELETE_SUCCESS =
  "VENDOR_CANCELLATION_DELETE_SUCCESS";
export const VENDOR_CANCELLATION_DELETE_FAILURE =
  "VENDOR_CANCELLATION_DELETE_FAILURE";

// user cancellation create

export const createUserCancellationDropdown = (
  dropdown,
  setModalShow,
  setFormData
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: USER_CANCELLATION_CREATE_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/order/create/order/dropdown/name/v1`;
      const response = await axios.post(url, dropdown, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: USER_CANCELLATION_CREATE_SUCCESS,
        payload: response.data,
      });
      setModalShow(false);
      setFormData({});
    } catch (error) {
      errorHandling(dispatch, USER_CANCELLATION_CREATE_FAILURE, error);
      setModalShow(false);
      setFormData({});
    }
  };
};

// user cancellation update

export const updateUserCancellationDropdown = (
  id,
  dropdown,
  setUpdateModalShow,
  setFormData
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: USER_CANCELLATION_UPDATE_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/order/update/order/dropdown/${id}/name/v1`;
      const response = await axios.put(url, dropdown, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: USER_CANCELLATION_UPDATE_SUCCESS,
        payload: response.data,
      });
      setUpdateModalShow(false);
      setFormData({});
    } catch (error) {
      errorHandling(dispatch, USER_CANCELLATION_UPDATE_FAILURE, error);
      setUpdateModalShow(false);
      setFormData({});
    }
  };
};

// user cancellation list

export const userCancellationDropdownList = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: USER_CANCELLATION_LIST_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/order/get/all/order/dropdown/name/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: USER_CANCELLATION_LIST_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      errorHandling(dispatch, USER_CANCELLATION_LIST_FAILURE, error);
    }
  };
};

// user cancellation delete

export const deleteUserCancellationDropdown = (
  id,
  setDeleteModal,
  setFormData
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: USER_CANCELLATION_DELETE_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/order/delete/order/dropdown/${id}/name/v1`;
      const response = await axios.delete(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: USER_CANCELLATION_DELETE_SUCCESS,
        payload: response.data,
      });
      setDeleteModal(false);
      setFormData({});
    } catch (error) {
      errorHandling(dispatch, USER_CANCELLATION_DELETE_FAILURE, error);
      setDeleteModal(false);
      setFormData({});
    }
  };
};

// vendor cancellation create

export const createVendorCancellationDropdown = (
  dropdown,
  setModalShow,
  setFormData
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: VENDOR_CANCELLATION_CREATE_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/order/user/create/order/dropdown/name/v1`;
      const response = await axios.post(url, dropdown, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: VENDOR_CANCELLATION_CREATE_SUCCESS,
        payload: response.data,
      });
      setModalShow(false);
      setFormData({});
    } catch (error) {
      errorHandling(dispatch, VENDOR_CANCELLATION_CREATE_FAILURE, error);
      setModalShow(false);
      setFormData({});
    }
  };
};

// vendor cancellation update

export const updateVendorCancellationDropdown = (
  id,
  dropdown,
  setUpdateModalShow,
  setFormData
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: VENDOR_CANCELLATION_UPDATE_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/order/user/update/order/dropdown/${id}/name/v1`;
      const response = await axios.put(url, dropdown, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: VENDOR_CANCELLATION_UPDATE_SUCCESS,
        payload: response.data,
      });
      setUpdateModalShow(false);
      setFormData({});
    } catch (error) {
      errorHandling(dispatch, VENDOR_CANCELLATION_UPDATE_FAILURE, error);
      setUpdateModalShow(false);
      setFormData({});
    }
  };
};

// vendor cancellation list

export const vendorCancellationDropdownList = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: VENDOR_CANCELLATION_LIST_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/order/user/get/all/order/dropdown/name/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: VENDOR_CANCELLATION_LIST_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      errorHandling(dispatch, VENDOR_CANCELLATION_LIST_FAILURE, error);
    }
  };
};

// vendor cancellation delete

export const deleteVendorCancellationDropdown = (
  id,
  setDeleteModal,
  setFormData
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: VENDOR_CANCELLATION_DELETE_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/order/user/delete/order/dropdown/${id}/name/v1`;
      const response = await axios.delete(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: VENDOR_CANCELLATION_DELETE_SUCCESS,
        payload: response.data,
      });
      setDeleteModal(false);
      setFormData({});
    } catch (error) {
      errorHandling(dispatch, VENDOR_CANCELLATION_DELETE_FAILURE, error);
      setDeleteModal(false);
      setFormData({});
    }
  };
};
