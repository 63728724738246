import axios from "axios";
import { toast } from "react-toastify";
import { errorHandling } from "../../helper";

export const NOTIFICATION_LIST_REQUEST = 'NOTIFICATION_LIST_REQUEST';
export const NOTIFICATION_LIST_SUCCESS = 'NOTIFICATION_LIST_SUCCESS';
export const NOTIFICATION_LIST_FAILURE = 'NOTIFICATION_LIST_FAILURE';

export const SEND_NOTIFICATION_REQUEST = 'SEND_NOTIFICATION_REQUEST';
export const SEND_NOTIFICATION_SUCCESS = 'SEND_NOTIFICATION_SUCCESS';
export const SEND_NOTIFICATION_FAILURE = 'SEND_NOTIFICATION_FAILURE';

export const RE_SEND_NOTIFICATION_REQUEST = 'RE_SEND_NOTIFICATION_REQUEST';
export const RE_SEND_NOTIFICATION_SUCCESS = 'RE_SEND_NOTIFICATION_SUCCESS';
export const RE_SEND_NOTIFICATION_FAILURE = 'RE_SEND_NOTIFICATION_FAILURE';

export const notificationList = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: NOTIFICATION_LIST_REQUEST });
            const url = `${process.env.REACT_APP_API_AWS_URL}/fcm/get/all/marketing/noti/v1`;
            const response = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: NOTIFICATION_LIST_SUCCESS, payload: response.data });
        } catch (error) {
            errorHandling(dispatch, NOTIFICATION_LIST_FAILURE, error);
        }
    }
};

export const sendNotification = (data, navigate) => {
    return async (dispatch) => {
        try {
            dispatch({ type: SEND_NOTIFICATION_REQUEST });
            const url = `${process.env.REACT_APP_API_AWS_URL}/fcm/send/marketing/noti/to/all/v1`;
            const response = await axios.post(url, data, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: SEND_NOTIFICATION_SUCCESS, payload: response.data });
            navigate(-1);
            toast("Notification sent");
        } catch (error) {
            errorHandling(dispatch, SEND_NOTIFICATION_FAILURE, error);
        }
    }
};

export const reSendNotification = (id, setNotificationId, setModalShow) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "IS_STATUS_CHANGE" });
            dispatch({ type: RE_SEND_NOTIFICATION_REQUEST });
            const url = `${process.env.REACT_APP_API_AWS_URL}/fcm/re/send/marketing/noti/to/all/${id}/v1`;
            const response = await axios.put(url, {}, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: RE_SEND_NOTIFICATION_SUCCESS, payload: response.data });
            toast("Notification sent");
            setModalShow(false);
            setNotificationId(null);
        } catch (error) {
            errorHandling(dispatch, RE_SEND_NOTIFICATION_FAILURE, error);
        }
    }
};