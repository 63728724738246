import React, { useEffect, useMemo, useState } from "react";
import ViewMoreBtn from "../../components/ViewMoreBtn";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { arrowRight } from "../../../../assets/svg/Svg";
import { FiEdit } from "react-icons/fi";
import { LuFilter } from "react-icons/lu";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteSubAdmin,
  getAllSubAdminsList,
} from "../../../../redux/subAdmin/subAdmin.actions";
import { Trash } from "feather-icons-react/build/IconComponents";
import DeleteModal from "../../components/DeleteModal";

const SubAdmin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [subAdminId, setSubAdminId] = useState("");
  const [category, setCategory] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [data, setData] = useState([]);
  const { subAdmins } = useSelector((state) => state.subAdminInfo);

  useEffect(() => {
    dispatch(getAllSubAdminsList());
  }, [dispatch]);

  useEffect(() => {
    if (subAdmins && subAdmins.length > 0) {
      setData(subAdmins);
    } else {
      setData([]);
    }
  }, [subAdmins]);

  const columns = [
    {
      name: "Created On",
      selector: (row) =>
        new Date(row.createdAt)
          .toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          })
          .replace(/\//g, "-"),
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => (
        <label className={`badge badge-${row.active ? "success" : "danger"}`}>
          {row.active ? "Active" : "InActive"}
        </label>
      ),
      sortable: true,
    },
    {
      name: "Actions",
      selector: (row) => (
        <div className="d-flex flex-wrap align-items-center">
          <FiEdit
            size={16}
            className="action-icon me-2 mb-2"
            onClick={() => navigate(`/sub-admin/${row?._id}/update`)}
          />
          <Trash
            size={16}
            className="action-icon me-2 mb-2"
            onClick={() => {
              setSubAdminId(row?._id);
              setDeleteModal(true);
            }}
          />
          <ViewMoreBtn
            redirectUrl={`/sub-admin/${row?._id}/update`}
            className="action-icon mb-2"
          />
        </div>
      ),
    },
  ];

  const filteredData = useMemo(() => {
    return data.filter((item) => {
      const matchesSearchText = item.name
        .toLowerCase()
        .includes(searchText.toLowerCase());

      const matchesStatus =
        category === "Active" ? true : category === "Inactive" ? false : true;

      return matchesSearchText && matchesStatus;
    });
  }, [data, searchText, category]);

  const handleSearch = (value) => {
    setSearchText(value);
    setResetPaginationToggle(!resetPaginationToggle);
  };

  const handleCategoryChange = (value) => {
    setCategory(value);
    setResetPaginationToggle(!resetPaginationToggle);
  };

  const handlePerform = async () => {
    await dispatch(deleteSubAdmin(subAdminId, setDeleteModal));
    await dispatch(getAllSubAdminsList());
  };

  const paginationOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
    noRowsPerPage: false,
    selectAllRowsItem: false,
    selectAllRowsItemText: "All",
  };

  const customPagination = () => {
    const page = Math.ceil(filteredData.length / paginationPerPage);
    const pageButtons = [];
    const startPage = Math.max(currentPage - 1, 1);
    const endPage = Math.min(currentPage + 1, page);

    // Always add the first page
    pageButtons.push(
      <button
        key={1}
        className={`btn-pagination ${currentPage === 1 ? "active" : ""}`}
        onClick={() => setCurrentPage(1)}
      >
        1
      </button>
    );

    // Ellipsis after the first page if necessary
    if (startPage > 2) {
      pageButtons.push(
        <span key="ellipsis-start" className="pagination-ellipsis">
          &hellip;
        </span>
      );
    }

    // Range of buttons around the current page
    for (let i = startPage; i <= endPage; i++) {
      if (i !== 1 && i !== page) {
        pageButtons.push(
          <button
            key={i}
            className={`btn-pagination ${currentPage === i ? "active" : ""}`}
            onClick={() => setCurrentPage(i)}
          >
            {i}
          </button>
        );
      }
    }

    // Ellipsis before the last page if necessary
    if (endPage < page - 1) {
      pageButtons.push(
        <span key="ellipsis-end" className="pagination-ellipsis">
          &hellip;
        </span>
      );
    }

    // Always add the last page
    if (page !== 1) {
      pageButtons.push(
        <button
          key={page}
          className={`btn-pagination ${currentPage === page ? "active" : ""}`}
          onClick={() => setCurrentPage(page)}
        >
          {page}
        </button>
      );
    }

    return (
      <div className="pagination-container mt-5">
        <button
          className="btn-pagination btn-pagi_prev"
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          {arrowRight}
        </button>
        <span>{pageButtons}</span>
        <button
          className="btn-pagination btn-pagi_next"
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage === page}
        >
          {arrowRight}
        </button>
      </div>
    );
  };

  const paginationPerPage = 10;
  const [currentPage, setCurrentPage] = React.useState(1);

  const paginatedData = filteredData.slice(
    (currentPage - 1) * paginationPerPage,
    currentPage * paginationPerPage
  );

  return (
    <>
      {isLoading ? (
        <div className="w-100 d-flex">
          <div
            className="spinner-border text-primary mx-auto"
            role="status"
          ></div>
        </div>
      ) : (
        <>
          <div>
            <div className="page-header">
              <h3 className="page-title">Sub-Admins</h3>
              <Col md={4} className="d-flex align-items-end">
                <button
                  className="btn_primary_outline_sm ms-auto"
                  onClick={() => navigate("/sub-admin/create")}
                >
                  Add New Sub-Admin
                </button>
              </Col>
            </div>
            <div className="row">
              <div className="col-lg-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body py-4">
                    <>
                      {/* <button onClick={handleClearRows}>Clear Selected Rows</button> */}
                      <DataTable
                        // title={<h6 className="card-title">Users List</h6>}
                        columns={columns}
                        data={paginatedData}
                        pagination
                        paginationServer
                        paginationTotalRows={filteredData.length}
                        onChangePage={(page) => setCurrentPage(page)}
                        paginationComponentOptions={paginationOptions}
                        paginationComponent={customPagination}
                        highlightOnHover
                        pointerOnHover
                        subHeader
                        subHeaderAlign="left"
                        subHeaderComponent={
                          <>
                            <Row className="mb-3">
                              <Col
                                md={8}
                                className="d-flex align-items-start flex-column gap-2"
                              >
                                <span className="d-flex align-items-center gap-2 me-3 filterby_label">
                                  <LuFilter />
                                  Filter By :
                                </span>
                                <div className="table_filter">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search by name"
                                    value={searchText}
                                    onChange={(e) =>
                                      handleSearch(e.target.value)
                                    }
                                  />
                                  <select
                                    className="form-control h-54px w-auto"
                                    value={category}
                                    onChange={(e) =>
                                      handleCategoryChange(e.target.value)
                                    }
                                  >
                                    <option selected value="">
                                      Status
                                    </option>
                                    <option value="Active">Active</option>
                                    <option value="Inactive">Inactive</option>
                                  </select>
                                </div>
                              </Col>
                              {/* <Col md={4} className="d-flex align-items-end">
                                <button className="btn_primary_outline_sm ms-auto">
                                  Export CSV
                                </button>
                              </Col> */}
                            </Row>
                          </>
                        }
                      />
                    </>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <DeleteModal
        forSubAdmins
        deleteModal={deleteModal}
        setDeleteModal={setDeleteModal}
        handlePerform={handlePerform}
      />
    </>
  );
};

export default SubAdmin;
