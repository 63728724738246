import React, { Suspense, lazy, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import Spinner from "../app/views/shared/Spinner";
import SubAdminCreate from "./views/pages/sub-admin/SubAdminCreate";
import Subdmin from "./views/pages/sub-admin/SubAdmin";
import Banners from "./views/pages/banner/Banners";
import BannerDetail from "./views/pages/banner/BannerDetail";
import Mail from "./views/pages/mail/Mail";
import SendEmail from "./views/pages/mail/SendEmail";
// import MailAdmin from "./views/pages/mail/MailAdmin";
// import MailUser from "./views/pages/mail/MailUser";
// import MailVendor from "./views/pages/mail/MailVendor";
// import MailVet from "./views/pages/mail/MailVet";
import PushNotification from "./views/pages/push-notification/PushNotification";
import PushNotificationSend from "./views/pages/push-notification/PushNotificationSend";
import UsersPushNotification from "./views/pages/usersPushNotification/PushNotification";
import UsersPushNotificationSend from "./views/pages/usersPushNotification/PushNotificationSend";
import PetDetail from "./views/pages/pets/PetDetail";
import HomePath from "./views/components/HomePath";
import PetCategories from "./views/pages/dropdowns/user/PetCategories.js";
import UserCancellation from "./views/pages/dropdowns/order/UserCancellation.js";
import VendorCancellation from "./views/pages/dropdowns/order/VendorCancellation.js";
import CouponCode from "./views/pages/couponCode/CouponCode.js";
import CouponCodeDetails from "./views/pages/couponCode/CouponCodeDetails.js";
import SubAdminUpdate from "./views/pages/sub-admin/SubAdminUpdate.js";
import { useSelector } from "react-redux";
import Policies from "./views/pages/policies/Policies.js";
import VetVendorPolicies from "./views/pages/policies/VetVendorPolicies.js";
import UserPolicies from "./views/pages/policies/UserPolicies.js";
import UserTerms from "./views/pages/policies/UserTerms.js";
import UserSupport from "./views/pages/policies/UserSupport.js";
import UserPrivacy from "./views/pages/policies/UserPrivacy.js";
import UserCancellationPolicy from "./views/pages/policies/UserCancellationPolicy.js";
import VetTerms from "./views/pages/policies/VetTerms.js";
import VetSupport from "./views/pages/policies/VetSupport.js";
import VetPrivacy from "./views/pages/policies/VetPrivacy.js";

const Dashboard = lazy(() => import("./views/pages/dashboard/Dashboard"));
const Profile = lazy(() => import("./views/pages/Profile"));
const Error404 = lazy(() => import("./views/pages/error/Error404"));
const Error500 = lazy(() => import("./views/pages/error/Error500"));
const Auth = lazy(() => import("./views/pages/auth/Auth"));
const Users = lazy(() => import("./views/pages/user/Users"));
const UserDetail = lazy(() => import("./views/pages/user/UserDetail"));
const Vendors = lazy(() => import("./views/pages/vendor/Vendors"));
const VendorDetail = lazy(() => import("./views/pages/vendor/VendorDetail"));
const Products = lazy(() => import("./views/pages/products/Products"));
const ProductDetail = lazy(() =>
  import("./views/pages/products/ProductDetail")
);
const Vets = lazy(() => import("./views/pages/vets/Vets"));
const VetDetail = lazy(() => import("./views/pages/vets/VetDetail"));
const Pets = lazy(() => import("./views/pages/pets/Pets"));
const Blogs = lazy(() => import("./views/pages/blogs/Blogs"));
const BlogDetail = lazy(() => import("./views/pages/blogs/BlogDetail"));
const Videos = lazy(() => import("./views/pages/videos/Videos"));
const VideoDetail = lazy(() => import("./views/pages/videos/VideoDetail"));
const ReportAbuseList = lazy(() =>
  import("./views/pages/report-abuse/ReportAbuseList")
);
const ReportAbuseDetail = lazy(() =>
  import("./views/pages/report-abuse/ReportAbuseDetail")
);

const Vet = lazy(() => import("./views/pages/dropdowns/vet/Vet"));
const ClinicalTreatment = lazy(() =>
  import("./views/pages/dropdowns/vet/ClinicalTreatment")
);
const PetHealthConcern = lazy(() =>
  import("./views/pages/dropdowns/vet/PetHealthConcern")
);
const SpecialityCertificates = lazy(() =>
  import("./views/pages/dropdowns/vet/SpecialityCertificates")
);
const TreatmentHome = lazy(() =>
  import("./views/pages/dropdowns/vet/TreatmentHome")
);
const BloodtestType = lazy(() =>
  import("./views/pages/dropdowns/vet/BloodtestType")
);
const VaccinationType = lazy(() =>
  import("./views/pages/dropdowns/vet/VaccinationType")
);
const Degree = lazy(() => import("./views/pages/dropdowns/vet/Degree"));
const SubDegree = lazy(() => import("./views/pages/dropdowns/vet/SubDegree"));

const Vendor = lazy(() => import("./views/pages/dropdowns/vendor/Vendor"));
const DistanceRange = lazy(() =>
  import("./views/pages/dropdowns/vendor/DistanceRange")
);
const BusinessType = lazy(() =>
  import("./views/pages/dropdowns/vendor/BusinessType")
);

const User = lazy(() => import("./views/pages/dropdowns/user/User"));
const CancelAppointment = lazy(() =>
  import("./views/pages/dropdowns/user/CancelAppointment")
);
const BreedPet = lazy(() => import("./views/pages/dropdowns/user/BreedPet"));
const Allergy = lazy(() => import("./views/pages/dropdowns/user/Allergy"));

const Product = lazy(() => import("./views/pages/dropdowns/product/Product"));
const Brand = lazy(() => import("./views/pages/dropdowns/product/Brand"));
const Category = lazy(() => import("./views/pages/dropdowns/product/Category"));
const SubCategory = lazy(() =>
  import("./views/pages/dropdowns/product/SubCategory")
);
const HealthConcern = lazy(() =>
  import("./views/pages/dropdowns/product/HealthConcern")
);
const UnitType = lazy(() => import("./views/pages/dropdowns/product/UnitType"));
const UnitName = lazy(() => import("./views/pages/dropdowns/product/UnitName"));

const Order = lazy(() => import("./views/pages/dropdowns/order/Order"));

const ReportAbuse = lazy(() =>
  import("./views/pages/dropdowns/report-abuse/ReportAbuse")
);
const AppSetting = lazy(() => import("./views/pages/AppSetting"));
const UploadDocs = lazy(() => import("./views/components/UploadDocs.js"));
const AboutUs = lazy(() => import("./views/pages/aboutus/AboutUs.js"));
const ContactUs = lazy(() => import("./views/pages/contactus/ContactUs.js"));
const Ambulance = lazy(() => import("./views/pages/ambulance/Ambulance.js"));
const GovernmentPet = lazy(() =>
  import("./views/pages/governmentPet/GovernmentPet.js")
);
const GovernmentPetDetails = lazy(() =>
  import("./views/pages/governmentPet/GovernmentPetDetails.js")
);
const Orders = lazy(() => import("./views/pages/orders/Orders.js"));
const OrderDetails = lazy(() => import("./views/pages/orders/OrderDetails.js"));
const Payment = lazy(() => import("./views/pages/payment/Payment.js"));
const PaymentDetails = lazy(() =>
  import("./views/pages/payment/PaymentDetails.js")
);
const EHealthCard = lazy(() =>
  import("./views/pages/ehealthcard/EHealthCard.js")
);
const EHealthCardDetails = lazy(() =>
  import("./views/pages/ehealthcard/EHealthCardDetails.js")
);
const Homecare = lazy(() => import("./views/pages/homecare/Homecare.js"));
const HomecareDetails = lazy(() =>
  import("./views/pages/homecare/HomecareDetails.js")
);
const Consultations = lazy(() =>
  import("./views/pages/consultation/Consultation.js")
);
const ConsultationDetails = lazy(() =>
  import("./views/pages/consultation/ConsultationDetails.js")
);

const ProtectedRoute = ({
  children,
  redirectPath = "/login",
  accessCondition,
}) => {
  const isAuthenticated = localStorage.getItem("token");
  const { profile, loading } = useSelector((state) => state.authInfo);
  const isProfileLoaded = Object.keys(profile).length > 0;

  if (!isAuthenticated) {
    return <Navigate to={redirectPath} replace />;
  }

  if (!isProfileLoaded) {
    return null; // or a loading spinner
  }

  // Check access conditions
  if (accessCondition && !accessCondition(profile)) {
    return <Navigate to="/404" replace />;
  }

  // If authenticated and access condition is met
  return children;
};

const AppRoutes = () => {
  return (
    <Suspense fallback={<Spinner />}>
      <Routes>
        <Route path="/login" element={<Auth />} />{" "}
        <Route
          path="/change_password"
          element={
            <ProtectedRoute>
              <Auth />
            </ProtectedRoute>
          }
        />
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <HomePath />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/users"
          element={
            <ProtectedRoute
              accessCondition={(profile) =>
                profile?.role === "admin" || profile?.usersAccess?.canRead
              }
            >
              <Users />
            </ProtectedRoute>
          }
        />
        <Route
          path="/user/:id"
          element={
            <ProtectedRoute
              accessCondition={(profile) =>
                profile?.role === "admin" || profile?.usersAccess?.canRead
              }
            >
              <UserDetail />
            </ProtectedRoute>
          }
        />
        <Route
          path="/sub-admins"
          element={
            <ProtectedRoute
              accessCondition={(profile) => profile?.role === "admin"}
            >
              <Subdmin />
            </ProtectedRoute>
          }
        />
        <Route
          path="/sub-admin/create"
          element={
            <ProtectedRoute
              accessCondition={(profile) => profile?.role === "admin"}
            >
              <SubAdminCreate />
            </ProtectedRoute>
          }
        />
        <Route
          path="/sub-admin/:id/update"
          element={
            <ProtectedRoute
              accessCondition={(profile) => profile?.role === "admin"}
            >
              <SubAdminUpdate />
            </ProtectedRoute>
          }
        />
        <Route
          path="/banners"
          element={
            <ProtectedRoute
              accessCondition={(profile) =>
                profile?.role === "admin" || profile?.bannersAccess?.canRead
              }
            >
              <Banners />
            </ProtectedRoute>
          }
        />
        <Route
          path="/banner/:id"
          element={
            <ProtectedRoute
              accessCondition={(profile) =>
                profile?.role === "admin" || profile?.bannersAccess?.canRead
              }
            >
              <BannerDetail />
            </ProtectedRoute>
          }
        />
        <Route
          path="/vendors"
          element={
            <ProtectedRoute
              accessCondition={(profile) =>
                profile?.role === "admin" || profile?.vendorsAccess?.canRead
              }
            >
              <Vendors />
            </ProtectedRoute>
          }
        />
        <Route
          path="/vendor/:id"
          element={
            <ProtectedRoute
              accessCondition={(profile) =>
                profile?.role === "admin" || profile?.vendorsAccess?.canRead
              }
            >
              <VendorDetail />
            </ProtectedRoute>
          }
        />
        <Route
          path="/products"
          element={
            <ProtectedRoute
              accessCondition={(profile) =>
                profile?.role === "admin" || profile?.productsAccess?.canRead
              }
            >
              <Products />
            </ProtectedRoute>
          }
        />
        <Route
          path="/product/:id"
          element={
            <ProtectedRoute
              accessCondition={(profile) =>
                profile?.role === "admin" || profile?.productsAccess?.canRead
              }
            >
              <ProductDetail />
            </ProtectedRoute>
          }
        />
        <Route
          path="/vets"
          element={
            <ProtectedRoute
              accessCondition={(profile) =>
                profile?.role === "admin" || profile?.vetsAccess?.canRead
              }
            >
              <Vets />
            </ProtectedRoute>
          }
        />
        <Route
          path="/vet/:id"
          element={
            <ProtectedRoute
              accessCondition={(profile) =>
                profile?.role === "admin" || profile?.vetsAccess?.canRead
              }
            >
              <VetDetail />
            </ProtectedRoute>
          }
        />
        <Route
          path="/pets"
          element={
            <ProtectedRoute
              accessCondition={(profile) =>
                profile?.role === "admin" || profile?.petsAccess?.canRead
              }
            >
              <Pets />
            </ProtectedRoute>
          }
        />
        <Route
          path="/pet/:id"
          element={
            <ProtectedRoute
              accessCondition={(profile) =>
                profile?.role === "admin" || profile?.petsAccess?.canRead
              }
            >
              <PetDetail />
            </ProtectedRoute>
          }
        />
        <Route
          path="/blogs"
          element={
            <ProtectedRoute
              accessCondition={(profile) =>
                profile?.role === "admin" || profile?.blogsAccess?.canRead
              }
            >
              <Blogs />
            </ProtectedRoute>
          }
        />
        <Route
          path="/blog/:id"
          element={
            <ProtectedRoute
              accessCondition={(profile) =>
                profile?.role === "admin" || profile?.blogsAccess?.canRead
              }
            >
              <BlogDetail />
            </ProtectedRoute>
          }
        />
        <Route
          path="/videos"
          element={
            <ProtectedRoute
              accessCondition={(profile) =>
                profile?.role === "admin" || profile?.videosAccess?.canRead
              }
            >
              <Videos />
            </ProtectedRoute>
          }
        />
        <Route
          path="/video/:id"
          element={
            <ProtectedRoute
              accessCondition={(profile) =>
                profile?.role === "admin" || profile?.videosAccess?.canRead
              }
            >
              <VideoDetail />
            </ProtectedRoute>
          }
        />
        <Route
          path="/aboutus"
          element={
            <ProtectedRoute
              accessCondition={(profile) =>
                profile?.role === "admin" || profile?.aboutusAccess?.canRead
              }
            >
              <AboutUs />
            </ProtectedRoute>
          }
        />
        <Route
          path="/contactus"
          element={
            <ProtectedRoute
              accessCondition={(profile) =>
                profile?.role === "admin" || profile?.contactusAccess?.canRead
              }
            >
              <ContactUs />
            </ProtectedRoute>
          }
        />
        <Route
          path="/ambulance"
          element={
            <ProtectedRoute
              accessCondition={(profile) =>
                profile?.role === "admin" || profile?.ambulanceAccess?.canRead
              }
            >
              <Ambulance />
            </ProtectedRoute>
          }
        />
        <Route
          path="/government-pet"
          element={
            <ProtectedRoute
              accessCondition={(profile) =>
                profile?.role === "admin" ||
                profile?.governmentpetsAccess?.canRead
              }
            >
              <GovernmentPet />
            </ProtectedRoute>
          }
        />
        <Route
          path="/government-pet/:id"
          element={
            <ProtectedRoute
              accessCondition={(profile) =>
                profile?.role === "admin" ||
                profile?.governmentpetsAccess?.canRead
              }
            >
              <GovernmentPetDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path="/orders"
          element={
            <ProtectedRoute
              accessCondition={(profile) =>
                profile?.role === "admin" || profile?.ordersAccess?.canRead
              }
            >
              <Orders />
            </ProtectedRoute>
          }
        />
        <Route
          path="/order/:id"
          element={
            <ProtectedRoute
              accessCondition={(profile) =>
                profile?.role === "admin" || profile?.ordersAccess?.canRead
              }
            >
              <OrderDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path="/payments"
          element={
            <ProtectedRoute
              accessCondition={(profile) =>
                profile?.role === "admin" || profile?.paymentsAccess?.canRead
              }
            >
              <Payment />
            </ProtectedRoute>
          }
        />
        <Route
          path="/payment/:id"
          element={
            <ProtectedRoute
              accessCondition={(profile) =>
                profile?.role === "admin" || profile?.paymentsAccess?.canRead
              }
            >
              <PaymentDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path="/health-cards"
          element={
            <ProtectedRoute
              accessCondition={(profile) =>
                profile?.role === "admin" || profile?.ehealthcardAccess?.canRead
              }
            >
              <EHealthCard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/health-card/:id"
          element={
            <ProtectedRoute
              accessCondition={(profile) =>
                profile?.role === "admin" || profile?.ehealthcardAccess?.canRead
              }
            >
              <EHealthCardDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path="/homecares"
          element={
            <ProtectedRoute
              accessCondition={(profile) =>
                profile?.role === "admin" || profile?.homecareAccess?.canRead
              }
            >
              <Homecare />
            </ProtectedRoute>
          }
        />
        <Route
          path="/homecare/:id"
          element={
            <ProtectedRoute
              accessCondition={(profile) =>
                profile?.role === "admin" || profile?.homecareAccess?.canRead
              }
            >
              <HomecareDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path="/consultations"
          element={
            <ProtectedRoute
              accessCondition={(profile) =>
                profile?.role === "admin" ||
                profile?.consultationsAccess?.canRead
              }
            >
              <Consultations />
            </ProtectedRoute>
          }
        />
        <Route
          path="/consultation/:id"
          element={
            <ProtectedRoute
              accessCondition={(profile) =>
                profile?.role === "admin" ||
                profile?.consultationsAccess?.canRead
              }
            >
              <ConsultationDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path="/report-abuse"
          element={
            <ProtectedRoute
              accessCondition={(profile) =>
                profile?.role === "admin" || profile?.reportabuseAccess?.canRead
              }
            >
              <ReportAbuseList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/report-abuse/:id"
          element={
            <ProtectedRoute
              accessCondition={(profile) =>
                profile?.role === "admin" || profile?.reportabuseAccess?.canRead
              }
            >
              <ReportAbuseDetail />
            </ProtectedRoute>
          }
        />
        <Route
          path="/vet-field"
          element={
            <ProtectedRoute
              accessCondition={(profile) =>
                profile?.role === "admin" || profile?.dropdownsAccess?.canRead
              }
            >
              <Vet />
            </ProtectedRoute>
          }
        />
        <Route
          path="/vet-field/clinical-treatments"
          element={
            <ProtectedRoute
              accessCondition={(profile) =>
                profile?.role === "admin" || profile?.dropdownsAccess?.canRead
              }
            >
              <ClinicalTreatment />
            </ProtectedRoute>
          }
        />
        <Route
          path="/vet-field/pet-health-concern"
          element={
            <ProtectedRoute
              accessCondition={(profile) =>
                profile?.role === "admin" || profile?.dropdownsAccess?.canRead
              }
            >
              <PetHealthConcern />
            </ProtectedRoute>
          }
        />
        <Route
          path="/vet-field/speciality-certificates"
          element={
            <ProtectedRoute
              accessCondition={(profile) =>
                profile?.role === "admin" || profile?.dropdownsAccess?.canRead
              }
            >
              <SpecialityCertificates />
            </ProtectedRoute>
          }
        />
        <Route
          path="/vet-field/treatments-offer-at-home"
          element={
            <ProtectedRoute
              accessCondition={(profile) =>
                profile?.role === "admin" || profile?.dropdownsAccess?.canRead
              }
            >
              <TreatmentHome />
            </ProtectedRoute>
          }
        />
        <Route
          path="/vet-field/bloodtest-type"
          element={
            <ProtectedRoute
              accessCondition={(profile) =>
                profile?.role === "admin" || profile?.dropdownsAccess?.canRead
              }
            >
              <BloodtestType />
            </ProtectedRoute>
          }
        />
        <Route
          path="/vet-field/vaccination-types"
          element={
            <ProtectedRoute
              accessCondition={(profile) =>
                profile?.role === "admin" || profile?.dropdownsAccess?.canRead
              }
            >
              <VaccinationType />
            </ProtectedRoute>
          }
        />
        <Route
          path="/vet-field/degree-type"
          element={
            <ProtectedRoute
              accessCondition={(profile) =>
                profile?.role === "admin" || profile?.dropdownsAccess?.canRead
              }
            >
              <Degree />
            </ProtectedRoute>
          }
        />
        <Route
          path="/vet-field/degree-name"
          element={
            <ProtectedRoute
              accessCondition={(profile) =>
                profile?.role === "admin" || profile?.dropdownsAccess?.canRead
              }
            >
              <SubDegree />
            </ProtectedRoute>
          }
        />
        <Route
          path="/vendor-field"
          element={
            <ProtectedRoute
              accessCondition={(profile) =>
                profile?.role === "admin" || profile?.dropdownsAccess?.canRead
              }
            >
              <Vendor />
            </ProtectedRoute>
          }
        />
        <Route
          path="/vendor-field/delivery-distance-range"
          element={
            <ProtectedRoute
              accessCondition={(profile) =>
                profile?.role === "admin" || profile?.dropdownsAccess?.canRead
              }
            >
              <DistanceRange />
            </ProtectedRoute>
          }
        />
        <Route
          path="/vendor-field/business-type"
          element={
            <ProtectedRoute
              accessCondition={(profile) =>
                profile?.role === "admin" || profile?.dropdownsAccess?.canRead
              }
            >
              <BusinessType />
            </ProtectedRoute>
          }
        />
        <Route
          path="/user-field"
          element={
            <ProtectedRoute
              accessCondition={(profile) =>
                profile?.role === "admin" || profile?.dropdownsAccess?.canRead
              }
            >
              <User />
            </ProtectedRoute>
          }
        />
        <Route
          path="/user-field/cancel-appointment-reason"
          element={
            <ProtectedRoute
              accessCondition={(profile) =>
                profile?.role === "admin" || profile?.dropdownsAccess?.canRead
              }
            >
              <CancelAppointment />
            </ProtectedRoute>
          }
        />
        <Route
          path="/user-field/pet-category"
          element={
            <ProtectedRoute
              accessCondition={(profile) =>
                profile?.role === "admin" || profile?.dropdownsAccess?.canRead
              }
            >
              <PetCategories />
            </ProtectedRoute>
          }
        />
        <Route
          path="/user-field/breed-pet"
          element={
            <ProtectedRoute
              accessCondition={(profile) =>
                profile?.role === "admin" || profile?.dropdownsAccess?.canRead
              }
            >
              <BreedPet />
            </ProtectedRoute>
          }
        />
        <Route
          path="/user-field/allergy"
          element={
            <ProtectedRoute
              accessCondition={(profile) =>
                profile?.role === "admin" || profile?.dropdownsAccess?.canRead
              }
            >
              <Allergy />
            </ProtectedRoute>
          }
        />
        <Route
          path="/product-field"
          element={
            <ProtectedRoute
              accessCondition={(profile) =>
                profile?.role === "admin" || profile?.dropdownsAccess?.canRead
              }
            >
              <Product />
            </ProtectedRoute>
          }
        />
        <Route
          path="/product-field/brand"
          element={
            <ProtectedRoute
              accessCondition={(profile) =>
                profile?.role === "admin" || profile?.dropdownsAccess?.canRead
              }
            >
              <Brand />
            </ProtectedRoute>
          }
        />
        <Route
          path="/product-field/category"
          element={
            <ProtectedRoute
              accessCondition={(profile) =>
                profile?.role === "admin" || profile?.dropdownsAccess?.canRead
              }
            >
              <Category />
            </ProtectedRoute>
          }
        />
        <Route
          path="/product-field/sub-category"
          element={
            <ProtectedRoute
              accessCondition={(profile) =>
                profile?.role === "admin" || profile?.dropdownsAccess?.canRead
              }
            >
              <SubCategory />
            </ProtectedRoute>
          }
        />
        <Route
          path="/product-field/health-concern"
          element={
            <ProtectedRoute
              accessCondition={(profile) =>
                profile?.role === "admin" || profile?.dropdownsAccess?.canRead
              }
            >
              <HealthConcern />
            </ProtectedRoute>
          }
        />
        <Route
          path="/product-field/unit-type"
          element={
            <ProtectedRoute
              accessCondition={(profile) =>
                profile?.role === "admin" || profile?.dropdownsAccess?.canRead
              }
            >
              <UnitType />
            </ProtectedRoute>
          }
        />
        <Route
          path="/product-field/unit-name"
          element={
            <ProtectedRoute
              accessCondition={(profile) =>
                profile?.role === "admin" || profile?.dropdownsAccess?.canRead
              }
            >
              <UnitName />
            </ProtectedRoute>
          }
        />
        <Route
          path="/order-field/order-cancel-reason"
          element={
            <ProtectedRoute
              accessCondition={(profile) =>
                profile?.role === "admin" || profile?.dropdownsAccess?.canRead
              }
            >
              <Order />
            </ProtectedRoute>
          }
        />
        <Route
          path="/order-field/user-cancellation"
          element={
            <ProtectedRoute
              accessCondition={(profile) =>
                profile?.role === "admin" || profile?.dropdownsAccess?.canRead
              }
            >
              <UserCancellation />
            </ProtectedRoute>
          }
        />
        <Route
          path="/order-field/vendor-cancellation"
          element={
            <ProtectedRoute
              accessCondition={(profile) =>
                profile?.role === "admin" || profile?.dropdownsAccess?.canRead
              }
            >
              <VendorCancellation />
            </ProtectedRoute>
          }
        />
        <Route
          path="/report-abuse-field/animal-report"
          element={
            <ProtectedRoute
              accessCondition={(profile) =>
                profile?.role === "admin" || profile?.dropdownsAccess?.canRead
              }
            >
              <ReportAbuse />
            </ProtectedRoute>
          }
        />
        <Route
          path="/app-setting"
          element={
            <ProtectedRoute
              accessCondition={(profile) =>
                profile?.role === "admin" || profile?.appsettingsAccess?.canRead
              }
            >
              <AppSetting />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/profile"
          element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/marketing-notification"
          element={
            <ProtectedRoute>
              {" "}
              accessCondition=
              {(profile) =>
                profile?.role === "admin" ||
                profile?.marketingnotificationsAccess?.canRead
              }
              <PushNotification />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/marketing-notification/send"
          element={
            <ProtectedRoute
              accessCondition={(profile) =>
                profile?.role === "admin" ||
                profile?.marketingnotificationsAccess?.canRead
              }
            >
              <PushNotificationSend />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/push-notification"
          element={
            <ProtectedRoute
              accessCondition={(profile) =>
                profile?.role === "admin" ||
                profile?.pushnotificationsAccess?.canRead
              }
            >
              <UsersPushNotification />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/push-notification/send"
          element={
            <ProtectedRoute
              accessCondition={(profile) =>
                profile?.role === "admin" ||
                profile?.pushnotificationsAccess?.canRead
              }
            >
              <UsersPushNotificationSend />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/coupon-code"
          element={
            <ProtectedRoute
              accessCondition={(profile) =>
                profile?.role === "admin" || profile?.couponcodeAccess?.canRead
              }
            >
              <CouponCode />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/coupon-codes/:id"
          element={
            <ProtectedRoute
              accessCondition={(profile) =>
                profile?.role === "admin" || profile?.couponcodeAccess?.canRead
              }
            >
              <CouponCodeDetails />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/mail"
          element={
            <ProtectedRoute
              accessCondition={(profile) =>
                profile?.role === "admin" || profile?.mailAccess?.canRead
              }
            >
              <Mail />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/mail/:type"
          element={
            <ProtectedRoute
              accessCondition={(profile) =>
                profile?.role === "admin" || profile?.mailAccess?.canRead
              }
            >
              <SendEmail />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/policies"
          element={
            <ProtectedRoute
              accessCondition={(profile) =>
                profile?.role === "admin" || profile?.policyAccess?.canRead
              }
            >
              <Policies />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/policy/user"
          element={
            <ProtectedRoute
              accessCondition={(profile) =>
                profile?.role === "admin" || profile?.policyAccess?.canRead
              }
            >
              <UserPolicies />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/policy/user/terms"
          element={
            <ProtectedRoute
              accessCondition={(profile) =>
                profile?.role === "admin" || profile?.policyAccess?.canRead
              }
            >
              <UserTerms />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/policy/user/support"
          element={
            <ProtectedRoute
              accessCondition={(profile) =>
                profile?.role === "admin" || profile?.policyAccess?.canRead
              }
            >
              <UserSupport />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/policy/user/privacy"
          element={
            <ProtectedRoute
              accessCondition={(profile) =>
                profile?.role === "admin" || profile?.policyAccess?.canRead
              }
            >
              <UserPrivacy />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/policy/user/cancellation_policy"
          element={
            <ProtectedRoute
              accessCondition={(profile) =>
                profile?.role === "admin" || profile?.policyAccess?.canRead
              }
            >
              <UserCancellationPolicy />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/policy/vet-vendor"
          element={
            <ProtectedRoute
              accessCondition={(profile) =>
                profile?.role === "admin" || profile?.policyAccess?.canRead
              }
            >
              <VetVendorPolicies />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/policy/vet-vendor/terms"
          element={
            <ProtectedRoute
              accessCondition={(profile) =>
                profile?.role === "admin" || profile?.policyAccess?.canRead
              }
            >
              <VetTerms />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/policy/vet-vendor/support"
          element={
            <ProtectedRoute
              accessCondition={(profile) =>
                profile?.role === "admin" || profile?.policyAccess?.canRead
              }
            >
              <VetSupport />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/policy/vet-vendor/privacy"
          element={
            <ProtectedRoute
              accessCondition={(profile) =>
                profile?.role === "admin" || profile?.policyAccess?.canRead
              }
            >
              <VetPrivacy />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/upload/docs/:type"
          element={
            <ProtectedRoute>
              <UploadDocs />
            </ProtectedRoute>
          }
        />
        {/* <Route exact path="/mail/admin" element={
          <ProtectedRoute>
            <MailAdmin />
          </ProtectedRoute>
        } />
        <Route exact path="/mail/vet" element={
          <ProtectedRoute>
            <MailVet />
          </ProtectedRoute>
        } />
        <Route exact path="/mail/vendor" element={
          <ProtectedRoute>
            <MailVendor />
          </ProtectedRoute>
        } />
        <Route exact path="/mail/user" element={
          <ProtectedRoute>
            <MailUser />
          </ProtectedRoute>
        } /> */}
        <Route path="*" element={<Error404 />} />
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
