import * as vendorDropdownAction from "./vendorDropdown.action";

export const vendorDropdownFeatureKey = "vendorDropdownInfo";

const initialState = {
  loading: false,
  errorMsg: "",
  list: [],
  details: {},
  isStatusUpdate: false,
};

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    // BUISNESS create

    case vendorDropdownAction.BUISNESS_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
        isStatusUpdate: false,
      };

    case vendorDropdownAction.BUISNESS_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        isStatusUpdate: true,
      };

    case vendorDropdownAction.BUISNESS_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        list: [],
        isStatusUpdate: false,
      };

    //BUISNESS update

    case vendorDropdownAction.BUISNESS_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
        isStatusUpdate: false,
      };

    case vendorDropdownAction.BUISNESS_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        isStatusUpdate: true,
      };

    case vendorDropdownAction.BUISNESS_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        list: [],
        isStatusUpdate: false,
      };

    // BUISNESS list

    case vendorDropdownAction.BUISNESS_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case vendorDropdownAction.BUISNESS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: payload.data,
      };

    case vendorDropdownAction.BUISNESS_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        list: [],
      };

    // BUISNESS delete

    case vendorDropdownAction.BUISNESS_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
        isStatusUpdate: false,
      };

    case vendorDropdownAction.BUISNESS_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        list: payload.data,
        isStatusUpdate: true,
      };

    case vendorDropdownAction.BUISNESS_DELETE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        list: [],
        isStatusUpdate: false,
      };

    default:
      return state;
  }
};
