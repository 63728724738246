import * as abuseDropdownAction from "./abuseDropdown.action";

export const abuseDropdownFeatureKey = "abuseDropdownInfo";

const initialState = {
  loading: false,
  errorMsg: "",
  list: [],
  details: {},
  isStatusUpdate: false,
};

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    // ABUSE create

    case abuseDropdownAction.ABUSE_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
        isStatusUpdate: false,
      };

    case abuseDropdownAction.ABUSE_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        isStatusUpdate: true,
      };

    case abuseDropdownAction.ABUSE_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        list: [],
        isStatusUpdate: false,
      };

    //ABUSE update

    case abuseDropdownAction.ABUSE_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
        isStatusUpdate: false,
      };

    case abuseDropdownAction.ABUSE_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        isStatusUpdate: true,
      };

    case abuseDropdownAction.ABUSE_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        list: [],
        isStatusUpdate: false,
      };

    // ABUSE list

    case abuseDropdownAction.ABUSE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case abuseDropdownAction.ABUSE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: payload.data,
      };

    case abuseDropdownAction.ABUSE_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        list: [],
      };

    // ABUSE delete

    case abuseDropdownAction.ABUSE_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
        isStatusUpdate: false,
      };

    case abuseDropdownAction.ABUSE_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        list: payload.data,
        isStatusUpdate: true,
      };

    case abuseDropdownAction.ABUSE_DELETE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        list: [],
        isStatusUpdate: false,
      };
    default:
      return state;
  }
};
