import axios from "axios";
import { toast } from "react-toastify";
import { downloadCsvData, errorHandling, imageBaseUrl } from "../../helper";
import moment from "moment";

export const ABUSE_TYPES_REQUEST = 'ABUSE_TYPES_REQUEST';
export const ABUSE_TYPES_SUCCESS = 'ABUSE_TYPES_SUCCESS';
export const ABUSE_TYPES_FAILURE = 'ABUSE_TYPES_FAILURE';

export const ABUSE_LIST_REQUEST = 'ABUSE_LIST_REQUEST';
export const ABUSE_LIST_SUCCESS = 'ABUSE_LIST_SUCCESS';
export const ABUSE_LIST_FAILURE = 'ABUSE_LIST_FAILURE';

export const ABUSE_DETAILS_REQUEST = 'ABUSE_DETAILS_REQUEST';
export const ABUSE_DETAILS_SUCCESS = 'ABUSE_DETAILS_SUCCESS';
export const ABUSE_DETAILS_FAILURE = 'ABUSE_DETAILS_FAILURE';

export const ABUSE_LIST_FOR_EXCEL_REQUEST = 'ABUSE_LIST_FOR_EXCEL_REQUEST';
export const ABUSE_LIST_FOR_EXCEL_SUCCESS = 'ABUSE_LIST_FOR_EXCEL_SUCCESS';
export const ABUSE_LIST_FOR_EXCEL_FAILURE = 'ABUSE_LIST_FOR_EXCEL_FAILURE';

export const abuseTypes = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: ABUSE_TYPES_REQUEST });
            const url = `${process.env.REACT_APP_API_AWS_URL}/report/get/all/animal/abuse/name/v1`;
            const response = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: ABUSE_TYPES_SUCCESS, payload: response.data });
        } catch (error) {
            errorHandling(dispatch, ABUSE_TYPES_FAILURE, error);
        }
    }
};

export const abuseList = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: ABUSE_LIST_REQUEST });
            const url = `${process.env.REACT_APP_API_AWS_URL}/report/admin/get/list/of/roport/abuses/v1`;
            const response = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: ABUSE_LIST_SUCCESS, payload: response.data });
        } catch (error) {
            errorHandling(dispatch, ABUSE_LIST_FAILURE, error);
        }
    }
};

export const abuseDetails = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: ABUSE_DETAILS_REQUEST });
            const url = `${process.env.REACT_APP_API_AWS_URL}/report/admin/full/report/of/abuse/${id}/v1`;
            const response = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: ABUSE_DETAILS_SUCCESS, payload: response.data });
        } catch (error) {
            errorHandling(dispatch, ABUSE_DETAILS_FAILURE, error);
        }
    }
};

export const abuseListForExcel = (data) => {
    return async (dispatch) => {
        try {
            dispatch({ type: ABUSE_LIST_FOR_EXCEL_REQUEST });
            const url = `${process.env.REACT_APP_API_AWS_URL}/report/admin/full/report/of/abuse/for/xl/v1`;
            const response = await axios.post(url, data, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: ABUSE_LIST_FOR_EXCEL_SUCCESS, payload: response.data });
            if (response.data.data.length > 0) {
                response.data.data.forEach(item => {
                    if (Array.isArray(item["supporting evidance"]) && item["supporting evidance"].length > 0) {
                        item["supporting evidance"].forEach((elem, index) => {
                            item[`suppportingEvidence${index + 1}`] = `${imageBaseUrl}${elem}`;
                        });
                        delete item["supporting evidance"];
                    }
                    if (item["video evidence"]) {
                        item["video evidence"] = `${imageBaseUrl}${item["video evidence"]}`;
                    }
                    if (Array.isArray(item.completedSteps) && item.completedSteps.length > 0) {
                        item.completedSteps = Math.max(...item.completedSteps);
                    }
                    if (item.perpetratorDetails) {
                        const perpetrator = item.perpetratorDetails;
                        item.perpetratorApartmentName = perpetrator.appartmentName;
                        item.perpetratorCity = perpetrator.city;
                        item.perpetratorFloorNo = perpetrator.floorNo;
                        item.perpetratorHouseNo = perpetrator.houseNo;
                        item.perpetratorLat = perpetrator.lat;
                        item.perpetratorLong = perpetrator.long;
                        item.perpetratorName = perpetrator.name;
                        item.perpetratorPincode = perpetrator.pincode;
                        item.perpetratorState = perpetrator.state;
                        item.perpetratorEvidence = perpetrator.supportingEvidance;
                        delete item.perpetratorDetails;

                        if (Array.isArray(item.perpetratorEvidence) && item.perpetratorEvidence.length > 0) {
                            item.perpetratorEvidence.forEach((elem, index) => {
                                item[`perpetratorEvidence${index + 1}`] = `${imageBaseUrl}${elem}`;
                            });
                            delete item.perpetratorEvidence;
                        }

                        if (Array.isArray(item.witnessDetails) && item.witnessDetails.length > 0) {
                            item.witnessDetails.forEach((elem, index) => {
                                const witness = elem;
                                item[`witness${index + 1}AppartmentName`] = witness.appartmentName;
                                item[`witness${index + 1}City`] = witness.city;
                                item[`witness${index + 1}FloorNo`] = witness.floorNo;
                                item[`witness${index + 1}HouseNo`] = witness.houseNo;
                                item[`witness${index + 1}Mobile`] = witness.mobileNo;
                                item[`witness${index + 1}Name`] = witness.name;
                                item[`witness${index + 1}PinCode`] = witness.pincode;
                                item[`witness${index + 1}State`] = witness.state;
                                item[`witness${index + 1}Lattitute`] = witness.location.coordinates[0];
                                item[`witness${index + 1}Longitude`] = witness.location.coordinates[1];
                            });
                            delete item.witnessDetails;
                        }
                    }
                });
                downloadCsvData(response.data.data, `reportAbuse_${moment(new Date()).format("DD-MM-YYYY h:mm a")}`);
            }
        } catch (error) {
            errorHandling(dispatch, ABUSE_LIST_FOR_EXCEL_FAILURE, error);
        }
    }
};