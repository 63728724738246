import * as notificationActions from "./notification.actions";

export const usersNotificationFeatureKey = "usersNotificationInfo";

const initialState = {
    loading: false,
    errorMsg: "",
    list: [],
    isStatusChange: false
};

export const reducer = (state = initialState, { payload, type }) => {
    switch (type) {
        case notificationActions.NOTIFICATION_LIST_REQUEST:
            return {
                ...state,
                loading: true
            }

        case notificationActions.NOTIFICATION_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                list: payload.data
            }

        case notificationActions.NOTIFICATION_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload,
                list: []
            }

        case notificationActions.SEND_NOTIFICATION_REQUEST:
            return {
                ...state,
                loading: true
            }

        case notificationActions.SEND_NOTIFICATION_SUCCESS:
            return {
                ...state,
                loading: false
            }

        case notificationActions.SEND_NOTIFICATION_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case notificationActions.RE_SEND_NOTIFICATION_REQUEST:
            return {
                ...state,
                loading: true
            }

        case notificationActions.RE_SEND_NOTIFICATION_SUCCESS:
            return {
                ...state,
                loading: false,
                isStatusChange: true
            }

        case notificationActions.RE_SEND_NOTIFICATION_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case "IS_STATUS_CHANGE":
            return {
                ...state,
                isStatusChange: false
            }

        default: return state;
    }
};