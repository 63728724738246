import * as productDropdownAction from "./productDropdown.action";

export const productDropdownFeatureKey = "productDropdownInfo";

const initialState = {
  loading: false,
  errorMsg: "",
  list: [],
  category: [],
  subcategory: [],
  unitTypes: [],
  unitNames: [],
  details: {},
  isStatusUpdate: false,
};

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    // brand create

    case productDropdownAction.BRAND_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
        isStatusUpdate: false,
      };

    case productDropdownAction.BRAND_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        isStatusUpdate: true,
      };

    case productDropdownAction.BRAND_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        list: [],
        isStatusUpdate: false,
      };

    //brand update

    case productDropdownAction.BRAND_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
        isStatusUpdate: false,
      };

    case productDropdownAction.BRAND_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        isStatusUpdate: true,
      };

    case productDropdownAction.BRAND_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        list: [],
        isStatusUpdate: false,
      };

    // brand list

    case productDropdownAction.BRAND_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case productDropdownAction.BRAND_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: payload.data,
      };

    case productDropdownAction.BRAND_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        list: [],
      };

    // brand delete

    case productDropdownAction.BRAND_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
        isStatusUpdate: false,
      };

    case productDropdownAction.BRAND_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        list: payload.data,
        isStatusUpdate: true,
      };

    case productDropdownAction.BRAND_DELETE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        list: [],
        isStatusUpdate: false,
      };

    // category create

    case productDropdownAction.CATEGORY_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
        isStatusUpdate: false,
      };

    case productDropdownAction.CATEGORY_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        isStatusUpdate: true,
      };

    case productDropdownAction.CATEGORY_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        category: [],
        isStatusUpdate: false,
      };

    //CATEGORY update

    case productDropdownAction.CATEGORY_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
        isStatusUpdate: false,
      };

    case productDropdownAction.CATEGORY_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        isStatusUpdate: true,
      };

    case productDropdownAction.CATEGORY_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        category: [],
        isStatusUpdate: false,
      };

    // CATEGORY list

    case productDropdownAction.CATEGORY_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case productDropdownAction.CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        category: payload.data,
      };

    case productDropdownAction.CATEGORY_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        category: [],
      };

    // CATEGORY delete

    case productDropdownAction.CATEGORY_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
        isStatusUpdate: false,
      };

    case productDropdownAction.CATEGORY_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        list: payload.data,
        isStatusUpdate: true,
      };

    case productDropdownAction.CATEGORY_DELETE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        category: [],
        isStatusUpdate: false,
      };

    // subcategory create

    case productDropdownAction.SUBCATEGORY_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
        isStatusUpdate: false,
      };

    case productDropdownAction.SUBCATEGORY_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        isStatusUpdate: true,
      };

    case productDropdownAction.SUBCATEGORY_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        subcategory: [],
        isStatusUpdate: false,
      };

    //SUBCATEGORY update

    case productDropdownAction.SUBCATEGORY_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
        isStatusUpdate: false,
      };

    case productDropdownAction.SUBCATEGORY_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        isStatusUpdate: true,
      };

    case productDropdownAction.SUBCATEGORY_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        subcategory: [],
        isStatusUpdate: false,
      };

    // SUBCATEGORY list

    case productDropdownAction.SUBCATEGORY_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case productDropdownAction.SUBCATEGORY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        subcategory: payload.data,
      };

    case productDropdownAction.SUBCATEGORY_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        subcategory: [],
      };

    // SUBCATEGORY delete

    case productDropdownAction.SUBCATEGORY_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
        isStatusUpdate: false,
      };

    case productDropdownAction.SUBCATEGORY_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        list: payload.data,
        isStatusUpdate: true,
      };

    case productDropdownAction.SUBCATEGORY_DELETE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        subcategory: [],
        isStatusUpdate: false,
      };

    //health concern

    case productDropdownAction.HEALTH_CONCERN_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
        isStatusUpdate: false,
      };

    case productDropdownAction.HEALTH_CONCERN_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        isStatusUpdate: true,
      };

    case productDropdownAction.HEALTH_CONCERN_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        list: [],
        isStatusUpdate: false,
      };

    //HEALTH_CONCERN update

    case productDropdownAction.HEALTH_CONCERN_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
        isStatusUpdate: false,
      };

    case productDropdownAction.HEALTH_CONCERN_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        isStatusUpdate: true,
      };

    case productDropdownAction.HEALTH_CONCERN_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        list: [],
        isStatusUpdate: false,
      };

    // HEALTH_CONCERN list

    case productDropdownAction.HEALTH_CONCERN_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case productDropdownAction.HEALTH_CONCERN_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: payload.data,
      };

    case productDropdownAction.HEALTH_CONCERN_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        list: [],
      };

    // HEALTH_CONCERN delete

    case productDropdownAction.HEALTH_CONCERN_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
        isStatusUpdate: false,
      };

    case productDropdownAction.HEALTH_CONCERN_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        list: payload.data,
        isStatusUpdate: true,
      };

    case productDropdownAction.HEALTH_CONCERN_DELETE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        list: [],
        isStatusUpdate: false,
      };

    //UNIT TYPE CREATE

    case productDropdownAction.UNIT_TYPE_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
        isStatusUpdate: false,
      };

    case productDropdownAction.UNIT_TYPE_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        isStatusUpdate: true,
      };

    case productDropdownAction.UNIT_TYPE_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        unitTypes: [],
        isStatusUpdate: false,
      };

    //UNIT_TYPE update

    case productDropdownAction.UNIT_TYPE_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
        isStatusUpdate: false,
      };

    case productDropdownAction.UNIT_TYPE_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        isStatusUpdate: true,
      };

    case productDropdownAction.UNIT_TYPE_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        unitTypes: [],
        isStatusUpdate: false,
      };

    // UNIT_TYPE list

    case productDropdownAction.UNIT_TYPE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case productDropdownAction.UNIT_TYPE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        unitTypes: payload.data,
      };

    case productDropdownAction.UNIT_TYPE_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        unitTypes: [],
      };

    // UNIT_TYPE delete

    case productDropdownAction.UNIT_TYPE_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
        isStatusUpdate: false,
      };

    case productDropdownAction.UNIT_TYPE_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        unitTypes: payload.data,
        isStatusUpdate: true,
      };

    case productDropdownAction.UNIT_TYPE_DELETE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        unitTypes: [],
        isStatusUpdate: false,
      };

    //UNIT_NAME

    case productDropdownAction.UNIT_NAME_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
        isStatusUpdate: false,
      };

    case productDropdownAction.UNIT_NAME_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        isStatusUpdate: true,
      };

    case productDropdownAction.UNIT_NAME_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        unitNames: [],
        isStatusUpdate: false,
      };

    //UNIT_NAME update

    case productDropdownAction.UNIT_NAME_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
        isStatusUpdate: false,
      };

    case productDropdownAction.UNIT_NAME_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        isStatusUpdate: true,
      };

    case productDropdownAction.UNIT_NAME_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        unitNames: [],
        isStatusUpdate: false,
      };

    // UNIT_NAME list

    case productDropdownAction.UNIT_NAME_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case productDropdownAction.UNIT_NAME_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        unitNames: payload.data,
      };

    case productDropdownAction.UNIT_NAME_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        unitNames: [],
      };

    // UNIT_NAME delete

    case productDropdownAction.UNIT_NAME_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
        isStatusUpdate: false,
      };

    case productDropdownAction.UNIT_NAME_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        unitNames: payload.data,
        isStatusUpdate: true,
      };

    case productDropdownAction.UNIT_NAME_DELETE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        unitNames: [],
        isStatusUpdate: false,
      };

    default:
      return state;
  }
};
