import * as petActions from "./pet.actions";

export const petFeatureKey = "petInfo";

const initialState = {
  loading: false,
  errorMsg: "",
  pets: [],
  petDetails: {},
  petCategories: [],
  petConcerns: [],
  petTreatments:[]
};

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case petActions.PET_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case petActions.PET_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        pets: payload.data,
      };

    case petActions.PET_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    case petActions.PET_DETAIL_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case petActions.PET_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        petDetails: payload.data,
      };

    case petActions.PET_DETAIL_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    case petActions.PET_CATEGORIES_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case petActions.PET_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        petCategories: payload.data,
      };

    case petActions.PET_CATEGORIES_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    case petActions.PET_HEALTH_CONCERNS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case petActions.PET_HEALTH_CONCERNS_SUCCESS:
      return {
        ...state,
        loading: false,
        petConcerns: payload.data,
      };

    case petActions.PET_HEALTH_CONCERNS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    case petActions.PET_HEALTH_TREATMENT_AT_HOME_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case petActions.PET_HEALTH_TREATMENT_AT_HOME_SUCCESS:
      return {
        ...state,
        loading: false,
        petTreatments: payload.data,
      };

    case petActions.PET_HEALTH_TREATMENT_AT_HOME_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };
    default:
      return state;
  }
};
