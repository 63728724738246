import axios from "axios";
import { toast } from "react-toastify";
import { errorHandling } from "../../helper";

export const GET_SUB_ADMIN_LIST_REQUEST = "GET_SUB_ADMIN_LIST_REQUEST";
export const GET_SUB_ADMIN_LIST_SUCCESS = "GET_SUB_ADMIN_LIST_SUCCESS";
export const GET_SUB_ADMIN_LIST_FAILURE = "GET_SUB_ADMIN_LIST_FAILURE";

export const GET_SUB_ADMIN_DETAILS_REQUEST = "GET_SUB_ADMIN_DETAILS_REQUEST";
export const GET_SUB_ADMIN_DETAILS_SUCCESS = "GET_SUB_ADMIN_DETAILS_SUCCESS";
export const GET_SUB_ADMIN_DETAILS_FAILURE = "GET_SUB_ADMIN_DETAILS_FAILURE";

export const GET_SUB_ADMIN_CREATE_REQUEST = "GET_SUB_ADMIN_CREATE_REQUEST";
export const GET_SUB_ADMIN_CREATE_SUCCESS = "GET_SUB_ADMIN_CREATE_SUCCESS";
export const GET_SUB_ADMIN_CREATE_FAILURE = "GET_SUB_ADMIN_CREATE_FAILURE";

export const GET_SUB_ADMIN_UPDATE_REQUEST = "GET_SUB_ADMIN_UPDATE_REQUEST";
export const GET_SUB_ADMIN_UPDATE_SUCCESS = "GET_SUB_ADMIN_UPDATE_SUCCESS";
export const GET_SUB_ADMIN_UPDATE_FAILURE = "GET_SUB_ADMIN_UPDATE_FAILURE";

export const GET_SUB_ADMIN_DELETE_REQUEST = "GET_SUB_ADMIN_DELETE_REQUEST";
export const GET_SUB_ADMIN_DELETE_SUCCESS = "GET_SUB_ADMIN_DELETE_SUCCESS";
export const GET_SUB_ADMIN_DELETE_FAILURE = "GET_SUB_ADMIN_DELETE_FAILURE";

export const getAllSubAdminsList = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_SUB_ADMIN_LIST_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/admin/get/subadmin/list/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: GET_SUB_ADMIN_LIST_SUCCESS, payload: response.data });
    } catch (error) {
      errorHandling(dispatch, GET_SUB_ADMIN_LIST_FAILURE, error);
    }
  };
};

export const getSubAdminDetails = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_SUB_ADMIN_DETAILS_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/admin/get/subadmin/details/${id}/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: GET_SUB_ADMIN_DETAILS_SUCCESS, payload: response.data });
    } catch (error) {
      errorHandling(dispatch, GET_SUB_ADMIN_DETAILS_FAILURE, error);
    }
  };
};

export const createSubAdmin = (formData, navigate) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_SUB_ADMIN_CREATE_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/admin/create/subadmin/v1`;
      const response = await axios.post(url, formData, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: GET_SUB_ADMIN_CREATE_SUCCESS, payload: response.data });
      navigate("/sub-admins");
    } catch (error) {
      errorHandling(dispatch, GET_SUB_ADMIN_CREATE_FAILURE, error);
    }
  };
};

export const updateSubAdmin = (id, formData) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_SUB_ADMIN_UPDATE_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/admin/update/subadmin/${id}/v1`;
      const response = await axios.put(url, formData, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: GET_SUB_ADMIN_UPDATE_SUCCESS, payload: response.data });
    } catch (error) {
      errorHandling(dispatch, GET_SUB_ADMIN_UPDATE_FAILURE, error);
    }
  };
};

export const deleteSubAdmin = (id, setDeleteModal) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_SUB_ADMIN_DELETE_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/admin/delete/subadmin/${id}/v1`;
      const response = await axios.delete(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: GET_SUB_ADMIN_DELETE_SUCCESS, payload: response.data });
      setDeleteModal(false);
    } catch (error) {
      errorHandling(dispatch, GET_SUB_ADMIN_DELETE_FAILURE, error);
    }
  };
};
