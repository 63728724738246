import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { toast } from 'react-toastify';
import axios from 'axios';

export const downloadCsvData = (data, filename) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    // Buffer to store the generated Excel file
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
    saveAs(blob, `${filename}.xlsx`);
};

export const excelToJsonData = (file) => {
    return new Promise((resolve, reject) => {
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                try {
                    const data = e.target.result;
                    const workbook = XLSX.read(data, { type: "binary" });
                    const sheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[sheetName];
                    const json = XLSX.utils.sheet_to_json(worksheet);
                    const jsonData = JSON.stringify(json, null, 2);
                    resolve(jsonData);
                } catch (error) {
                    reject(error);
                }
            };
            reader.onerror = (error) => {
                reject(error);
            };
            reader.readAsBinaryString(file);
        } else {
            reject(new Error('No file provided'));
        }
    });
};

export const errorHandling = async (dispatch, type, error) => {
    console.error("error", error);
    dispatch({ type, payload: error.message });
    toast(error?.response?.data?.message);
    if (error?.response?.status == 401) {
        localStorage.removeItem("token");
    }
};

export const imageBaseUrl = process.env.REACT_APP_IMAGE_BASE_URL;

export const uploadImage = async (url, file, setSelectedImage) => {
    try {
        const imgRes = await axios.post(`${process.env.REACT_APP_API_AWS_URL}${url}`, {
            sFileName: file.name,
            sContentType: file.type
        }, {
            headers: {
                Authorization: localStorage.getItem('token')
            }
        });
        const surl = imgRes.data.data.sUrl
        const sImage = imgRes.data.data.sPath
        await axios.put(surl, file, { headers: { "Content-Type": file.type } });
        setSelectedImage(null);
        return sImage;
    } catch (error) {
        console.error("image upload error", error);
    }
};