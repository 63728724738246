import axios from "axios";
import { toast } from "react-toastify";
import { errorHandling } from "../../helper";

export const GET_APP_VERSION_REQUEST = 'GET_APP_VERSION_REQUEST';
export const GET_APP_VERSION_SUCCESS = 'GET_APP_VERSION_SUCCESS';
export const GET_APP_VERSION_FAILURE = 'GET_APP_VERSION_FAILURE';

export const UPDATE_APP_VERSION_REQUEST = 'UPDATE_APP_VERSION_REQUEST';
export const UPDATE_APP_VERSION_SUCCESS = 'UPDATE_APP_VERSION_SUCCESS';
export const UPDATE_APP_VERSION_FAILURE = 'UPDATE_APP_VERSION_FAILURE';

export const getAppVersion = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_APP_VERSION_REQUEST });
            const url = `${process.env.REACT_APP_API_AWS_URL}/setting/admin/get/app/version/v1`;
            const response = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: GET_APP_VERSION_SUCCESS, payload: response.data });
        } catch (error) {
            errorHandling(dispatch, GET_APP_VERSION_FAILURE, error);
        }
    }
};

export const updateAppVersion = (data) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "IS_UPDATE_STATUS" });
            dispatch({ type: UPDATE_APP_VERSION_REQUEST });
            const url = `${process.env.REACT_APP_API_AWS_URL}/setting/update/app/version/v1`;
            const response = await axios.put(url, data, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: UPDATE_APP_VERSION_SUCCESS, payload: response.data });
        } catch (error) {
            errorHandling(dispatch, UPDATE_APP_VERSION_FAILURE, error);
        }
    }
};