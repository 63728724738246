import axios from "axios";
import { toast } from "react-toastify";
import { errorHandling } from "../../helper";

export const GET_ALL_COUPON_CODE_REQUEST = "GET_ALL_COUPON_CODE_REQUEST";
export const GET_ALL_COUPON_CODE_SUCCESS = "GET_ALL_COUPON_CODE_SUCCESS";
export const GET_ALL_COUPON_CODE_FAILURE = "GET_ALL_COUPON_CODE_FAILURE";

export const CREATE_COUPON_CODE_REQUEST = "CREATE_COUPON_CODE_REQUEST";
export const CREATE_COUPON_CODE_SUCCESS = "CREATE_COUPON_CODE_SUCCESS";
export const CREATE_COUPON_CODE_FAILURE = "CREATE_COUPON_CODE_FAILURE";

export const COUPON_CODE_DETAILS_REQUEST = "COUPON_CODE_DETAILS_REQUEST";
export const COUPON_CODE_DETAILS_SUCCESS = "COUPON_CODE_DETAILS_SUCCESS";
export const COUPON_CODE_DETAILS_FAILURE = "COUPON_CODE_DETAILS_FAILURE";

export const COUPON_CODE_DELETE_REQUEST = "COUPON_CODE_DELETE_REQUEST";
export const COUPON_CODE_DELETE_SUCCESS = "COUPON_CODE_DELETE_SUCCESS";
export const COUPON_CODE_DELETE_FAILURE = "COUPON_CODE_DELETE_FAILURE";

export const COUPON_CODE_UPDATE_REQUEST = "COUPON_CODE_UPDATE_REQUEST";
export const COUPON_CODE_UPDATE_SUCCESS = "COUPON_CODE_UPDATE_SUCCESS";
export const COUPON_CODE_UPDATE_FAILURE = "COUPON_CODE_UPDATE_FAILURE";

export const getAllCoupons = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_ALL_COUPON_CODE_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/coupon/get/all/coupon/card/for/admin/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: GET_ALL_COUPON_CODE_SUCCESS, payload: response.data });
    } catch (error) {
      errorHandling(dispatch, GET_ALL_COUPON_CODE_FAILURE, error);
    }
  };
};

export const createCoupon = (data, setModalShow, setFormData) => {
  return async (dispatch) => {
    try {
      dispatch({ type: CREATE_COUPON_CODE_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/coupon/create/coupon/card/v1`;
      const response = await axios.post(url, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: CREATE_COUPON_CODE_SUCCESS, payload: response.data });
      setModalShow(false);
      setFormData({
        image: "",
        title: "",
        category: "",
        description: "",
        discount: "",
        requiredMinPrice: "",
        uptoPrice: "",
      });
    } catch (error) {
      errorHandling(dispatch, CREATE_COUPON_CODE_FAILURE, error);
    }
  };
};

export const couponDetails = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: COUPON_CODE_DETAILS_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/coupon/get/single/coupon/${id}/card/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: COUPON_CODE_DETAILS_SUCCESS, payload: response.data });
    } catch (error) {
      errorHandling(dispatch, COUPON_CODE_DETAILS_FAILURE, error);
    }
  };
};

export const deleteCoupon = (id, setDeleteModal, setDeleteId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: COUPON_CODE_DELETE_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/coupon/delete/coupon/card/${id}/v1`;
      const response = await axios.delete(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: COUPON_CODE_DELETE_SUCCESS, payload: id });
      setDeleteModal(false);
      setDeleteId("");
    } catch (error) {
      errorHandling(dispatch, COUPON_CODE_DELETE_FAILURE, error);
    }
  };
};

export const updateCoupon = (id, data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: COUPON_CODE_UPDATE_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/coupon/update/coupon/card/${id}/v1`;
      const response = await axios.put(url, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: COUPON_CODE_UPDATE_SUCCESS, payload: response.data });
      toast("Coupon updated successfully");
    } catch (error) {
      errorHandling(dispatch, COUPON_CODE_UPDATE_FAILURE, error);
    }
  };
};
