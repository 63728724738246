import * as blogActions from "./blog.actions";

export const blogFeatureKey = "blogInfo";

const initialState = {
    loading: false,
    errorMsg: "",
    blogs: [],
    blogDetails: {},
    isStatusChange: false
};

export const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case blogActions.BLOG_LIST_REQUEST:
            return {
                ...state,
                loading: true
            }

        case blogActions.BLOG_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                blogs: payload.getBlogs
            }

        case blogActions.BLOG_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case blogActions.BLOG_DETAIL_REQUEST:
            return {
                ...state,
                loading: true
            }

        case blogActions.BLOG_DETAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                blogDetails: payload.data
            }

        case blogActions.BLOG_DETAIL_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case blogActions.BLOG_UPDATE_REQUEST:
            return {
                ...state,
                loading: true
            }

        case blogActions.BLOG_UPDATE_SUCCESS:
            return {
                ...state,
                loading: false,
                blogDetails: payload.data,
                isStatusChange: true
            }

        case blogActions.BLOG_UPDATE_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case "BLOG_STATUS_CHANGE":
            return {
                ...state,
                isStatusChange: false
            }

        default: return state
    }
};